import {
  Container,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import PlatformMenu from "../components/PlatformMenu";
import {
  Heart,
  Target,
  Calendar,
  Shield,
  Users,
  Zap,
  ChevronRight,
  Quote,
} from "lucide-react";

const Root = styled(Container)(({ theme }) => ({
  padding: theme.spacing(12, 4),
  position: "relative",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: "5%",
    width: "90%",
    height: "100%",
    background: `linear-gradient(180deg, ${theme.palette.background.paper} 0%, transparent 100%)`,
    opacity: 0.4,
    zIndex: -1,
  },
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(6, 2),
  },
}));

const HeaderBox = styled(Box)(({ theme }) => ({
  position: "relative",
  marginBottom: theme.spacing(12),
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: "-30px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "150px",
    height: "4px",
    background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
    borderRadius: theme.spacing(1),
  },
}));

const HeaderText = styled(Typography)(({ theme }) => ({
  fontSize: "3.5rem",
  fontWeight: 700,
  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  textAlign: "center",
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    fontSize: "2.5rem",
  },
}));

const SubHeaderText = styled(Typography)(({ theme }) => ({
  fontSize: "1.25rem",
  fontWeight: 300,
  color: theme.palette.text.primary,
  textAlign: "center",
  maxWidth: "700px",
  margin: "0 auto",
  lineHeight: 1.8,
}));

const FeatureCard = styled(Card)(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: theme.spacing(3),
  height: "100%",
  transition: "all 0.3s ease-in-out",
  position: "relative",
  overflow: "visible",
  "&:hover": {
    transform: "translateY(-8px)",
    "& .icon-wrapper": {
      transform: "rotate(5deg) scale(1.1)",
    },
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  width: "60px",
  height: "60px",
  borderRadius: "50%",
  background: theme.palette.background.paper,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  boxShadow: `0 4px 20px ${theme.palette.primary.main}20`,
  transition: "transform 0.3s ease-in-out",
  color: theme.palette.primary.main,
}));

const TestimonialCard = styled(Card)(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: theme.spacing(3),
  padding: theme.spacing(4),
  position: "relative",
  overflow: "visible",
  "&::before": {
    content: '""',
    position: "absolute",
    top: "-15px",
    left: "20px",
    width: "30px",
    height: "30px",
    background: theme.palette.background.paper,
    transform: "rotate(45deg)",
    zIndex: 0,
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: "30px",
  padding: theme.spacing(2, 6),
  fontSize: "1.1rem",
  textTransform: "none",
  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

const About = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const features = [
    {
      icon: <Calendar size={28} />,
      title: "Smart Scheduling",
      description: "Effortless appointment booking and reminders",
    },
    {
      icon: <Shield size={28} />,
      title: "Secure Records",
      description: "Digital health records at your fingertips",
    },
    {
      icon: <Users size={28} />,
      title: "Connected Care",
      description: "Seamless communication with veterinarians",
    },
    {
      icon: <Zap size={28} />,
      title: "Quick Access",
      description: "Instant access to medical history and reports",
    },
  ];

  return (
    <>
      <PlatformMenu />
      <Root maxWidth="lg">
        <HeaderBox>
          <HeaderText variant="h1">Future of Animal Care</HeaderText>
          <SubHeaderText>
            Where innovation meets compassion in veterinary care
          </SubHeaderText>
        </HeaderBox>

        <Grid container spacing={4} mb={12}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <FeatureCard elevation={0}>
                <CardContent sx={{ textAlign: "center", p: 4 }}>
                  <IconWrapper className="icon-wrapper">
                    {feature.icon}
                  </IconWrapper>
                  <Typography variant="h6" gutterBottom fontWeight={600}>
                    {feature.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {feature.description}
                  </Typography>
                </CardContent>
              </FeatureCard>
            </Grid>
          ))}
        </Grid>

        <Box mb={12}>
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box mb={4}>
                <IconWrapper sx={{ mb: 3 }}>
                  <Heart size={28} />
                </IconWrapper>
                <Typography variant="h4" gutterBottom fontWeight={700}>
                  Our Mission
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  sx={{ mb: 3 }}
                >
                  Revolutionizing animal healthcare through technology and
                  compassion.
                </Typography>
                <Button
                  endIcon={<ChevronRight />}
                  sx={{ textTransform: "none" }}
                  color="primary"
                >
                  Learn more
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mb={4}>
                <IconWrapper sx={{ mb: 3 }}>
                  <Target size={28} />
                </IconWrapper>
                <Typography variant="h4" gutterBottom fontWeight={700}>
                  Our Vision
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  sx={{ mb: 3 }}
                >
                  Creating a world where every animal has access to quality
                  healthcare.
                </Typography>
                <Button
                  endIcon={<ChevronRight />}
                  sx={{ textTransform: "none" }}
                  color="primary"
                >
                  Read more
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box mb={12}>
          <Typography
            variant="h4"
            textAlign="center"
            gutterBottom
            fontWeight={700}
          >
            Trusted by Professionals
          </Typography>
          <Grid container spacing={4} mt={4}>
            <Grid item xs={12} md={6}>
              <TestimonialCard elevation={1}>
                <Box display="flex" alignItems="center" mb={2}>
                  <Quote
                    size={40}
                    color={theme.palette.primary.main}
                    style={{ opacity: 0.2 }}
                  />
                </Box>
                <Typography variant="body1" paragraph>
                  "The platform has revolutionized how I manage my veterinary
                  practice. Everything is streamlined and efficient."
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="primary"
                  fontWeight={600}
                >
                  Dr. Sarah Turner
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  Veterinary Specialist
                </Typography>
              </TestimonialCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <TestimonialCard elevation={1}>
                <Box display="flex" alignItems="center" mb={2}>
                  <Quote
                    size={40}
                    color={theme.palette.primary.main}
                    style={{ opacity: 0.2 }}
                  />
                </Box>
                <Typography variant="body1" paragraph>
                  "Managing my pets' healthcare has never been easier. The app
                  keeps track of everything I need."
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="primary"
                  fontWeight={600}
                >
                  Emily Rodriguez
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  Pet Owner
                </Typography>
              </TestimonialCard>
            </Grid>
          </Grid>
        </Box>

        <Box textAlign="center">
          <ActionButton
            variant="contained"
            onClick={() => navigate("/dashboard")}
            size={isMobile ? "medium" : "large"}
            endIcon={<ChevronRight />}
          >
            Join Gadbetay Today
          </ActionButton>
        </Box>
      </Root>
    </>
  );
};

export default About;
