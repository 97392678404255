import React, { useState, useEffect, useRef } from "react";
import "ol/ol.css";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import { fromLonLat } from "ol/proj";
import { defaults as defaultControls } from "ol/control";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  alpha,
  Typography,
  Snackbar,
  Alert,
  Fade,
  TextField,
  Tab,
  Tabs,
  Container,
  Paper,
  Tooltip,
  useTheme,
  useMediaQuery,
  Divider,
  Chip,
} from "@mui/material";
import {
  PetsOutlined,
  LocationOnOutlined,
  HealthAndSafetyOutlined,
  PsychologyOutlined,
  NotificationsNoneOutlined,
  SatelliteAltOutlined,
  FileDownloadOutlined,
  SearchOutlined,
  ThermostatOutlined,
  SpeedOutlined,
  WarningAmberOutlined,
  InfoOutlined,
} from "@mui/icons-material";
import PlatformMenu from "../components/PlatformMenu";
import { styled } from "@mui/system";
import { Theme } from "@mui/material/styles";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocationOnIcon from "@mui/icons-material/LocationOn";

interface Location {
  lat: number;
  lng: number;
}

interface AnimalData {
  id: string;
  health: string;
  temperature: string;
  location: Location;
  behavior: string;
}

const GradientBackground = styled(Box)(({ theme }: { theme: Theme }) => ({
  background: "linear-gradient(135deg, #f5f7fa 0%, #e9ecef 100%)",
  minHeight: "100vh",
  padding: theme.spacing(4),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));

const StyledCard = styled(Card)(({ theme }: { theme: Theme }) => ({
  borderRadius: 16,
  boxShadow: "0 8px 24px rgba(0,0,0,0.08)",
  marginBottom: theme.spacing(3),
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.02)",
  },
}));

const StyledTabs = styled(Tabs)(({ theme }: { theme: Theme }) => ({
  borderRadius: 12,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 12px rgba(0,0,0,0.06)",
  marginBottom: theme.spacing(3),
  "& .MuiTab-root": {
    textTransform: "none",
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  "& .Mui-selected": {
    color: theme.palette.primary.main,
  },
}));

const Tracking: React.FC = () => {
  const [animalId, setAnimalId] = useState<string>("");
  const [animalData, setAnimalData] = useState<AnimalData | null>(null);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [location, setLocation] = useState<Location>({
    lat: 18.9712,
    lng: -72.2852,
  });
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const mapElement = useRef<HTMLDivElement | null>(null);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    const map = new Map({
      target: mapElement.current!,
      layers: [
        new TileLayer({
          source: new XYZ({
            url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
          }),
        }),
        new TileLayer({
          source: new XYZ({
            url: "https://s2maps-tiles.eu/wms/?SERVICE=WMS&REQUEST=GetMap&LAYERS=s2cloudless-2020&SRS=EPSG:3857&WIDTH=256&HEIGHT=256&BBOX={bbox-epsg-3857}",
          }),
        }),
      ],
      view: new View({
        center: fromLonLat([-72.2852, 18.9712]),
        zoom: 5,
      }),
      controls: defaultControls({
        zoom: true,
        rotate: false,
        attribution: true,
      }),
    });

    return () => map.setTarget(undefined);
  }, []);

  const handleTrack = () => {
    const validAnimalId = "12345";

    if (animalId !== validAnimalId) {
      setAlertOpen(true);
      setAnimalData(null);
    } else {
      setAnimalData({
        id: animalId,
        health: "Good",
        temperature: "38°C",
        location: location,
        behavior: "Normal",
      });
    }
  };

  const handleExport = (format: string) => {
    alert(`Exporting data as ${format}`);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <Box sx={{ bgcolor: "background.default", minHeight: "100vh" }}>
      <PlatformMenu />
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Fade in timeout={800}>
          <Paper
            elevation={0}
            sx={{
              mb: 6,
              background: (theme) =>
                `linear-gradient(135deg, ${theme.palette.primary.main}, ${alpha(
                  theme.palette.primary.light,
                  0.8
                )})`,
              borderRadius: 4,
              p: 6,
              color: "white",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: -20,
                right: -20,
                opacity: 0.1,
                transform: "rotate(-15deg)",
              }}
            >
              <LocationOnIcon sx={{ fontSize: 180 }} />
            </Box>
            <Typography
              variant="h3"
              fontWeight="bold"
              gutterBottom
              sx={{ letterSpacing: -0.5 }}
            >
              Animal Tracking
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography variant="h6" sx={{ opacity: 0.9 }}></Typography>
            </Box>

            <Typography variant="h6" sx={{ opacity: 0.9 }}>
              Monitor and track animal location, health, and behavior in
              real-time
            </Typography>
          </Paper>
        </Fade>

        {/* Search Section */}
        <Card
          elevation={0}
          sx={{
            mb: 4,
            bgcolor: "background.paper",
            borderRadius: 4,
            border: "1px solid",
            borderColor: "divider",
          }}
        >
          <CardContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={8}>
                <TextField
                  fullWidth
                  placeholder="Enter Animal ID (e.g., 12345)"
                  variant="outlined"
                  value={animalId}
                  onChange={(e) => setAnimalId(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <SearchOutlined sx={{ mr: 1, color: "text.secondary" }} />
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 2,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={handleTrack}
                  startIcon={<LocationOnOutlined />}
                  sx={{
                    height: 56,
                    borderRadius: 2,
                    textTransform: "none",
                    fontSize: "1rem",
                  }}
                >
                  Track Animal
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {/* Map Card */}
        <Card
          elevation={0}
          sx={{
            mb: 4,
            borderRadius: 4,
            border: "1px solid",
            borderColor: "divider",
          }}
        >
          <CardContent>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2, gap: 1 }}>
              <SatelliteAltOutlined color="primary" />
              <Typography variant="h6" fontWeight={500}>
                Location Map
              </Typography>
              <Tooltip title="Real-time GPS tracking">
                <InfoOutlined
                  sx={{ ml: "auto", color: "text.secondary", fontSize: 20 }}
                />
              </Tooltip>
            </Box>
            <Box
              sx={{
                height: 400,
                borderRadius: 3,
                overflow: "hidden",
                border: "1px solid",
                borderColor: "divider",
              }}
            >
              <div ref={mapElement} style={{ width: "100%", height: "100%" }} />
            </Box>
          </CardContent>
        </Card>

        {/* Info Tabs */}
        <Tabs
          value={selectedTab}
          onChange={(e, val) => setSelectedTab(val)}
          variant={isMobile ? "scrollable" : "fullWidth"}
          scrollButtons={isMobile ? "auto" : false}
          sx={{
            mb: 3,
            "& .MuiTab-root": {
              textTransform: "none",
              fontSize: "0.9rem",
              minHeight: 48,
            },
          }}
        >
          <Tab icon={<SpeedOutlined />} label="Status" />
          <Tab icon={<HealthAndSafetyOutlined />} label="Health" />
          <Tab icon={<PsychologyOutlined />} label="Behavior" />
          <Tab icon={<WarningAmberOutlined />} label="Alerts" />
        </Tabs>

        {/* Tab Content */}
        <Paper
          elevation={0}
          sx={{
            p: 3,
            borderRadius: 4,
            border: "1px solid",
            borderColor: "divider",
            mb: 4,
          }}
        >
          {selectedTab === 0 && (
            <Box>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 500 }}>
                Current Status
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Card
                    elevation={0}
                    sx={{
                      p: 2,
                      border: "1px solid",
                      borderColor: "divider",
                      borderRadius: 2,
                    }}
                  >
                    <Typography color="text.secondary" variant="body2">
                      Location
                    </Typography>
                    <Typography variant="h6" sx={{ mt: 1 }}>
                      {animalData
                        ? `${animalData.location.lat.toFixed(
                            4
                          )}, ${animalData.location.lng.toFixed(4)}`
                        : "Not Available"}
                    </Typography>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card
                    elevation={0}
                    sx={{
                      p: 2,
                      border: "1px solid",
                      borderColor: "divider",
                      borderRadius: 2,
                    }}
                  >
                    <Typography color="text.secondary" variant="body2">
                      Last Updated
                    </Typography>
                    <Typography variant="h6" sx={{ mt: 1 }}>
                      Just Now
                    </Typography>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          )}

          {selectedTab === 1 && (
            <Box>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 500 }}>
                Health Statistics
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Card
                    elevation={0}
                    sx={{
                      p: 2,
                      border: "1px solid",
                      borderColor: "divider",
                      borderRadius: 2,
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <ThermostatOutlined
                        sx={{ mr: 1, color: "text.secondary" }}
                      />
                      <Typography color="text.secondary">
                        Temperature
                      </Typography>
                    </Box>
                    <Typography variant="h6">
                      {animalData ? animalData.temperature : "Unknown"}
                    </Typography>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card
                    elevation={0}
                    sx={{
                      p: 2,
                      border: "1px solid",
                      borderColor: "divider",
                      borderRadius: 2,
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <HealthAndSafetyOutlined
                        sx={{ mr: 1, color: "text.secondary" }}
                      />
                      <Typography color="text.secondary">Status</Typography>
                    </Box>
                    <Typography variant="h6">
                      {animalData ? animalData.health : "Unknown"}
                    </Typography>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          )}

          {selectedTab === 2 && (
            <Box>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 500 }}>
                Behavioral Analysis
              </Typography>
              <Card
                elevation={0}
                sx={{
                  p: 2,
                  border: "1px solid",
                  borderColor: "divider",
                  borderRadius: 2,
                }}
              >
                <Typography variant="body1">
                  {animalData
                    ? animalData.behavior
                    : "Behavior data not available"}
                </Typography>
              </Card>
            </Box>
          )}

          {selectedTab === 3 && (
            <Box>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 500 }}>
                Recent Alerts
              </Typography>
              <Card
                elevation={0}
                sx={{
                  p: 2,
                  border: "1px solid",
                  borderColor: "divider",
                  borderRadius: 2,
                  bgcolor: "grey.50",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <NotificationsNoneOutlined
                    sx={{ mr: 1, color: "text.secondary" }}
                  />
                  <Typography>No recent alerts</Typography>
                </Box>
              </Card>
            </Box>
          )}
        </Paper>

        {/* Export Section */}
        <Card
          elevation={0}
          sx={{
            borderRadius: 4,
            border: "1px solid",
            borderColor: "divider",
          }}
        >
          <CardContent>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <FileDownloadOutlined color="primary" />
              <Typography variant="h6" sx={{ ml: 1, fontWeight: 500 }}>
                Export Data
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button
                variant="outlined"
                onClick={() => handleExport("CSV")}
                startIcon={<FileDownloadOutlined />}
                sx={{
                  textTransform: "none",
                  borderRadius: 2,
                }}
              >
                CSV
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleExport("PDF")}
                startIcon={<FileDownloadOutlined />}
                sx={{
                  textTransform: "none",
                  borderRadius: 2,
                }}
              >
                PDF
              </Button>
            </Box>
          </CardContent>
        </Card>

        {/* Alert */}
        <Snackbar
          open={alertOpen}
          autoHideDuration={6000}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleAlertClose}
            severity="error"
            sx={{ width: "100%" }}
          >
            Invalid Animal ID. Please try again or contact support.
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default Tracking;
