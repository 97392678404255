import { useEffect, useState } from "react";
import { db } from "../../services/firebase";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  getDoc,
} from "firebase/firestore";

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Chip,
  Tooltip,
  Box,
} from "@mui/material";

interface Appointment {
  id: string;
  customerName: string;
  vetName: string;
  date: string;
  time: string;
  status: "pending" | "completed" | "deleted";
  vetId: string;
  userId: string;
  problem: string;
  comment: string;
}

const AppointmentManagement = () => {
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [selectedAppointment, setSelectedAppointment] =
    useState<Appointment | null>(null);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const fetchAppointments = async () => {
      const querySnapshot = await getDocs(collection(db, "appointments"));
      const fetchedAppointments: Appointment[] = querySnapshot.docs.map(
        (doc) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as Appointment)
      );

      setAppointments(fetchedAppointments);
    };

    fetchAppointments();
  }, []);

  // const updateAppointmentStatus = async (
  //   appointmentId: string,
  //   status: "pending" | "completed" | "deleted"
  // ) => {
  //   const appointmentRef = doc(db, "appointments", appointmentId);
  //   await updateDoc(appointmentRef, { status });
  //   setAppointments(
  //     appointments.map((app) =>
  //       app.id === appointmentId ? { ...app, status } : app
  //     )
  //   );
  // };

  const handleViewDetails = (appointment: Appointment) => {
    setSelectedAppointment(appointment);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedAppointment(null);
  };

  const handleDeleteAppointment = async (appointmentId: string) => {
    try {
      const appointmentRef = doc(db, "appointments", appointmentId);
      const docSnapshot = await getDoc(appointmentRef);

      if (!docSnapshot.exists()) {
        console.error("Document not found!");
        return;
      }

      await updateDoc(appointmentRef, { status: "deleted" });

      setAppointments(
        appointments.map((app) =>
          app.id === appointmentId ? { ...app, status: "deleted" } : app
        )
      );
    } catch (error) {
      console.error("Error deleting appointment:", error);
    }
  };

  const checkAppointmentStatus = (appointment: Appointment) => {
    const currentTime = new Date();
    const appointmentTime = new Date(`${appointment.date} ${appointment.time}`);
    if (currentTime > appointmentTime && appointment.status !== "deleted") {
      return "completed";
    }
    return appointment.status;
  };

  const getStatusColor = (status: "pending" | "completed" | "deleted") => {
    switch (status) {
      case "pending":
        return "warning";
      case "completed":
        return "success";
      case "deleted":
        return "error";
      default:
        return "default";
    }
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Appointment Management
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Customer Name</TableCell>
              <TableCell>Vet Name</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {appointments.map((appointment) => {
              const status = checkAppointmentStatus(appointment);
              return (
                <TableRow key={appointment.id}>
                  <TableCell>{appointment.customerName}</TableCell>
                  <TableCell>{appointment.vetName}</TableCell>
                  <TableCell>{appointment.date}</TableCell>
                  <TableCell>{appointment.time}</TableCell>
                  <TableCell>
                    <Chip label={status} color={getStatusColor(status)} />
                  </TableCell>
                  <TableCell>
                    <Tooltip title="View Details" arrow>
                      <Button
                        variant="outlined"
                        onClick={() => handleViewDetails(appointment)}
                        color="primary"
                        style={{ marginRight: "8px" }}
                      >
                        View
                      </Button>
                    </Tooltip>
                    <Tooltip title="Delete Appointment" arrow>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleDeleteAppointment(appointment.id)}
                        disabled={status === "deleted"}
                      >
                        Delete
                      </Button>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog to view appointment details */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Appointment Details</DialogTitle>
        <DialogContent>
          {selectedAppointment && (
            <Box>
              <Typography variant="h6">
                Customer Name: {selectedAppointment.customerName}
              </Typography>
              <Typography variant="body1">
                Vet Name: {selectedAppointment.vetName}
              </Typography>
              <Typography variant="body1">
                Date: {selectedAppointment.date}
              </Typography>
              <Typography variant="body1">
                Time: {selectedAppointment.time}
              </Typography>
              <Typography variant="body1">
                Status:{" "}
                <Chip
                  label={selectedAppointment.status}
                  color={getStatusColor(selectedAppointment.status)}
                />
              </Typography>
              <Typography variant="body1">
                Problem: {selectedAppointment.problem}
              </Typography>
              <Typography variant="body1">
                Comment: {selectedAppointment.comment}
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AppointmentManagement;
