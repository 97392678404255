import React, { useState, useEffect } from "react";
import { db } from "../../services/firebase";
import {
  Container,
  Typography,
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import { Edit, Delete, Add } from "@mui/icons-material";
import {
  collection,
  query,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
  setDoc,
  Timestamp,
} from "firebase/firestore";

interface Article {
  id: string;
  title: string;
  category: string;
  description: string;
  content: string;
  reads: number;
  imageUrl?: string;
}

const ArticleManagement: React.FC = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentArticle, setCurrentArticle] = useState<Article | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const fetchArticles = async () => {
    const q = query(collection(db, "articles"));
    const querySnapshot = await getDocs(q);
    const articlesData: Article[] = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      articlesData.push({
        id: doc.id,
        title: data.title,
        category: data.category,
        description: data.description,
        content: data.content,
        reads: data.reads || 0,
        imageUrl: data.imageUrl,
      });
    });
    setArticles(articlesData);
  };

  useEffect(() => {
    fetchArticles();
  }, []);

  const handleDialogOpen = (article: Article | null) => {
    setCurrentArticle(
      article || {
        id: "",
        title: "",
        category: "",
        description: "",
        content: "",
        reads: 0,
        imageUrl: "",
      }
    );
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setCurrentArticle(null);
  };

  const handleSave = async () => {
    if (currentArticle) {
      const imageUrl = currentArticle.imageUrl;

      if (currentArticle.id) {
        const articleRef = doc(db, "articles", currentArticle.id);
        await updateDoc(articleRef, {
          title: currentArticle.title,
          category: currentArticle.category,
          description: currentArticle.description,
          content: currentArticle.content,
          reads: currentArticle.reads,
          imageUrl,
        });
        setSnackbarMessage("Article updated successfully");
      } else {
        const newArticleRef = doc(collection(db, "articles"));
        await setDoc(newArticleRef, {
          title: currentArticle.title,
          category: currentArticle.category,
          description: currentArticle.description,
          content: currentArticle.content,
          reads: 0,
          createdAt: Timestamp.now(),
          imageUrl,
        });
        setSnackbarMessage("Article created successfully");
      }

      setSnackbarOpen(true);
      handleDialogClose();
      fetchArticles();
    }
  };

  const handleDelete = async (id: string) => {
    const articleRef = doc(db, "articles", id);
    await deleteDoc(articleRef);
    setArticles(articles.filter((article) => article.id !== id));
    setSnackbarMessage("Article deleted successfully");
    setSnackbarOpen(true);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    if (currentArticle) {
      setCurrentArticle({ ...currentArticle, [name]: value });
    }
  };

  return (
    <Container>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
      >
        <Typography variant="h4">Article Management</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={() => handleDialogOpen(null)}
        >
          New Article
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Reads</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {articles.map((article) => (
              <TableRow key={article.id}>
                <TableCell>{article.title}</TableCell>
                <TableCell>{article.category}</TableCell>
                <TableCell>{article.description}</TableCell>
                <TableCell>{article.reads}</TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => handleDialogOpen(article)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() => handleDelete(article.id)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          {currentArticle?.id ? "Edit Article" : "New Article"}
        </DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Title"
            type="text"
            fullWidth
            name="title"
            value={currentArticle?.title || ""}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Category"
            type="text"
            fullWidth
            name="category"
            value={currentArticle?.category || ""}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            name="description"
            value={currentArticle?.description || ""}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Content"
            type="text"
            fullWidth
            name="content"
            value={currentArticle?.content || ""}
            onChange={handleChange}
            multiline
            rows={4}
          />
          {/* Input field for image URL */}
          <TextField
            margin="dense"
            label="Image URL"
            type="text"
            fullWidth
            name="imageUrl"
            value={currentArticle?.imageUrl || ""}
            onChange={handleChange}
          />
          {currentArticle?.imageUrl && (
            <img
              src={currentArticle.imageUrl}
              alt="Article"
              width="100%"
              style={{ marginTop: "10px" }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ArticleManagement;
