import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardMedia,
  Typography,
  Box,
  Chip,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  InputAdornment,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  Modal,
  Backdrop,
  Fade,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WorkIcon from "@mui/icons-material/Work";
import PaidIcon from "@mui/icons-material/Paid";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../services/firebase";
import PlatformMenu from "../components/PlatformMenu";

interface Vacancy {
  id: string;
  company_logo: string;
  company_name: string;
  job_title: string;
  location: string;
  job_type: string;
  salary_range: string;
  remote_work: boolean;
  job_description: string;
  job_requirements: string;
  job_responsibilities: string;
  contact_name: string;
  contact_email: string;
  contact_phone: string;
  benefits?: string;
  company_culture?: string;
}

const Vacancies: React.FC = () => {
  const [vacancies, setVacancies] = useState<Vacancy[]>([]);
  const [filteredVacancies, setFilteredVacancies] = useState<Vacancy[]>([]);
  const [searchText, setSearchText] = useState("");
  const [jobType, setJobType] = useState("");
  const [location, setLocation] = useState("");
  const [minSalary, setMinSalary] = useState("");
  const [maxSalary, setMaxSalary] = useState("");
  const [remoteWork, setRemoteWork] = useState("");
  const [isAdvancedSearchOpen, setAdvancedSearchOpen] = useState(false);

  useEffect(() => {
    const fetchVacancies = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "vacancies"));
        const vacancyList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Vacancy[];
        setVacancies(vacancyList);
        setFilteredVacancies(vacancyList);
      } catch (error) {
        console.error("Error fetching vacancies:", error);
      }
    };

    fetchVacancies();
  }, []);

  const handleSearch = () => {
    let filtered = vacancies;

    if (searchText) {
      filtered = filtered.filter(
        (vacancy) =>
          vacancy.job_title.toLowerCase().includes(searchText.toLowerCase()) ||
          vacancy.company_name
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          vacancy.location.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    if (jobType)
      filtered = filtered.filter((vacancy) => vacancy.job_type === jobType);
    if (location)
      filtered = filtered.filter((vacancy) => vacancy.location === location);
    if (minSalary)
      filtered = filtered.filter(
        (vacancy) =>
          parseInt(vacancy.salary_range.split("-")[0]) >= parseInt(minSalary)
      );
    if (maxSalary)
      filtered = filtered.filter(
        (vacancy) =>
          parseInt(vacancy.salary_range.split("-")[1]) <= parseInt(maxSalary)
      );
    if (remoteWork)
      filtered = filtered.filter(
        (vacancy) =>
          (remoteWork === "Yes" && vacancy.remote_work) ||
          (remoteWork === "No" && !vacancy.remote_work)
      );

    setFilteredVacancies(filtered);
  };

  return (
    <div>
      <PlatformMenu />

      <Box p={4} sx={{ background: "#f5f5f5" }}>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{ fontWeight: "bold", color: "#333" }}
        >
          Job Vacancies
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
            mb: 4,
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Search by job title, company, or location"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{
              backgroundColor: "#fff",
              borderRadius: 2,
              flex: 1,
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
              },
            }}
          />
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setAdvancedSearchOpen(true)}
            startIcon={<FilterAltIcon />}
            sx={{
              borderRadius: 2,
              fontWeight: "bold",
              padding: "12px 24px",
            }}
          >
            Advanced Search
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            sx={{
              borderRadius: 2,
              fontWeight: "bold",
              padding: "12px 24px",
            }}
          >
            Search
          </Button>
        </Box>

        {/* Advanced Search Modal */}
        <Modal
          open={isAdvancedSearchOpen}
          onClose={() => setAdvancedSearchOpen(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={isAdvancedSearchOpen}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "white",
                borderRadius: 2,
                p: 4,
                width: "90%",
                maxWidth: 600,
                boxShadow: 24,
              }}
            >
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
              >
                <Typography variant="h6" fontWeight="bold">
                  Advanced Search
                </Typography>
                <IconButton onClick={() => setAdvancedSearchOpen(false)}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <TextField
                fullWidth
                label="Location"
                variant="outlined"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                sx={{ mb: 2 }}
              />
              <FormControl fullWidth sx={{ mb: 2 }}>
                <Select
                  value={jobType}
                  onChange={(e: SelectChangeEvent) =>
                    setJobType(e.target.value as string)
                  }
                  displayEmpty
                  inputProps={{ "aria-label": "Job Type" }}
                >
                  <MenuItem value="">All Job Types</MenuItem>
                  <MenuItem value="Full-Time">Full-Time</MenuItem>
                  <MenuItem value="Part-Time">Part-Time</MenuItem>
                  <MenuItem value="Contract">Contract</MenuItem>
                  <MenuItem value="Internship">Internship</MenuItem>
                </Select>
              </FormControl>
              <Box display="flex" gap={2} sx={{ mb: 2 }}>
                <TextField
                  label="Min Salary"
                  variant="outlined"
                  type="number"
                  value={minSalary}
                  onChange={(e) => setMinSalary(e.target.value)}
                  fullWidth
                />
                <TextField
                  label="Max Salary"
                  variant="outlined"
                  type="number"
                  value={maxSalary}
                  onChange={(e) => setMaxSalary(e.target.value)}
                  fullWidth
                />
              </Box>
              <FormControl fullWidth>
                <Select
                  value={remoteWork}
                  onChange={(e: SelectChangeEvent) =>
                    setRemoteWork(e.target.value as string)
                  }
                  displayEmpty
                  inputProps={{ "aria-label": "Remote Work" }}
                >
                  <MenuItem value="">Remote Work</MenuItem>
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleSearch();
                  setAdvancedSearchOpen(false);
                }}
                sx={{ mt: 3, width: "100%", fontWeight: "bold" }}
              >
                Apply Filters
              </Button>
            </Box>
          </Fade>
        </Modal>

        <Grid container spacing={4}>
          {filteredVacancies.map((vacancy) => (
            <Grid item xs={12} key={vacancy.id}>
              <Card
                sx={{
                  borderRadius: 3,
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.3s, box-shadow 0.3s",
                  "&:hover": {
                    transform: "translateY(-5px)",
                    boxShadow: "0 6px 8px rgba(0, 0, 0, 0.2)",
                  },
                }}
              >
                <Card
                  sx={{
                    borderRadius: 4,
                    boxShadow: "0 6px 10px rgba(0, 0, 0, 0.15)",
                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                    backgroundColor: "#fff",
                    "&:hover": {
                      transform: "translateY(-1px)",
                      boxShadow: "0 10px 15px rgba(0, 0, 0, 0.25)",
                    },
                    overflow: "hidden",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                      alignItems: "flex-start",
                    }}
                  >
                    <CardMedia
                      component="img"
                      sx={{
                        width: { xs: "100%", sm: 180 },
                        height: 140,
                        objectFit: "cover",
                        flexShrink: 0,
                      }}
                      image={vacancy.company_logo || "placeholder-image.png"}
                      alt={vacancy.company_name}
                    />
                    <Box
                      sx={{
                        flex: 1,
                        p: 3,
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      <Box>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          color="primary"
                        >
                          {vacancy.job_title}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                          {vacancy.company_name}
                        </Typography>
                      </Box>
                      <Box display="flex" flexWrap="wrap" gap={1}>
                        <Chip
                          icon={<LocationOnIcon sx={{ color: "#4caf50" }} />}
                          label={vacancy.location}
                          variant="outlined"
                          sx={{ fontSize: "0.875rem", color: "#4caf50" }}
                        />
                        <Chip
                          icon={<WorkIcon sx={{ color: "#ff9800" }} />}
                          label={vacancy.job_type}
                          variant="outlined"
                          sx={{ fontSize: "0.875rem", color: "#ff9800" }}
                        />
                        <Chip
                          icon={<PaidIcon sx={{ color: "#2196f3" }} />}
                          label={`$${vacancy.salary_range}`}
                          variant="outlined"
                          sx={{ fontSize: "0.875rem", color: "#2196f3" }}
                        />
                        {vacancy.remote_work && (
                          <Chip
                            label="Remote"
                            sx={{
                              backgroundColor: "#e0f7fa",
                              color: "#00796b",
                              fontWeight: "bold",
                              fontSize: "0.875rem",
                            }}
                          />
                        )}
                      </Box>
                      <Accordion
                        sx={{
                          backgroundColor: "#f9f9f9",
                          boxShadow: "none",
                          "&::before": { display: "none" },
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant="body2" fontWeight="bold">
                            Job Details
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ px: 2 }}>
                          <Typography variant="body2" gutterBottom>
                            <strong>Description:</strong>{" "}
                            {vacancy.job_description}
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            <strong>Requirements:</strong>{" "}
                            {vacancy.job_requirements}
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            <strong>Responsibilities:</strong>{" "}
                            {vacancy.job_responsibilities}
                          </Typography>
                          {vacancy.benefits && (
                            <Typography variant="body2" gutterBottom>
                              <strong>Benefits:</strong> {vacancy.benefits}
                            </Typography>
                          )}
                          {vacancy.company_culture && (
                            <Typography variant="body2" gutterBottom>
                              <strong>Culture:</strong>{" "}
                              {vacancy.company_culture}
                            </Typography>
                          )}
                        </AccordionDetails>
                      </Accordion>
                      <Button
                        variant="contained"
                        color="primary"
                        href={`mailto:${vacancy.contact_email}`}
                        sx={{
                          mt: 2,
                          alignSelf: "flex-start",
                          textTransform: "none",
                          borderRadius: 2,
                          fontWeight: "bold",
                        }}
                      >
                        Apply Now
                      </Button>
                    </Box>
                  </Box>
                </Card>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
};

export default Vacancies;
