import { ReactNode, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../services/firebase"; 
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";

interface ProtectedRouteProps {
  children: ReactNode;
}

export default function ProtectedRoute({ children }: ProtectedRouteProps) {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isProfileComplete, setIsProfileComplete] = useState(false);

  useEffect(() => {
    const checkProfileCompletion = async () => {
      if (currentUser) {
        try {
          const userDocRef = doc(db, "users", currentUser.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();

            
            const isComplete =
              userData?.username && userData?.birthdate && userData?.location;

            
            await setDoc(
              userDocRef,
              {
                profileComplete: isComplete, 
              },
              { merge: true }
            );

            setIsProfileComplete(isComplete);
          } else {
            setIsProfileComplete(false);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          setIsProfileComplete(false);
        }
      } else {
        setIsProfileComplete(false);
      }
      setIsLoading(false);
    };

    checkProfileCompletion();
  }, [currentUser]);

  const handleRedirectToProfile = () => {
    setShowAlert(false);
    navigate("/profile");
  };

  useEffect(() => {
    if (!isLoading && currentUser && !isProfileComplete) {
      setShowAlert(true);
    }
  }, [isLoading, currentUser, isProfileComplete]);

  if (isLoading) {
    return <div>Loading...</div>; 
  }

  if (!currentUser) {
    return <div>Please log in to access this page.</div>;
  }

  return (
    <>
      <Box
        sx={{
          filter: showAlert ? "blur(2px)" : "none",
          transition: "filter 0.3s ease",
        }}
      >
        {children}
      </Box>

      {/* Profile Completion Alert */}
      <Dialog
        open={showAlert}
        onClose={handleRedirectToProfile}
        aria-labelledby="profile-completion-dialog"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="profile-completion-dialog">
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Complete Your Profile
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="body1" sx={{ marginBottom: 2 }}>
              To access this feature, please complete your profile.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleRedirectToProfile}
            variant="contained"
            color="primary"
            fullWidth
            sx={{ textTransform: "none", fontWeight: "bold" }}
          >
            Go to Profile
          </Button>
          <Button
            onClick={handleRedirectToProfile}
            variant="outlined"
            fullWidth
            sx={{ textTransform: "none", fontWeight: "bold", marginTop: 1 }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
