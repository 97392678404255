import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Box,
  Fade,
  List,
  ListItem,
  CircularProgress,
  Paper,
  Chip,
  Divider,
  Avatar,
  IconButton,
  alpha,
} from "@mui/material";
import {
  Search as SearchIcon,
  LocationOn,
  Medication,
  AccessTime,
  CalendarMonth,
  Close,
} from "@mui/icons-material";
import { getDocs, collection, addDoc } from "firebase/firestore";
import { db } from "../services/firebase";
import PlatformMenu from "../components/PlatformMenu";
import VaccinesIcon from '@mui/icons-material/Vaccines';

interface TimeSlot {
  date: string;
  startTime: string;
  endTime: string;
  isTaken?: boolean;
}

interface Review {
  userId: string;
  rating: number;
  comment: string;
  date: string;
}

interface Vet {
  id: string;
  username: string;
  isVet: boolean;
  specialization: string;
  vetLocation: string;
  serviceFee: string;
  availableTimeSlots: TimeSlot[];
  reviews: Review[];
  averageRating: number;
  phone: string;
  email: string;
}

interface Appointment {
  id: string;
  vetId: string;
  vetName: string;
  date: string;
  time: string;
  problem: string;
  comment: string;
  userId: string;
}

const Vets: React.FC = () => {
 
  const [vets, setVets] = useState<Vet[]>([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({
    specialization: "",
    location: "",
    search: "",
  });
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [selectedVet, setSelectedVet] = useState<Vet | null>(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<TimeSlot | null>(
    null
  );
  const [appointmentDetails, setAppointmentDetails] = useState({
    problem: "",
    comment: "",
  });

  useEffect(() => {
    const fetchVetsAndAppointments = async () => {
      try {
        const [vetsSnapshot, appointmentsSnapshot] = await Promise.all([
          getDocs(collection(db, "users")),
          getDocs(collection(db, "appointments")),
        ]);

        const fetchedAppointments = appointmentsSnapshot.docs.map(
          (doc) => ({ id: doc.id, ...doc.data() } as Appointment)
        );
        setAppointments(fetchedAppointments);

        const vetsData: Vet[] = vetsSnapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() } as Vet))
          .filter((user) => user.isVet)
          .map((vet) => ({
            ...vet,
            availableTimeSlots: vet.availableTimeSlots.map((slot) => ({
              ...slot,
              isTaken: fetchedAppointments.some(
                (app) =>
                  app.vetId === vet.id &&
                  app.date === slot.date &&
                  app.time === slot.startTime
              ),
            })),
          }));

        setVets(vetsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchVetsAndAppointments();
  }, []);

  const handleFilterChange = (key: string, value: string) => {
    setFilter({ ...filter, [key]: value });
  };

  const handleAppointmentSubmission = async () => {
    if (selectedVet && selectedTimeSlot && !selectedTimeSlot.isTaken) {
      const appointment: Appointment = {
        id: Date.now().toString(),
        vetId: selectedVet.id,
        vetName: selectedVet.username,
        date: selectedTimeSlot.date,
        time: selectedTimeSlot.startTime,
        problem: appointmentDetails.problem,
        comment: appointmentDetails.comment,
        userId: "currentUserId",
      };

      try {
        await addDoc(collection(db, "appointments"), appointment);

        setAppointments([appointment, ...appointments]);
        setSelectedVet({
          ...selectedVet,
          availableTimeSlots: selectedVet.availableTimeSlots.map((slot) =>
            slot === selectedTimeSlot ? { ...slot, isTaken: true } : slot
          ),
        });

        alert("Appointment booked successfully!");
      } catch (error) {
        console.error("Error booking appointment:", error);
        alert("An error occurred while booking the appointment.");
      }
    } else {
      alert("Please select a valid time slot.");
    }
  };

  const filteredVets = vets.filter(
    (vet) =>
      (filter.specialization
        ? vet.specialization.includes(filter.specialization)
        : true) &&
      (filter.location ? vet.vetLocation.includes(filter.location) : true) &&
      (filter.search
        ? vet.username.toLowerCase().includes(filter.search.toLowerCase())
        : true)
  );

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      <PlatformMenu />
      <Container maxWidth="lg" sx={{ mt: 6, mb: 8 }}>
        {/* Hero Section */}



        <Fade in timeout={800}>
        <Paper
            elevation={0}
            sx={{
              mb: 6,
              background: (theme) =>
                `linear-gradient(135deg, ${theme.palette.primary.main}, ${alpha(
                  theme.palette.primary.light,
                  0.8
                )})`,
              borderRadius: 4,
              p: 6,
              color: "white",
              position: "relative",
              overflow: "hidden",
            }}
          >
        
           <Box
              sx={{
                position: "absolute",
                top: -20,
                right: -20,
                opacity: 0.1,
                transform: "rotate(-15deg)",
              }}
            >
              <VaccinesIcon  sx={{ fontSize: 180 }} />
            </Box>
          <Typography variant="h3" fontWeight="bold" gutterBottom>
            Find Your Perfect Vet
          </Typography>
          <Typography variant="h6" sx={{ opacity: 0.9 }}>
            Book appointments with trusted veterinarians in your area
          </Typography>
        </Paper>
        </Fade>




        {/* Search Filters */}
        <Paper
          elevation={2}
          sx={{
            p: 3,
            mb: 4,
            borderRadius: 2,
            background: (theme) => alpha(theme.palette.background.paper, 0.8),
            backdropFilter: "blur(10px)",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                variant="outlined"
                label="Search by Name"
                value={filter.search}
                onChange={(e) => handleFilterChange("search", e.target.value)}
                InputProps={{
                  startAdornment: (
                    <SearchIcon sx={{ mr: 1, color: "text.secondary" }} />
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                variant="outlined"
                label="Specialization"
                value={filter.specialization}
                onChange={(e) =>
                  handleFilterChange("specialization", e.target.value)
                }
                InputProps={{
                  startAdornment: (
                    <Medication sx={{ mr: 1, color: "text.secondary" }} />
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                variant="outlined"
                label="Location"
                value={filter.location}
                onChange={(e) => handleFilterChange("location", e.target.value)}
                InputProps={{
                  startAdornment: (
                    <LocationOn sx={{ mr: 1, color: "text.secondary" }} />
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Paper>

        <Grid container spacing={4}>
          {filteredVets.map((vet) => (
            <Grid item xs={12} key={vet.id}>
              <Card
                sx={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: 4,
                  boxShadow: 6,
                  overflow: "hidden",
                  transition: "transform 0.3s, box-shadow 0.3s",
                  "&:hover": {
                    transform: "translateY(-4px)",
                    boxShadow: 12,
                  },
                }}
              >
                {/* Left Avatar Section */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    p: 4,
                    width: { xs: "40%", md: "25%" },
                    background: (theme) =>
                      alpha(theme.palette.primary.main, 0.1),
                  }}
                >
                  <Avatar
                    sx={{
                      width: 128,
                      height: 128,
                      bgcolor: (theme) => theme.palette.primary.main,
                      fontSize: "3rem",
                    }}
                  >
                    {vet.username.charAt(0)}
                  </Avatar>
                </Box>

                {/* Vet Details Section */}
                <Box
                  sx={{
                    flex: 1,
                    p: 4,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Typography variant="h5" fontWeight="bold" gutterBottom>
                      Dr. {vet.username}
                    </Typography>
                    <Chip
                      label={vet.specialization}
                      size="small"
                      color="primary"
                      sx={{ mb: 2 }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: 1,
                        gap: 1,
                      }}
                    >
                      <LocationOn
                        sx={{ fontSize: 20, color: "text.secondary" }}
                      />
                      <Typography variant="body1" color="text.secondary">
                        {vet.vetLocation}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: 1,
                        gap: 1,
                      }}
                    >
                      <AccessTime
                        sx={{ fontSize: 20, color: "text.secondary" }}
                      />
                      <Typography variant="body1" color="text.secondary">
                        Service Fee: ${vet.serviceFee}
                      </Typography>
                    </Box>
                  </Box>

                  <Button
                    variant="contained"
                    size="large"
                    sx={{
                      mt: 3,
                      alignSelf: "flex-start",
                      textTransform: "none",
                      borderRadius: 4,
                      px: 4,
                      py: 1.5,
                    }}
                    onClick={() => setSelectedVet(vet)}
                  >
                    View Schedule
                  </Button>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Booking Modal */}
        {selectedVet && (
          <Paper
            sx={{
              mt: 4,
              p: 4,
              borderRadius: 2,
              position: "relative",
            }}
          >
            <IconButton
              sx={{
                position: "absolute",
                right: 16,
                top: 16,
              }}
              onClick={() => setSelectedVet(null)}
            >
              <Close />
            </IconButton>

            <Typography variant="h5" fontWeight="bold" gutterBottom>
              Book an Appointment with Dr. {selectedVet.username}
            </Typography>
            <Divider sx={{ my: 2 }} />

            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>
                  Available Time Slots
                </Typography>
                <Paper variant="outlined" sx={{ p: 2, borderRadius: 2 }}>
                  <List
                    sx={{
                      display: "grid",
                      gridTemplateColumns:
                        "repeat(auto-fill, minmax(200px, 1fr))",
                      gap: 2,
                    }}
                  >
                    {selectedVet.availableTimeSlots.map((slot, index) => (
                      <ListItem
                        key={index}
                        sx={{
                          borderRadius: 1,
                          bgcolor:
                            selectedTimeSlot === slot
                              ? (theme) =>
                                  alpha(theme.palette.primary.main, 0.1)
                              : "transparent",
                          border: "1px solid",
                          borderColor: (theme) =>
                            slot.isTaken
                              ? theme.palette.error.main
                              : theme.palette.primary.light,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          textAlign: "center",
                          py: 2,
                        }}
                      >
                        <Typography
                          variant="body2"
                          fontWeight="bold"
                          sx={{ mb: 1 }}
                        >
                          {slot.date}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 1 }}>
                          {slot.startTime} - {slot.endTime}
                        </Typography>
                        {!slot.isTaken && (
                          <Button
                            variant={
                              selectedTimeSlot === slot
                                ? "contained"
                                : "outlined"
                            }
                            size="small"
                            sx={{ mt: 1 }}
                            onClick={() => setSelectedTimeSlot(slot)}
                          >
                            {selectedTimeSlot === slot ? "Selected" : "Select"}
                          </Button>
                        )}
                        {slot.isTaken && (
                          <Chip
                            label="Taken"
                            color="error"
                            size="small"
                            sx={{ mt: 1 }}
                          />
                        )}
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>
                  Appointment Details
                </Typography>
                <Paper variant="outlined" sx={{ p: 3, borderRadius: 2 }}>
                  <TextField
                    label="Describe your pet's problem"
                    fullWidth
                    multiline
                    rows={4}
                    sx={{ mb: 3 }}
                    value={appointmentDetails.problem}
                    onChange={(e) =>
                      setAppointmentDetails({
                        ...appointmentDetails,
                        problem: e.target.value,
                      })
                    }
                  />
                  <TextField
                    label="Additional Comments"
                    fullWidth
                    multiline
                    rows={2}
                    value={appointmentDetails.comment}
                    onChange={(e) =>
                      setAppointmentDetails({
                        ...appointmentDetails,
                        comment: e.target.value,
                      })
                    }
                  />
                  <Button
                    variant="contained"
                    fullWidth
                    size="large"
                    onClick={handleAppointmentSubmission}
                    sx={{
                      mt: 3,
                      borderRadius: 2,
                      py: 1.5,
                      textTransform: "none",
                      fontSize: "1.1rem",
                    }}
                  >
                    Confirm Appointment
                  </Button>
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        )}

        {/* Appointments List */}
        {appointments.length > 0 && (
          <Paper sx={{ mt: 4, p: 4, borderRadius: 2 }}>
            <Typography variant="h5" fontWeight="bold" gutterBottom>
              Your Upcoming Appointments
            </Typography>
            <Divider sx={{ mb: 3 }} />
            <Grid container spacing={2}>
              {appointments.map((appointment, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <Card variant="outlined" sx={{ borderRadius: 2 }}>
                    <CardContent>
                      <Box
                        sx={{ display: "flex", alignItems: "center", mb: 2 }}
                      >
                        <Avatar
                          sx={{
                            bgcolor: (theme) => theme.palette.primary.main,
                            mr: 2,
                          }}
                        >
                          {appointment.vetName.charAt(0)}
                        </Avatar>
                        <Box>
                          <Typography variant="subtitle1" fontWeight="bold">
                            Dr. {appointment.vetName}
                          </Typography>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CalendarMonth
                              sx={{
                                fontSize: 16,
                                mr: 0.5,
                                color: "text.secondary",
                              }}
                            />
                            <Typography variant="body2" color="text.secondary">
                              {appointment.date}
                            </Typography>
                            <AccessTime
                              sx={{
                                fontSize: 16,
                                ml: 2,
                                mr: 0.5,
                                color: "text.secondary",
                              }}
                            />
                            <Typography variant="body2" color="text.secondary">
                              {appointment.time}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ mb: 2 }}
                      >
                        {appointment.problem}
                      </Typography>
                      {/* <Button
  variant="contained"
  color="error"
  size="small"
  onClick={async () => {
    try {
      const appointmentRef = doc(db, "appointments", appointment.id); 
      await deleteDoc(appointmentRef); 
      setAppointments(prev => prev.filter((_, i) => i !== index)); 
      alert("Appointment canceled successfully.");
    } catch (error) {
      console.error("Error canceling appointment:", error);
      alert("An error occurred while canceling the appointment.");
    }
  }}
>
  Cancel Appointment
</Button> */}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Paper>
        )}
      </Container>
    </div>
  );
};

export default Vets;
