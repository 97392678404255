import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAt8KUSP3Sn6JAiVaihZa3MaYePmkN8CpU",
  authDomain: "animalx-d0cd6.firebaseapp.com",
  projectId: "animalx-d0cd6",
  storageBucket: "animalx-d0cd6.appspot.com",
  messagingSenderId: "539512520422",
  appId: "1:539512520422:web:ed3b0cc9232c677a088527",
  measurementId: "G-YLQ7HZG656",
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();

const analytics = getAnalytics(app);

const storage = getStorage(app);

export { db, auth, googleProvider, analytics, storage };
