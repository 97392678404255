import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Paper,
  Tabs,
  Tab,
  Box,
  Button,
  TextField,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import {
  Logout,
  Person,
  Pets,
  ShoppingCart,
  Forum,
  CalendarMonth,
  Article,
  Work,
  SupportAgent,
  Terminal,
  Shield,
} from "@mui/icons-material";
import UserManagement from "./admin/UserManagement";
import AnimalManagement from "./admin/AnimalManagement";
import AdManagement from "./admin/AdManagement";
import ForumManagement from "./admin/ForumManagement";
import AppointmentManagement from "./admin/AppointmentManagement";
import ArticleManagement from "./admin/ArticleManagement";
import VacancyManagement from "./admin/VacancyManagement";
import SupportTicketManagement from "./admin/SupportTicketManagement";

const ADMIN_PASSWORD = "admin";

const hackerTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#4ADE80",
    },
    secondary: {
      main: "#4F46E5",
    },
    background: {
      default: "#0F172A",
      paper: "#1E293B",
    },
    text: {
      primary: "#E2E8F0",
      secondary: "#94A3B8",
    },
  },
  typography: {
    fontFamily: "'JetBrains Mono', 'Roboto Mono', monospace",
    h4: {
      fontWeight: 600,
      letterSpacing: "0.1em",
    },
    body1: {
      fontSize: "0.95rem",
      letterSpacing: "0.03em",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: "none",
          fontWeight: 600,
          padding: "8px 16px",
          transition: "all 0.2s ease",
          "&:hover": {
            transform: "translateY(-1px)",
            boxShadow: "0 4px 12px rgba(74, 222, 128, 0.15)",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage:
            "linear-gradient(145deg, rgba(74, 222, 128, 0.03), rgba(79, 70, 229, 0.03))",
          borderRadius: 12,
          border: "1px solid rgba(74, 222, 128, 0.1)",
          backdropFilter: "blur(12px)",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            borderRadius: 8,
            "& fieldset": {
              borderColor: "rgba(74, 222, 128, 0.2)",
            },
            "&:hover fieldset": {
              borderColor: "rgba(74, 222, 128, 0.3)",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#4ADE80",
            },
          },
        },
      },
    },
  },
});

const AdminDashboard: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();

  const MatrixBackground = () => (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
        opacity: 0.05,
        background: `
          linear-gradient(rgba(74, 222, 128, 0.1) 1px, transparent 1px),
          linear-gradient(90deg, rgba(74, 222, 128, 0.1) 1px, transparent 1px)
        `,
        backgroundSize: "20px 20px",
      }}
    />
  );

  const handleLogin = () => {
    if (password === ADMIN_PASSWORD) {
      setIsAuthenticated(true);
    } else {
      alert("Invalid credentials. Please try again.");
    }
  };

  if (!isAuthenticated) {
    return (
      <ThemeProvider theme={hackerTheme}>
        <Box
          sx={{
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            background: "linear-gradient(145deg, #0F172A 0%, #1E293B 100%)",
          }}
        >
          <MatrixBackground />
          <Container maxWidth="sm">
            <Paper
              elevation={4}
              sx={{
                padding: 4,
                textAlign: "center",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <Shield
                sx={{
                  fontSize: 48,
                  color: "primary.main",
                  mb: 3,
                  opacity: 0.9,
                }}
              />
              <Typography
                variant="h4"
                sx={{
                  mb: 3,
                  color: "text.primary",
                }}
              >
                Admin Access
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  mb: 4,
                  color: "text.secondary",
                }}
              >
                Enter your credentials to access the system
              </Typography>
              <TextField
                label="Security Key"
                type="password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{ mb: 3 }}
              />
              <Button
                variant="contained"
                fullWidth
                onClick={handleLogin}
                sx={{
                  mb: 2,
                  height: 48,
                  backgroundColor: "primary.main",
                }}
              >
                Access Dashboard
              </Button>
              <Button
                variant="text"
                onClick={() => navigate("/dashboard")}
                sx={{
                  color: "text.secondary",
                  "&:hover": {
                    color: "text.primary",
                  },
                }}
              >
                Return to Main Dashboard
              </Button>
            </Paper>
          </Container>
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={hackerTheme}>
      <Box
        sx={{
          minHeight: "100vh",
          background: "linear-gradient(145deg, #0F172A 0%, #1E293B 100%)",
          pb: 8,
        }}
      >
        <MatrixBackground />
        <Container>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ pt: 4, pb: 4 }}
          >
            <Box display="flex" alignItems="center" gap={2}>
              <Terminal
                sx={{
                  fontSize: 36,
                  color: "primary.main",
                }}
              />
              <Typography variant="h4" color="text.primary">
                Admin Control
              </Typography>
            </Box>
            <Button
              variant="outlined"
              color="error"
              startIcon={<Logout />}
              onClick={() => setIsAuthenticated(false)}
              sx={{
                borderColor: "rgba(239, 68, 68, 0.5)",
                color: "#EF4444",
                "&:hover": {
                  borderColor: "#EF4444",
                  backgroundColor: "rgba(239, 68, 68, 0.05)",
                },
              }}
            >
              Sign Out
            </Button>
          </Box>

          <Paper sx={{ mb: 4, bgcolor: "background.paper" }}>
            <Tabs
              value={tabValue}
              onChange={(e, newValue) => setTabValue(newValue)}
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                "& .MuiTab-root": {
                  minHeight: 64,
                  minWidth: 120,
                  fontWeight: 500,
                  color: "text.secondary",
                  "&.Mui-selected": {
                    color: "primary.main",
                  },
                  "&:hover": {
                    backgroundColor: "rgba(74, 222, 128, 0.05)",
                  },
                },
              }}
            >
              {[
                { label: "Users", icon: <Person /> },
                { label: "Assets", icon: <Pets /> },
                { label: "Market", icon: <ShoppingCart /> },
                { label: "Forum", icon: <Forum /> },
                { label: "Schedule", icon: <CalendarMonth /> },
                { label: "Docs", icon: <Article /> },
                { label: "Jobs", icon: <Work /> },
                { label: "Support", icon: <SupportAgent /> },
              ].map((tab) => (
                <Tab
                  key={tab.label}
                  icon={tab.icon}
                  label={tab.label}
                  sx={{
                    textTransform: "none",
                    fontSize: "0.9rem",
                  }}
                />
              ))}
            </Tabs>
          </Paper>

          <Paper
            sx={{
              p: 4,
              minHeight: "60vh",
              bgcolor: "background.paper",
            }}
          >
            {tabValue === 0 && <UserManagement />}
            {tabValue === 1 && <AnimalManagement />}
            {tabValue === 2 && <AdManagement />}
            {tabValue === 3 && <ForumManagement />}
            {tabValue === 4 && <AppointmentManagement />}
            {tabValue === 5 && <ArticleManagement />}
            {tabValue === 6 && <VacancyManagement />}
            {tabValue === 7 && <SupportTicketManagement />}
          </Paper>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default AdminDashboard;
