import React, { useState, useEffect } from "react";
import { db } from "../services/firebase";
import { collection, getDocs, query, addDoc } from "firebase/firestore";
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Fade,
  IconButton,
  InputAdornment,
  Divider,
  Chip,
  Select,
  MenuItem,
  FormControl,
  Paper,
} from "@mui/material";
import {
  Search as SearchIcon,
  LocalMall as LocalMallIcon,
  LocationOn as LocationIcon,
  AccessTime as TimeIcon,
  ShoppingCart as CartIcon,
  Star as StarIcon,
  Close as CloseIcon,
  Category as CategoryIcon,
  ArrowForward as ArrowForwardIcon,
  Notes as NotesIcon,
  Phone as PhoneIcon,
  Home as HomeIcon,
} from "@mui/icons-material";
import { SelectChangeEvent } from "@mui/material";

interface OurMarketAd {
  id: string;
  title: string;
  description: string;
  category: string;
  location: string;
  deliveryTime: string;
  price: number;
  originalPrice: number;
  orders: number;
  reviews: number;
  imageUrl?: string;
}
const OurMarket: React.FC = () => {
  const [ads, setAds] = useState<OurMarketAd[]>([]);
  const [filteredAds, setFilteredAds] = useState<OurMarketAd[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [categories, setCategories] = useState<string[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedAd, setSelectedAd] = useState<OurMarketAd | null>(null);
  const [orderInfo, setOrderInfo] = useState({
    address: "",
    contact: "",
    comment: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [locationFilter, setLocationFilter] = useState("");
  const [locations, setLocations] = useState<string[]>([]);

  const fetchAds = async () => {
    try {
      const querySnapshot = await getDocs(
        query(collection(db, "our-market-ads"))
      );
      const adsList: OurMarketAd[] = [];
      querySnapshot.forEach((doc) => {
        adsList.push({ id: doc.id, ...doc.data() } as OurMarketAd);
      });
      setAds(adsList);
      setFilteredAds(adsList);
      setCategories(Array.from(new Set(adsList.map((ad) => ad.category))));
      setLocations(Array.from(new Set(adsList.map((ad) => ad.location))));
    } catch (error) {
      console.error("Error fetching ads:", error);
    }
  };

  const handleLocationChange = (event: SelectChangeEvent<string>) => {
    const location = event.target.value as string;
    setLocationFilter(location);
    filterAds(searchQuery, categoryFilter, location);
  };

  useEffect(() => {
    fetchAds();
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    filterAds(e.target.value, categoryFilter, locationFilter); 
  };

  const handleCategoryChange = (event: SelectChangeEvent<string>) => {
    const category = event.target.value as string;
    setCategoryFilter(category);
    filterAds(searchQuery, category, locationFilter); 
  };
  const handleImageError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.style.display = "none";
    event.currentTarget.parentElement
      ?.querySelector(".fallback-icon")
      ?.setAttribute("style", "display: flex !important");
  };

  const filterAds = (query: string, category: string, location: string) => {
    let filtered = ads;
    if (query) {
      filtered = filtered.filter(
        (ad) =>
          ad.title.toLowerCase().includes(query.toLowerCase()) ||
          ad.description.toLowerCase().includes(query.toLowerCase())
      );
    }
    if (category) {
      filtered = filtered.filter((ad) => ad.category === category);
    }
    if (location) {
      
      filtered = filtered.filter((ad) => ad.location === location);
    }
    setFilteredAds(filtered);
  };

  const handleOpenModal = (ad: OurMarketAd) => {
    setSelectedAd(ad);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOrderInfo({ address: "", contact: "", comment: "" });
    setSelectedAd(null);
    setOpenModal(false);
  };

  const handleOrderInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderInfo((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmitOrder = async () => {
    if (!selectedAd) return;
    setIsSubmitting(true);
    try {
      await addDoc(collection(db, "orders"), {
        adId: selectedAd.id,
        address: orderInfo.address,
        contact: orderInfo.contact,
        comment: orderInfo.comment,
        orderedAt: new Date().toISOString(),
      });
      setIsSubmitting(false);
      handleCloseModal();
    } catch (error) {
      console.error("Error submitting order:", error);
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        bgcolor: "#FFFFFF",
        minHeight: "100vh",
        px: { xs: 2, sm: 4, md: 8 },
        py: { xs: 4, sm: 6, md: 8 },
      }}
    >
      <Fade in timeout={1000}>
        <Typography
          variant="h2"
          sx={{
            fontWeight: 200,
            letterSpacing: 2,
            color: "#1a1a1a",
            textAlign: "center",
            mb: 8,
            fontSize: { xs: "2.5rem", md: "3.5rem" },
          }}
        >
          Discover Our Products
        </Typography>
      </Fade>

      <Paper
        elevation={0}
        sx={{
          mb: 6,
          p: 3,
          borderRadius: 4,
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 2,
          backgroundColor: "#fafafa",
          border: "1px solid #f0f0f0",
        }}
      >
        <TextField
          fullWidth
          placeholder="Search products..."
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: "#666" }} />
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              backgroundColor: "#fff",
              borderRadius: 2,
              "&:hover fieldset": { borderColor: "#000" },
              "& fieldset": { borderColor: "#e0e0e0" },
            },
          }}
        />
        <FormControl sx={{ minWidth: 200 }}>
          <Select
            value={categoryFilter}
            onChange={handleCategoryChange}
            displayEmpty
            startAdornment={<CategoryIcon sx={{ mr: 1, color: "#666" }} />}
            renderValue={categoryFilter ? undefined : () => "All Categories"}
            sx={{
              backgroundColor: "#fff",
              borderRadius: 2,
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#e0e0e0",
              },
            }}
          >
            <MenuItem value="">All Categories</MenuItem>
            {categories.map((category) => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 200 }}>
          <Select
            value={locationFilter}
            onChange={handleLocationChange}
            displayEmpty
            startAdornment={<LocationIcon sx={{ mr: 1, color: "#666" }} />}
            renderValue={locationFilter ? undefined : () => "All Locations"}
            sx={{
              backgroundColor: "#fff",
              borderRadius: 2,
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#e0e0e0",
              },
            }}
          >
            <MenuItem value="">All Locations</MenuItem>
            {locations.map((location) => (
              <MenuItem key={location} value={location}>
                {location}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Paper>

      <Grid container spacing={4}>
        {filteredAds.map((ad) => (
          <Grid item xs={12} sm={6} md={4} key={ad.id}>
            <Fade in timeout={800}>
              <Card
                sx={{
                  borderRadius: 3,
                  border: "1px solid #f0f0f0",
                  boxShadow: "none",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    transform: "translateY(-8px)",
                    boxShadow: "0 12px 24px rgba(0,0,0,0.1)",
                  },
                }}
              >
                <CardContent sx={{ p: 4 }}>
                  <Box mb={3}>
                    <Box
                      sx={{
                        position: "relative",
                        height: 200,
                        backgroundColor: "#fafafa",
                        borderRadius: 2,
                        overflow: "hidden",
                        mb: 3,
                      }}
                    >
                      {ad.imageUrl && (
                        <Box
                          component="img"
                          src={ad.imageUrl}
                          alt={ad.title}
                          onError={handleImageError}
                          sx={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      )}
                      <Box
                        className="fallback-icon"
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          display: ad.imageUrl ? "none" : "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#fafafa",
                        }}
                      >
                        <LocalMallIcon sx={{ fontSize: 64, color: "#666" }} />
                      </Box>
                    </Box>

                    <Chip
                      icon={<CategoryIcon sx={{ fontSize: 16 }} />}
                      label={ad.category}
                      size="small"
                      sx={{
                        mb: 2,
                        backgroundColor: "#f5f5f5",
                        color: "#666",
                        borderRadius: 1,
                      }}
                    />

                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 500,
                        mb: 1,
                        color: "#1a1a1a",
                        lineHeight: 1.4,
                      }}
                    >
                      {ad.title}
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{
                        color: "#666",
                        mb: 3,
                        lineHeight: 1.6,
                        height: 48,
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {ad.description}
                    </Typography>
                  </Box>

                  <Divider sx={{ mb: 3 }} />

                  <Grid container spacing={2} sx={{ mb: 3 }}>
                    <Grid item xs={6}>
                      <Box display="flex" alignItems="center" gap={1}>
                        <LocationIcon sx={{ fontSize: 18, color: "#666" }} />
                        <Typography variant="body2" color="#666">
                          {ad.location}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box display="flex" alignItems="center" gap={1}>
                        <TimeIcon sx={{ fontSize: 18, color: "#666" }} />
                        <Typography variant="body2" color="#666">
                          {ad.deliveryTime}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-end"
                    mb={3}
                  >
                    <Box>
                      <Typography
                        variant="h5"
                        sx={{ fontWeight: 600, color: "#1a1a1a" }}
                      >
                        ${ad.price}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: "#999", textDecoration: "line-through" }}
                      >
                        ${ad.originalPrice}
                      </Typography>
                    </Box>
                    <Box display="flex" gap={2}>
                      <Box display="flex" alignItems="center" gap={0.5}>
                        <CartIcon sx={{ fontSize: 18, color: "#666" }} />
                        <Typography variant="body2" color="#666">
                          {ad.orders}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" gap={0.5}>
                        <StarIcon sx={{ fontSize: 18, color: "#666" }} />
                        <Typography variant="body2" color="#666">
                          {ad.reviews}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => handleOpenModal(ad)}
                    endIcon={<ArrowForwardIcon />}
                    sx={{
                      py: 1.5,
                      backgroundColor: "primary",
                      borderRadius: 2,
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "primary.dark",
                      },
                    }}
                  >
                    Purchase Now
                  </Button>
                </CardContent>
              </Card>
            </Fade>
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 3,
            p: 3,
          },
        }}
      >
        <DialogTitle
          sx={{
            p: 0,
            mb: 3,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 500 }}>
            Complete Purchase
          </Typography>
          <IconButton
            onClick={handleCloseModal}
            size="small"
            sx={{ color: "#666" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ p: 0 }}>
          <TextField
            label="Delivery Address"
            name="address"
            value={orderInfo.address}
            onChange={handleOrderInfoChange}
            fullWidth
            required
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HomeIcon sx={{ color: "#666" }} />
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: 2,
              },
            }}
          />

          <TextField
            label="Contact Number"
            name="contact"
            value={orderInfo.contact}
            onChange={handleOrderInfoChange}
            fullWidth
            required
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneIcon sx={{ color: "#666" }} />
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: 2,
              },
            }}
          />

          <TextField
            label="Additional Notes"
            name="comment"
            value={orderInfo.comment}
            onChange={handleOrderInfoChange}
            fullWidth
            margin="normal"
            multiline
            rows={3}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <NotesIcon sx={{ color: "#666" }} />
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: 2,
              },
            }}
          />
        </DialogContent>

        <DialogActions sx={{ p: 0, mt: 3 }}>
          <Button
            onClick={handleCloseModal}
            sx={{
              color: "#666",
              "&:hover": {
                backgroundColor: "#f5f5f5",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmitOrder}
            variant="contained"
            disabled={isSubmitting}
            endIcon={
              isSubmitting ? (
                <CircularProgress size={20} />
              ) : (
                <ArrowForwardIcon />
              )
            }
            sx={{
              px: 4,
              py: 1.5,
              borderRadius: 2,
              backgroundColor: "#1a1a1a",
              "&:hover": {
                backgroundColor: "#000",
              },
            }}
          >
            {isSubmitting ? "Processing..." : "Confirm Order"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default OurMarket;
