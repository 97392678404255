import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Tabs,
  Tab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Alert,
  Snackbar,
  CircularProgress,
  TablePagination,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  ExpandMore as ExpandMoreIcon,
  Reply as ReplyIcon,
} from "@mui/icons-material";
import {
  collection,
  query,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  orderBy,
  Timestamp,
} from "firebase/firestore";
import { db } from "./../../services/firebase";

interface Ticket {
  id: string;
  userId: string;
  subject: string;
  description: string;
  status: "open" | "in-progress" | "closed";
  createdAt: Date;
  updatedAt: Date;
  messages: TicketMessage[];
}

interface TicketMessage {
  id: string;
  ticketId: string;
  userId: string;
  message: string;
  createdAt: Date;
  isStaff: boolean;
}

interface FAQ {
  id: string;
  question: string;
  answer: string;
  category: string;
  tags: string[];
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const SupportTicketManagement = () => {
  const [tabValue, setTabValue] = useState(0);
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [faqs, setFaqs] = useState<FAQ[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);
  const [selectedFAQ, setSelectedFAQ] = useState<FAQ | null>(null);
  const [isAddFAQDialogOpen, setIsAddFAQDialogOpen] = useState(false);
  const [isReplyDialogOpen, setIsReplyDialogOpen] = useState(false);
  const [replyMessage, setReplyMessage] = useState("");
  const [newFAQ, setNewFAQ] = useState<Omit<FAQ, "id">>({
    question: "",
    answer: "",
    category: "",
    tags: [],
  });

  useEffect(() => {
    fetchTickets();
    fetchFAQs();
  }, []);

  const fetchTickets = async () => {
    setLoading(true);
    try {
      const ticketsRef = collection(db, "tickets");
      const ticketsQuery = query(ticketsRef, orderBy("createdAt", "desc"));
      const snapshot = await getDocs(ticketsQuery);
      const fetchedTickets = snapshot.docs.map(
        (doc) =>
          ({
            id: doc.id,
            ...doc.data(),
            createdAt: doc.data().createdAt.toDate(),
            updatedAt: doc.data().updatedAt.toDate(),
          } as Ticket)
      );
      setTickets(fetchedTickets);
    } catch (err) {
      setError("Failed to fetch tickets");
      console.error(err);
    }
    setLoading(false);
  };

  const fetchFAQs = async () => {
    setLoading(true);
    try {
      const faqsRef = collection(db, "faqs");
      const snapshot = await getDocs(faqsRef);
      const fetchedFaqs = snapshot.docs.map(
        (doc) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as FAQ)
      );
      setFaqs(fetchedFaqs);
    } catch (err) {
      setError("Failed to fetch FAQs");
      console.error(err);
    }
    setLoading(false);
  };

  const handleStatusChange = async (
    ticketId: string,
    newStatus: "open" | "in-progress" | "closed"
  ) => {
    try {
      const ticketRef = doc(db, "tickets", ticketId);
      await updateDoc(ticketRef, {
        status: newStatus,
        updatedAt: Timestamp.now(),
      });
      setTickets(
        tickets.map((ticket) =>
          ticket.id === ticketId
            ? { ...ticket, status: newStatus, updatedAt: new Date() }
            : ticket
        )
      );
      setSuccess("Ticket status updated successfully");
    } catch (err) {
      setError("Failed to update ticket status");
      console.error(err);
    }
  };

  const handleReplySubmit = async () => {
    if (!selectedTicket || !replyMessage.trim()) return;

    try {
      const messageData: Omit<TicketMessage, "id"> = {
        ticketId: selectedTicket.id,
        userId: "admin",
        message: replyMessage,
        createdAt: new Date(),
        isStaff: true,
      };

      const ticketRef = doc(db, "tickets", selectedTicket.id);
      const updatedMessages = [
        ...selectedTicket.messages,
        { ...messageData, id: Math.random().toString(36).substr(2, 9) },
      ];

      await updateDoc(ticketRef, {
        messages: updatedMessages,
        updatedAt: Timestamp.now(),
      });

      setTickets(
        tickets.map((ticket) =>
          ticket.id === selectedTicket.id
            ? { ...ticket, messages: updatedMessages, updatedAt: new Date() }
            : ticket
        )
      );

      setReplyMessage("");
      setIsReplyDialogOpen(false);
      setSuccess("Reply sent successfully");
    } catch (err) {
      setError("Failed to send reply");
      console.error(err);
    }
  };

  const handleAddFAQ = async () => {
    try {
      const docRef = await addDoc(collection(db, "faqs"), newFAQ);
      const newFAQWithId = { ...newFAQ, id: docRef.id };
      setFaqs([...faqs, newFAQWithId]);
      setIsAddFAQDialogOpen(false);
      setNewFAQ({ question: "", answer: "", category: "", tags: [] });
      setSuccess("FAQ added successfully");
    } catch (err) {
      setError("Failed to add FAQ");
      console.error(err);
    }
  };

  const handleUpdateFAQ = async (faqId: string, updatedData: Partial<FAQ>) => {
    try {
      const faqRef = doc(db, "faqs", faqId);
      await updateDoc(faqRef, updatedData);
      setFaqs(
        faqs.map((faq) => (faq.id === faqId ? { ...faq, ...updatedData } : faq))
      );
      setSuccess("FAQ updated successfully");
    } catch (err) {
      setError("Failed to update FAQ");
      console.error(err);
    }
  };

  const handleDeleteFAQ = async (faqId: string) => {
    try {
      await deleteDoc(doc(db, "faqs", faqId));
      setFaqs(faqs.filter((faq) => faq.id !== faqId));
      setSuccess("FAQ deleted successfully");
    } catch (err) {
      setError("Failed to delete FAQ");
      console.error(err);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        Support Ticket Management
      </Typography>

      <Paper sx={{ width: "100%", mb: 2 }}>
        <Tabs
          value={tabValue}
          onChange={(_, newValue) => setTabValue(newValue)}
          aria-label="support management tabs"
        >
          <Tab label="Support Tickets" />
          <Tab label="FAQs Management" />
        </Tabs>

        {/* Tickets Tab */}
        <TabPanel value={tabValue} index={0}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Subject</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell>Last Updated</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tickets
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((ticket) => (
                    <TableRow key={ticket.id}>
                      <TableCell>{ticket.id.slice(0, 8)}...</TableCell>
                      <TableCell>{ticket.subject}</TableCell>
                      <TableCell>
                        <FormControl size="small">
                          <Select
                            value={ticket.status}
                            onChange={(e) =>
                              handleStatusChange(
                                ticket.id,
                                e.target.value as
                                  | "open"
                                  | "in-progress"
                                  | "closed"
                              )
                            }
                          >
                            <MenuItem value="open">Open</MenuItem>
                            <MenuItem value="in-progress">In Progress</MenuItem>
                            <MenuItem value="closed">Closed</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>{ticket.createdAt.toLocaleString()}</TableCell>
                      <TableCell>{ticket.updatedAt.toLocaleString()}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            setSelectedTicket(ticket);
                            setIsReplyDialogOpen(true);
                          }}
                        >
                          <ReplyIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={tickets.length}
            page={page}
            onPageChange={(_, newPage) => setPage(newPage)}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setPage(0);
            }}
          />
        </TabPanel>

        {/* FAQs Tab */}
        <TabPanel value={tabValue} index={1}>
          <Box sx={{ mb: 2 }}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setIsAddFAQDialogOpen(true)}
            >
              Add New FAQ
            </Button>
          </Box>

          {faqs.map((faq) => (
            <Accordion key={faq.id}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="body1" gutterBottom>
                    {faq.answer}
                  </Typography>
                  <Box sx={{ mt: 1 }}>
                    {faq.tags.map((tag) => (
                      <Chip key={tag} label={tag} sx={{ mr: 1 }} />
                    ))}
                  </Box>
                </Box>
                <Box>
                  <Button
                    startIcon={<EditIcon />}
                    onClick={() => setSelectedFAQ(faq)}
                    sx={{ mr: 1 }}
                  >
                    Edit
                  </Button>
                  <Button
                    startIcon={<DeleteIcon />}
                    color="error"
                    onClick={() => handleDeleteFAQ(faq.id)}
                  >
                    Delete
                  </Button>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </TabPanel>
      </Paper>

      {/* Reply Dialog */}
      <Dialog
        open={isReplyDialogOpen}
        onClose={() => setIsReplyDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Reply to Ticket</DialogTitle>
        <DialogContent>
          {selectedTicket && (
            <>
              <Box sx={{ mb: 2 }}>
                <Typography variant="h6">{selectedTicket.subject}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {selectedTicket.description}
                </Typography>
              </Box>
              <Box sx={{ mb: 2 }}>
                {selectedTicket.messages.map((message, index) => (
                  <Paper
                    key={index}
                    sx={{
                      p: 2,
                      mb: 1,
                      backgroundColor: message.isStaff ? "#f5f5f5" : "#e3f2fd",
                    }}
                  >
                    <Typography variant="body2">{message.message}</Typography>
                    <Typography variant="caption" color="text.secondary">
                      {message.createdAt.toLocaleString()} -{" "}
                      {message.isStaff ? "Staff" : "User"}
                    </Typography>
                  </Paper>
                ))}
              </Box>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Your Reply"
                value={replyMessage}
                onChange={(e) => setReplyMessage(e.target.value)}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsReplyDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleReplySubmit}
            variant="contained"
            color="primary"
          >
            Send Reply
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add FAQ Dialog */}
      <Dialog
        open={isAddFAQDialogOpen}
        onClose={() => setIsAddFAQDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Add New FAQ</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Question"
            value={newFAQ.question}
            onChange={(e) => setNewFAQ({ ...newFAQ, question: e.target.value })}
            sx={{ mb: 2, mt: 2 }}
          />
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Answer"
            value={newFAQ.answer}
            onChange={(e) => setNewFAQ({ ...newFAQ, answer: e.target.value })}
            sx={{ mb: 2 }}
          />
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Category</InputLabel>
            <Select
              value={newFAQ.category}
              onChange={(e) =>
                setNewFAQ({ ...newFAQ, category: e.target.value })
              }
              label="Category"
            >
              <MenuItem value="general">General</MenuItem>
              <MenuItem value="account">Account</MenuItem>
              <MenuItem value="billing">Billing</MenuItem>
              <MenuItem value="technical">Technical</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Tags (comma-separated)"
            value={newFAQ.tags.join(", ")}
            onChange={(e) =>
              setNewFAQ({
                ...newFAQ,
                tags: e.target.value
                  .split(",")
                  .map((tag) => tag.trim())
                  .filter(Boolean),
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAddFAQDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleAddFAQ} variant="contained" color="primary">
            Add FAQ
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit FAQ Dialog */}
      <Dialog
        open={Boolean(selectedFAQ)}
        onClose={() => setSelectedFAQ(null)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Edit FAQ</DialogTitle>
        <DialogContent>
          {selectedFAQ && (
            <>
              <TextField
                fullWidth
                label="Question"
                value={selectedFAQ.question}
                onChange={(e) =>
                  setSelectedFAQ({
                    ...selectedFAQ,
                    question: e.target.value,
                  })
                }
                sx={{ mb: 2, mt: 2 }}
              />
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Answer"
                value={selectedFAQ.answer}
                onChange={(e) =>
                  setSelectedFAQ({
                    ...selectedFAQ,
                    answer: e.target.value,
                  })
                }
                sx={{ mb: 2 }}
              />
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Category</InputLabel>
                <Select
                  value={selectedFAQ.category}
                  onChange={(e) =>
                    setSelectedFAQ({
                      ...selectedFAQ,
                      category: e.target.value,
                    })
                  }
                  label="Category"
                >
                  <MenuItem value="general">General</MenuItem>
                  <MenuItem value="account">Account</MenuItem>
                  <MenuItem value="billing">Billing</MenuItem>
                  <MenuItem value="technical">Technical</MenuItem>
                </Select>
              </FormControl>
              <TextField
                fullWidth
                label="Tags (comma-separated)"
                value={selectedFAQ.tags.join(", ")}
                onChange={(e) =>
                  setSelectedFAQ({
                    ...selectedFAQ,
                    tags: e.target.value
                      .split(",")
                      .map((tag) => tag.trim())
                      .filter(Boolean),
                  })
                }
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedFAQ(null)}>Cancel</Button>
          <Button
            onClick={() => {
              if (selectedFAQ) {
                handleUpdateFAQ(selectedFAQ.id, selectedFAQ);
                setSelectedFAQ(null);
              }
            }}
            variant="contained"
            color="primary"
          >
            Update FAQ
          </Button>
        </DialogActions>
      </Dialog>

      {/* Loading Indicator */}
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {/* Error and Success Messages */}
      <Snackbar
        open={Boolean(error)}
        autoHideDuration={6000}
        onClose={() => setError("")}
      >
        <Alert severity="error" onClose={() => setError("")}>
          {error}
        </Alert>
      </Snackbar>

      <Snackbar
        open={Boolean(success)}
        autoHideDuration={6000}
        onClose={() => setSuccess("")}
      >
        <Alert severity="success" onClose={() => setSuccess("")}>
          {success}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default SupportTicketManagement;
