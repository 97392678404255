import React, { useState, useEffect } from "react";
import { db, auth } from "../services/firebase";
import {
  collection,
  doc,
  getDoc,
  setDoc,
  onSnapshot,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import {
  Container,
  Typography,
  TextField,
  IconButton,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Box,
  Button,
  Fade,
  Chip,
  Divider,
  Skeleton,
  CircularProgress,
  Paper,
  alpha,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ForumIcon from "@mui/icons-material/Forum";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { v4 as uuidv4 } from "uuid";
import PlatformMenu from "../components/PlatformMenu";

interface Post {
  id: string;
  content: string;
  author: string;
  authorId: string;
  timestamp: any;
}

export interface Thread {
  id: string;
  title: string;
  description: string;
  creatorId: string;
  posts: Post[];
  icon?: JSX.Element;
}

const predefinedThreads: Thread[] = [
  {
    id: "1",
    title: "General Discussion",
    description: "Discuss anything related to farm animals and animal welfare.",
    creatorId: "admin",
    posts: [],
  },
  {
    id: "2",
    title: "Animal Health & Vaccination",
    description:
      "Share and discuss tips about animal health, vaccination schedules, and disease prevention.",
    creatorId: "admin",
    posts: [],
  },
  {
    id: "3",
    title: "Veterinary Services",
    description:
      "Connect with vets, discuss medical issues, and review veterinary services.",
    creatorId: "admin",
    posts: [],
  },
  {
    id: "4",
    title: "Farm Management",
    description:
      "Exchange tips on managing farms efficiently and ensuring animal welfare.",
    creatorId: "admin",
    posts: [],
  },
  {
    id: "5",
    title: "Marketplace Discussions",
    description:
      "Discuss buying, selling, and reviewing products available in the marketplace.",
    creatorId: "admin",
    posts: [],
  },
  {
    id: "6",
    title: "Career & Training Opportunities",
    description:
      "Explore career options, training programs, and government vacancies related to animal care.",
    creatorId: "admin",
    posts: [],
  },
  {
    id: "7",
    title: "Animal Breeding & Genetics",
    description:
      "Discuss strategies, techniques, and science behind animal breeding.",
    creatorId: "admin",
    posts: [],
  },
  {
    id: "8",
    title: "Emerging Farming Technologies",
    description:
      "Discuss new tools and technologies for improving animal farming and welfare.",
    creatorId: "admin",
    posts: [],
  },
  {
    id: "9",
    title: "Regulations & Legal Guidelines",
    description:
      "Share and discuss legal guidelines and government regulations on animal farming.",
    creatorId: "admin",
    posts: [],
  },
  {
    id: "10",
    title: "Success Stories & Inspiration",
    description:
      "Share success stories, experiences, and milestones in animal farming.",
    creatorId: "admin",
    posts: [],
  },
  {
    id: "11",
    title: "Platform Feedback & Feature Requests",
    description: "Provide feedback on the platform and suggest new features.",
    creatorId: "admin",
    posts: [],
  },
];

const Forum: React.FC = () => {
  const [user, setUser] = useState<any>(null);
  const [threads, setThreads] = useState<Thread[]>([]);
  const [selectedThreadId, setSelectedThreadId] = useState<string | null>(null);
  const [newPostContent, setNewPostContent] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true); 

  const [postToDelete, setPostToDelete] = useState<{
    threadId: string;
    post: Post;
  } | null>(null);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => setUser(user));
  }, []);

  useEffect(() => {
    const loadThreads = async () => {
      setLoading(true); 
      const threadsRef = collection(db, "threads");

      for (let thread of predefinedThreads) {
        const threadDocRef = doc(threadsRef, thread.id);
        const threadSnapshot = await getDoc(threadDocRef);
        if (!threadSnapshot.exists()) {
          await setDoc(threadDocRef, thread);
        }
      }

      const unsubscribe = onSnapshot(threadsRef, (snapshot) => {
        const loadedThreads = snapshot.docs.map((doc) => ({
          id: doc.id,
          title: doc.data().title,
          description: doc.data().description,
          creatorId: doc.data().creatorId,
          posts: doc.data().posts || [],
        }));
        setThreads(loadedThreads);
        setLoading(false); 
      });

      return () => unsubscribe();
    };
    loadThreads();
  }, []);

  const handleAddPost = async () => {
    if (!newPostContent || !selectedThreadId || !user) return;

    const newPost: Post = {
      id: uuidv4(),
      content: newPostContent,
      author: user.displayName,
      authorId: user.uid,
      timestamp: new Date(),
    };

    const threadRef = doc(db, "threads", selectedThreadId);
    await updateDoc(threadRef, {
      posts: arrayUnion(newPost),
    });

    setNewPostContent("");
  };

  const handleDeleteClick = (threadId: string, post: Post) => {
    if (post.authorId !== user?.uid) {
      return;
    }
    setPostToDelete({ threadId, post });
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (!postToDelete || postToDelete.post.authorId !== user?.uid) {
      return;
    }

    const threadRef = doc(db, "threads", postToDelete.threadId);
    try {
      await updateDoc(threadRef, {
        posts: arrayRemove(postToDelete.post),
      });
      setDeleteDialogOpen(false);
      setPostToDelete(null);
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  const handleBackToThreads = () => {
    setSelectedThreadId(null);
  };

  return (
    <Box sx={{ bgcolor: "#F5F7FA", minHeight: "100vh" }}>
      <PlatformMenu />
      <Container maxWidth="lg" sx={{ pt: 6, pb: 8 }}>
        {/* Hero Section */}

        <Fade in timeout={800}>
          <Paper
            elevation={0}
            sx={{
              mb: 6,
              background: (theme) =>
                `linear-gradient(135deg, ${theme.palette.primary.main}, ${alpha(
                  theme.palette.primary.light,
                  0.8
                )})`,
              borderRadius: 4,
              p: 6,
              color: "white",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: -20,
                right: -20,
                opacity: 0.1,
                transform: "rotate(-15deg)",
              }}
            >
              <ForumIcon sx={{ fontSize: 180 }} />
            </Box>
            <Typography
              variant="h3"
              fontWeight="bold"
              gutterBottom
              sx={{ letterSpacing: -0.5 }}
            >
              Community Forum
            </Typography>
            {user ? (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <PersonOutlineIcon />
                <Typography variant="h6" sx={{ opacity: 0.9 }}>
                  Welcome back, {user.displayName}
                </Typography>
              </Box>
            ) : (
              <Typography variant="h6" sx={{ opacity: 0.9 }}>
                Join the conversation
              </Typography>
            )}
          </Paper>
        </Fade>

        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", my: 10 }}>
            <CircularProgress size={50} color="primary" />
          </Box>
        ) : (
          <></>
        )}

        {selectedThreadId === null ? (
          <List sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {threads.map((thread) => (
              <Fade in timeout={600} key={thread.id}>
                <Card
                  elevation={0}
                  sx={{
                    borderRadius: 2,
                    transition: "all 0.3s ease",
                    "&:hover": {
                      transform: "translateY(-4px)",
                      boxShadow: (theme) =>
                        `0 8px 24px ${alpha(theme.palette.primary.main, 0.15)}`,
                    },
                  }}
                >
                  <CardContent
                    sx={{
                      cursor: "pointer",
                      p: 3,
                    }}
                    onClick={() => setSelectedThreadId(thread.id)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 2 }}
                      >
                        {thread.icon}
                        <Box>
                          <Typography
                            variant="h6"
                            sx={{ fontWeight: 600, mb: 1 }}
                          >
                            {thread.title}
                          </Typography>
                          <Typography color="text.secondary">
                            {thread.description}
                          </Typography>
                        </Box>
                      </Box>
                      <ChatBubbleOutlineIcon
                        sx={{
                          color: "primary.main",
                          opacity: 0.5,
                        }}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Fade>
            ))}
          </List>
        ) : (
          <Box>
            <Button
              variant="text"
              startIcon={<ArrowBackIosNewIcon />}
              onClick={handleBackToThreads}
              sx={{ mb: 4 }}
            >
              Back to Threads
            </Button>

            <Typography
              variant="h4"
              gutterBottom
              sx={{ fontWeight: 600, mb: 4 }}
            >
              {threads.find((thread) => thread.id === selectedThreadId)?.title}
            </Typography>

            <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
              {threads.find((thread) => thread.id === selectedThreadId)?.posts
                .length ? (
                threads
                  .find((thread) => thread.id === selectedThreadId)
                  ?.posts.map((post) => (
                    <Fade in timeout={500} key={post.id}>
                      <Card
                        elevation={0}
                        sx={{
                          borderRadius: 2,
                          bgcolor: "background.paper",
                          position: "relative",
                          ...(post.authorId === user?.uid && {
                            "&:hover": {
                              "& .delete-button": {
                                opacity: 1,
                              },
                            },
                          }),
                        }}
                      >
                        <CardContent sx={{ p: 3 }}>
                          <ListItem
                            alignItems="flex-start"
                            disablePadding
                            sx={{ mb: 2 }}
                          >
                            <ListItemAvatar>
                              <Avatar
                                sx={{
                                  bgcolor: "primary.main",
                                  color: "primary.contrastText",
                                }}
                              >
                                {post.author[0].toUpperCase()}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  <Typography
                                    component="span"
                                    variant="subtitle1"
                                    sx={{ fontWeight: 600 }}
                                  >
                                    {post.author}
                                  </Typography>
                                  {post.authorId === user?.uid && (
                                    <Chip
                                      label="You"
                                      size="small"
                                      sx={{
                                        height: 20,
                                        fontSize: "0.75rem",
                                        bgcolor: "primary.soft",
                                        color: "primary.main",
                                      }}
                                    />
                                  )}
                                </Box>
                              }
                              secondary={
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                    color: "text.secondary",
                                  }}
                                >
                                  <AccessTimeIcon sx={{ fontSize: 16 }} />
                                  <Typography variant="caption">
                                    {post.timestamp && post.timestamp.toDate
                                      ? post.timestamp.toDate().toLocaleString()
                                      : "Loading..."}
                                  </Typography>
                                </Box>
                              }
                            />
                            {user && post.authorId === user.uid && (
                              <Tooltip title="Delete your message">
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    handleDeleteClick(selectedThreadId!, post)
                                  }
                                  className="delete-button"
                                  sx={{
                                    color: "error.main",
                                    opacity: 0,
                                    transition: "opacity 0.2s ease",
                                    "&:hover": {
                                      bgcolor: "error.soft",
                                    },
                                  }}
                                >
                                  <DeleteOutlineIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          </ListItem>
                          <Divider sx={{ mb: 2 }} />
                          <Typography>{post.content}</Typography>
                        </CardContent>
                      </Card>
                    </Fade>
                  ))
              ) : (
                <Typography
                  color="text.secondary"
                  sx={{ textAlign: "center", py: 4 }}
                >
                  No posts yet. Start the conversation!
                </Typography>
              )}
            </Box>

            {user && (
              <Box sx={{ mt: 4 }}>
                <TextField
                  label="Write your thoughts..."
                  multiline
                  fullWidth
                  value={newPostContent}
                  onChange={(e) => setNewPostContent(e.target.value)}
                  variant="outlined"
                  rows={4}
                  sx={{
                    mb: 2,
                    bgcolor: "background.paper",
                    borderRadius: 2,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 2,
                    },
                  }}
                />
                <Button
                  variant="contained"
                  onClick={handleAddPost}
                  disabled={!newPostContent}
                  startIcon={<SendRoundedIcon />}
                  sx={{
                    borderRadius: 2,
                    textTransform: "none",
                    px: 4,
                    py: 1.5,
                  }}
                >
                  Post Reply
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Container>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        PaperProps={{
          sx: {
            borderRadius: 2,
            width: "100%",
            maxWidth: 400,
          },
        }}
      >
        <DialogTitle sx={{ pb: 1 }}>Delete Message</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this message? This action cannot be
            undone.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 2, pt: 0 }}>
          <Button
            onClick={() => setDeleteDialogOpen(false)}
            sx={{ color: "text.secondary" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            color="error"
            sx={{ borderRadius: 2, textTransform: "none" }}
          >
            Delete Message
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Forum;
