import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  TextField,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import { Edit, Search, Delete, Block } from "@mui/icons-material";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../services/firebase";

interface UserData {
  id: string;
  availableTime: string;
  bio: string;
  birthdate: string;
  email: string;
  firstName: string;
  gender: string;
  isVet: boolean;
  lastName: string;
  location: string;
  nickname: string;
  passportNumber: string;
  phoneNumber: string;
  role: string;
  serviceFee: string;
  socialMedia: string;
  specialization: string;
  username: string;
  vetBio: string;
  vetLocation: string;
  blocked?: boolean;
}

const UserManagement: React.FC = () => {
  const [users, setUsers] = useState<UserData[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<UserData[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editUser, setEditUser] = useState<UserData | null>(null);

  useEffect(() => {
    const fetchUsers = async () => {
      const querySnapshot = await getDocs(collection(db, "users"));
      const usersData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as UserData[];
      setUsers(usersData);
      setFilteredUsers(usersData);
    };

    fetchUsers();
  }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    setFilteredUsers(
      users.filter((user) =>
        Object.values(user).some((val) =>
          val.toString().toLowerCase().includes(query)
        )
      )
    );
  };

  const handleEditClick = (user: UserData) => {
    setEditUser(user);
    setOpenEditDialog(true);
  };

  const handleEditChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (editUser) {
      const { name, value } = event.target;
      setEditUser({
        ...editUser,
        [name]: value,
      });
    }
  };

  const handleSaveEdit = async () => {
    if (editUser) {
      const userRef = doc(db, "users", editUser.id);
      await updateDoc(userRef, { ...editUser });
      setUsers(
        users.map((user) => (user.id === editUser.id ? editUser : user))
      );
      setFilteredUsers(
        filteredUsers.map((user) => (user.id === editUser.id ? editUser : user))
      );
      setOpenEditDialog(false);
      setEditUser(null);
    }
  };

  const handleRemoveUser = async (userId: string) => {
    if (window.confirm("Are you sure you want to remove this user?")) {
      await deleteDoc(doc(db, "users", userId));
      setUsers(users.filter((user) => user.id !== userId));
      setFilteredUsers(filteredUsers.filter((user) => user.id !== userId));
    }
  };

  const handleBlockToggle = async (user: UserData) => {
    const userRef = doc(db, "users", user.id);
    const updatedUser = { ...user, blocked: !user.blocked };
    await updateDoc(userRef, { blocked: updatedUser.blocked });
    setUsers(users.map((u) => (u.id === user.id ? updatedUser : u)));
    setFilteredUsers(
      filteredUsers.map((u) => (u.id === user.id ? updatedUser : u))
    );
  };

  return (
    <Container>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        my={2}
      >
        <Typography variant="h5">User Management</Typography>
        <TextField
          variant="outlined"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: <Search />,
          }}
        />
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Vet Status</TableCell>
              <TableCell>Blocked</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.firstName}</TableCell>
                <TableCell>{user.lastName}</TableCell>
                <TableCell>{user.role}</TableCell>
                <TableCell>{user.isVet ? "Yes" : "No"}</TableCell>
                <TableCell>{user.blocked ? "Yes" : "No"}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEditClick(user)}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleRemoveUser(user.id)}>
                    <Delete />
                  </IconButton>
                  <IconButton onClick={() => handleBlockToggle(user)}>
                    <Block color={user.blocked ? "error" : "inherit"} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          {editUser &&
            Object.keys(editUser).map((key) => (
              <TextField
                key={key}
                margin="dense"
                label={key}
                name={key}
                value={(editUser as any)[key]}
                onChange={handleEditChange}
                fullWidth
                disabled={key === "id" || key === "blocked"}
              />
            ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSaveEdit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default UserManagement;
