import React, { useState, useEffect } from "react";
import { db, auth } from "../services/firebase";
import {
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  updateDoc,
  doc,
} from "firebase/firestore";
import {
  Snackbar,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  alpha,
  Card,
  CardContent,
  CardActions,
  Typography,
  Switch,
  Container,
  Grid,
  Paper,
  Chip,
  IconButton,
  Fade,
  useTheme,
  Alert,
  Tooltip,
  FormControlLabel,
} from "@mui/material";
import {
  Add as AddIcon,
  Search as SearchIcon,
  Category as CategoryIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Store as StoreIcon,
  Close as CloseIcon,
  LocalOffer as TagIcon,
  Description as DescriptionIcon,
  ShoppingCart as CartIcon,
  ContactPhone as ContactIcon,
} from "@mui/icons-material";
import PlatformMenu from "../components/PlatformMenu";
import OurMarket from "../components/OurMarket";
import LocationIcon from "@mui/icons-material/LocationOn";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";

interface Ad {
  id: string;
  userId: string;
  title: string;
  description: string;
  category: string;
  contact: string;
  location: string;
}

interface NewAd {
  title: string;
  description: string;
  category: string;
  contact: string;
  location: string;
}

const Market: React.FC = () => {
  const [showOurMarket, setShowOurMarket] = useState(true);
  const [ads, setAds] = useState<Ad[]>([]);
  const [filteredAds, setFilteredAds] = useState<Ad[]>([]);
  const [newAd, setNewAd] = useState<NewAd>({
    title: "",
    description: "",
    category: "",
    contact: "",
    location: "",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentAdId, setCurrentAdId] = useState<string | null>(null);
  const [locationFilter, setLocationFilter] = useState("");

  const handleFormChange = (
    e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>
  ) => {
    const { name, value } = e.target;
    if (name) {
      setNewAd((prev) => ({
        ...prev,
        [name]: value as string,
      }));
    }
  };

  const handleMarketToggle = () => {
    setShowOurMarket(!showOurMarket);
  };

  const handleCategoryChange = (event: any) => {
    setCategoryFilter(event.target.value);
  };

  const handleFormCategoryChange = (event: any) => {
    setNewAd((prev) => ({
      ...prev,
      category: event.target.value,
    }));
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleFormSubmit = async () => {
    if (
      !newAd.title ||
      !newAd.description ||
      !newAd.category ||
      !newAd.contact ||
      !newAd.location
    ) {
      setSnackbarMessage("Please fill all fields.");
      setSnackbarOpen(true);
      return;
    }

    const user = auth.currentUser;
    if (!user) {
      setSnackbarMessage("Please log in to post an ad.");
      setSnackbarOpen(true);
      return;
    }

    try {
      if (isEditing && currentAdId) {
        const adRef = doc(db, "ads", currentAdId);
        await updateDoc(adRef, {
          title: newAd.title,
          description: newAd.description,
          category: newAd.category,
          contact: newAd.contact,
          location: newAd.location,
        });

        setAds((prevAds) =>
          prevAds.map((ad) =>
            ad.id === currentAdId ? { ...ad, ...newAd } : ad
          )
        );
        setSnackbarMessage("Ad updated successfully!");
      } else {
        const docRef = await addDoc(collection(db, "ads"), {
          ...newAd,
          userId: user.uid,
        });

        setAds((prevAds) => [
          ...prevAds,
          { id: docRef.id, userId: user.uid, ...newAd },
        ]);
        setSnackbarMessage("Ad added successfully!");
      }

      setSnackbarOpen(true);
      setNewAd({
        title: "",
        description: "",
        category: "",
        contact: "",
        location: "",
      });
      setShowForm(false);
      setIsEditing(false);
      setCurrentAdId(null);
    } catch (error) {
      setSnackbarMessage("Error adding/updating ad. Please try again.");
      setSnackbarOpen(true);
    }
  };

  const handleEditClick = (ad: Ad) => {
    setNewAd({
      title: ad.title,
      description: ad.description,
      category: ad.category,
      contact: ad.contact,
      location: ad.location,
    });
    setIsEditing(true);
    setShowForm(true);
    setCurrentAdId(ad.id);
  };

  const filterAds = React.useCallback(() => {
    let filtered = ads;

    if (searchQuery) {
      filtered = filtered.filter(
        (ad) =>
          ad.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          ad.description.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (categoryFilter) {
      filtered = filtered.filter((ad) => ad.category === categoryFilter);
    }

    if (locationFilter) {
      filtered = filtered.filter((ad) => ad.location === locationFilter);
    }

    setFilteredAds(filtered);
  }, [ads, searchQuery, categoryFilter, locationFilter]);

  const fetchAds = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "ads"));
      const adsList: Ad[] = [];
      querySnapshot.forEach((doc) => {
        adsList.push({ id: doc.id, ...doc.data() } as Ad);
      });
      setAds(adsList);
    } catch (error) {
      console.error("Error fetching ads:", error);
    }
  };

  useEffect(() => {
    fetchAds();
  }, []);

  useEffect(() => {
    filterAds();
  }, [searchQuery, categoryFilter, ads, filterAds]);

  const handleAdRemove = async (adId: string) => {
    try {
      await deleteDoc(doc(db, "ads", adId));
      setAds(ads.filter((ad) => ad.id !== adId));
      setSnackbarMessage("Ad removed successfully!");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("Error removing ad. Please try again.");
      setSnackbarOpen(true);
    }
  };

  const handleLocationChange = (event: any) => {
    setLocationFilter(event.target.value);
  };

  const theme = useTheme();

  return (
    <Box sx={{ bgcolor: "#f5f5f7", minHeight: "100vh" }}>
      <PlatformMenu />

      <Container maxWidth="lg" sx={{ pt: 8, pb: 12 }}>
        {/* Hero Section */}

        <Fade in timeout={800}>
          <Box
            sx={{
              position: "relative",
              mb: 8,
              borderRadius: 4,
              overflow: "hidden",
              boxShadow: "0 20px 40px rgba(0,0,0,0.1)",
            }}
          >
            <Box
              sx={{
                background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 100%)`,
                p: { xs: 4, md: 8 },
                color: "white",
                position: "relative",
                zIndex: 1,
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: -20,
                  right: -20,
                  opacity: 0.1,
                  transform: "rotate(-15deg)",
                }}
              >
                <LocalGroceryStoreIcon sx={{ fontSize: 180 }} />
              </Box>
              <Typography variant="h3" fontWeight="bold" gutterBottom>
                Marketplace
              </Typography>
              <Typography variant="h6" sx={{ mb: 4, opacity: 0.9 }}>
                Discover amazing deals and connect with sellers
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <StoreIcon sx={{ fontSize: 30 }} />
                <FormControlLabel
                  control={
                    <Switch
                      checked={showOurMarket}
                      onChange={handleMarketToggle}
                      sx={{
                        "& .MuiSwitch-switchBase.Mui-checked": {
                          color: "#fff",
                          "&:hover": { bgcolor: "rgba(255,255,255,0.1)" },
                        },
                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                          {
                            bgcolor: "rgba(255,255,255,0.3)",
                          },
                      }}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        fontWeight: 600,
                        bgcolor: "rgba(255,255,255,0.2)",
                        px: 2,
                        py: 1,
                        borderRadius: 2,
                        backdropFilter: "blur(10px)",
                      }}
                    >
                      {showOurMarket ? "Our Market" : "User Market"}
                    </Typography>
                  }
                />
              </Box>
            </Box>
          </Box>
        </Fade>

        {!showOurMarket && (
          <Box>
            {/* Search and Filter Section */}
            <Paper
              elevation={0}
              sx={{
                p: 3,
                mb: 4,
                borderRadius: 3,
                bgcolor: "white",
                border: "1px solid",
                borderColor: "divider",
              }}
            >
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={6} md={3}>
                  <TextField
                    fullWidth
                    placeholder="Search ads..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <SearchIcon sx={{ mr: 1, color: "primary.main" }} />
                      ),
                      sx: {
                        borderRadius: 2,
                        "& fieldset": { borderColor: "transparent" },
                        bgcolor: theme.palette.grey[50],
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={locationFilter}
                      onChange={handleLocationChange}
                      displayEmpty
                      sx={{
                        borderRadius: 2,
                        bgcolor: theme.palette.grey[50],
                        "& fieldset": { borderColor: "transparent" },
                      }}
                      startAdornment={
                        <LocationIcon
                          sx={{ ml: 1, mr: 1, color: "primary.main" }}
                        />
                      }
                    >
                      <MenuItem value="">All Locations</MenuItem>
                      <MenuItem value="Artibonite">Artibonite</MenuItem>
                      <MenuItem value="Centre">Centre</MenuItem>
                      <MenuItem value="Grand'Anse">Grand'Anse</MenuItem>
                      <MenuItem value="Nippes">Nippes</MenuItem>
                      <MenuItem value="Nord">Nord</MenuItem>
                      <MenuItem value="Nord-Est">Nord-Est</MenuItem>
                      <MenuItem value="Nord-Ouest">Nord-Ouest</MenuItem>
                      <MenuItem value="Ouest">Ouest</MenuItem>
                      <MenuItem value="Sud">Sud</MenuItem>
                      <MenuItem value="Sud-Est">Sud-Est</MenuItem>
                      <MenuItem value="Out of Haiti">Out of Haiti</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <Select
                      value={categoryFilter}
                      onChange={handleCategoryChange}
                      displayEmpty
                      sx={{
                        borderRadius: 2,
                        bgcolor: theme.palette.grey[50],
                        "& fieldset": { borderColor: "transparent" },
                      }}
                      startAdornment={
                        <CategoryIcon
                          sx={{ ml: 1, mr: 1, color: "primary.main" }}
                        />
                      }
                    >
                      <MenuItem value="">All Categories</MenuItem>
                      <MenuItem value="Food">
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          <TagIcon fontSize="small" />
                          Food
                        </Box>
                      </MenuItem>
                      <MenuItem value="Toys">
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          <TagIcon fontSize="small" />
                          Toys
                        </Box>
                      </MenuItem>
                      <MenuItem value="Medical">
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          <TagIcon fontSize="small" />
                          Medical
                        </Box>
                      </MenuItem>
                      <MenuItem value="Other">
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          <TagIcon fontSize="small" />
                          Other
                        </Box>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Tooltip title="Post a new ad">
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={() => setShowForm(true)}
                      sx={{
                        height: "56px",
                        borderRadius: 2,
                        boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
                        "&:hover": {
                          transform: "translateY(-2px)",
                          boxShadow: "0 12px 20px rgba(0,0,0,0.15)",
                        },
                      }}
                    >
                      <AddIcon sx={{ mr: 1 }} />
                      Post Ad
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </Paper>

            {/* Ad Form */}
            <Fade in={showForm}>
              <Paper
                elevation={0}
                sx={{
                  p: 4,
                  mb: 4,
                  borderRadius: 3,
                  bgcolor: "white",
                  border: "1px solid",
                  borderColor: "divider",
                  position: "relative",
                }}
              >
                <IconButton
                  onClick={() => setShowForm(false)}
                  sx={{
                    position: "absolute",
                    right: 16,
                    top: 16,
                    bgcolor: theme.palette.grey[100],
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h5" fontWeight="600" gutterBottom>
                  {isEditing ? "Edit Ad" : "Create New Ad"}
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Title"
                      name="title"
                      value={newAd.title}
                      onChange={handleFormChange}
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <DescriptionIcon
                            sx={{ mr: 1, color: "primary.main" }}
                          />
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel>Category</InputLabel>
                      <Select
                        name="category"
                        value={newAd.category}
                        onChange={handleFormCategoryChange}
                        label="Category"
                        startAdornment={
                          <CategoryIcon sx={{ mr: 1, color: "primary.main" }} />
                        }
                      >
                        <MenuItem value="Food">Food</MenuItem>
                        <MenuItem value="Toys">Toys</MenuItem>
                        <MenuItem value="Medical">Medical</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Description"
                      name="description"
                      value={newAd.description}
                      onChange={handleFormChange}
                      fullWidth
                      multiline
                      rows={3}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Contact"
                      name="contact"
                      value={newAd.contact}
                      onChange={handleFormChange}
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <ContactIcon sx={{ mr: 1, color: "primary.main" }} />
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel>Location</InputLabel>
                      <Select
                        name="location"
                        value={newAd.location}
                        onChange={handleFormCategoryChange}
                        label="Location"
                        startAdornment={
                          <LocationIcon sx={{ mr: 1, color: "primary.main" }} />
                        }
                      >
                        <MenuItem value="Artibonite">Artibonite</MenuItem>
                        <MenuItem value="Centre">Centre</MenuItem>
                        <MenuItem value="Grand'Anse">Grand'Anse</MenuItem>
                        <MenuItem value="Nippes">Nippes</MenuItem>
                        <MenuItem value="Nord">Nord</MenuItem>
                        <MenuItem value="Nord-Est">Nord-Est</MenuItem>
                        <MenuItem value="Nord-Ouest">Nord-Ouest</MenuItem>
                        <MenuItem value="Ouest">Ouest</MenuItem>
                        <MenuItem value="Sud">Sud</MenuItem>
                        <MenuItem value="Sud-Est">Sud-Est</MenuItem>
                        <MenuItem value="Out of Haiti">Out of Haiti</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        variant="outlined"
                        onClick={() => setShowForm(false)}
                        startIcon={<CloseIcon />}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleFormSubmit}
                        startIcon={isEditing ? <EditIcon /> : <AddIcon />}
                        sx={{
                          boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                          "&:hover": {
                            transform: "translateY(-2px)",
                            boxShadow: "0 6px 16px rgba(0,0,0,0.15)",
                          },
                        }}
                      >
                        {isEditing ? "Update Ad" : "Post Ad"}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Fade>

            {/* Ads Grid */}
            <Grid container spacing={3}>
              {filteredAds.map((ad) => (
                <Grid item xs={12} md={6} lg={4} key={ad.id}>
                  <Card
                    elevation={0}
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: 3,
                      border: "1px solid",
                      borderColor: "divider",
                      bgcolor: "white",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        transform: "translateY(-4px)",
                        boxShadow: "0 12px 24px rgba(0,0,0,0.1)",
                      },
                    }}
                  >
                    <CardContent sx={{ flexGrow: 1, p: 3 }}>
                      <Box
                        sx={{
                          mb: 2,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography variant="h6" fontWeight="600">
                          {ad.title}
                        </Typography>
                        <Chip
                          icon={<TagIcon />}
                          label={ad.category}
                          size="small"
                          color="primary"
                          variant="outlined"
                          sx={{ borderRadius: 2 }}
                        />
                      </Box>
                      <Typography color="text.secondary" sx={{ mb: 2 }}>
                        {ad.description}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          color: "text.secondary",
                        }}
                      >
                        <ContactIcon fontSize="small" />
                        <Typography variant="body2">{ad.contact}</Typography>
                      </Box>
                    </CardContent>
                    {auth.currentUser?.uid === ad.userId && (
                      <CardActions
                        sx={{
                          justifyContent: "flex-end",
                          p: 2,
                          borderTop: "1px solid",
                          borderColor: "divider",
                        }}
                      >
                        <Tooltip title="Edit ad">
                          <IconButton
                            size="small"
                            onClick={() => handleEditClick(ad)}
                            sx={{
                              color: "primary.main",
                              "&:hover": { bgcolor: "primary.light" },
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete ad">
                          <IconButton
                            size="small"
                            onClick={() => handleAdRemove(ad.id)}
                            sx={{
                              color: "error.main",
                              "&:hover": { bgcolor: "error.light" },
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </CardActions>
                    )}
                  </Card>
                </Grid>
              ))}
            </Grid>

            {/* Empty State */}
            {filteredAds.length === 0 && (
              <Paper
                elevation={0}
                sx={{
                  p: 6,
                  mt: 4,
                  textAlign: "center",
                  borderRadius: 3,
                  bgcolor: "white",
                  border: "1px dashed",
                  borderColor: "divider",
                }}
              >
                <CartIcon
                  sx={{ fontSize: 48, color: "text.secondary", mb: 2 }}
                />
                <Typography
                  variant="h6"
                  fontWeight="600"
                  color="text.secondary"
                >
                  No ads found
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mt: 1, mb: 3 }}
                >
                  {searchQuery || categoryFilter
                    ? "Try adjusting your search or filter criteria"
                    : "Be the first to post an ad and start selling!"}
                </Typography>
                {!showForm && (
                  <Button
                    variant="contained"
                    onClick={() => setShowForm(true)}
                    startIcon={<AddIcon />}
                    sx={{
                      borderRadius: 2,
                      px: 4,
                      py: 1.5,
                      boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
                      "&:hover": {
                        transform: "translateY(-2px)",
                        boxShadow: "0 12px 20px rgba(0,0,0,0.15)",
                      },
                    }}
                  >
                    Post New Ad
                  </Button>
                )}
              </Paper>
            )}
          </Box>
        )}

        {/* Our Market Section */}
        {showOurMarket && (
          <Box>
            <Paper
              elevation={0}
              sx={{
                borderRadius: 3,
                bgcolor: "white",
                border: "1px solid",
                borderColor: "divider",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  p: 4,
                  borderBottom: "1px solid",
                  borderColor: "divider",
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <StoreIcon color="primary" sx={{ fontSize: 32 }} />
                <Typography variant="h5" fontWeight="600">
                  Our Market
                </Typography>
              </Box>
              <Box sx={{ p: 4 }}>
                <OurMarket />
              </Box>
            </Paper>
          </Box>
        )}

        {/* Snackbar Notifications */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={
              snackbarMessage.includes("successfully") ? "success" : "error"
            }
            variant="filled"
            sx={{
              borderRadius: 2,
              boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
              "& .MuiAlert-icon": {
                fontSize: 24,
              },
            }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>

      {/* Footer */}
      <Box
        component="footer"
        sx={{
          py: 4,
          bgcolor: "background.paper",
          borderTop: "1px solid",
          borderColor: "divider",
        }}
      >
        <Container maxWidth="lg">
          <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            sx={{ opacity: 0.8 }}
          >
            © {new Date().getFullYear()} Marketplace. All rights reserved.
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default Market;
