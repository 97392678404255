import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  IconButton,
  Box,
  Typography,
  Alert,
  Stack,
} from "@mui/material";
import { Delete, Edit, Vaccines, Search } from "@mui/icons-material";
import {
  collection,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
  arrayUnion,
} from "firebase/firestore";
import { db } from "../../services/firebase";

interface VaccinationRecord {
  date: string;
  idNumber: string;
  nextDueDate: string;
  place: string;
  vaccineName: string;
  verified: boolean;
}

interface Animal {
  id: string;
  age: string;
  breed: string;
  dietaryInfo: string;
  gender: string;
  healthConditions: string;
  healthNotes: string;
  medications: string;
  nickname: string;
  profilePhoto: string | null;
  purposeTags: string[];
  species: string;
  uniqueID: string;
  userId: string;
  vaccinationRecords: VaccinationRecord[];
}

const AnimalManagement: React.FC = () => {
  const [animals, setAnimals] = useState<Animal[]>([]);
  const [filteredAnimals, setFilteredAnimals] = useState<Animal[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedAnimal, setSelectedAnimal] = useState<Animal | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [vaccineDialogOpen, setVaccineDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [newVaccine, setNewVaccine] = useState<Partial<VaccinationRecord>>({
    date: "",
    idNumber: "",
    nextDueDate: "",
    place: "",
    vaccineName: "",
    verified: false,
  });

  useEffect(() => {
    const fetchAnimals = async () => {
      try {
        const animalsRef = collection(db, "animals");
        const snapshot = await getDocs(animalsRef);
        const animalsData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        })) as Animal[];
        setAnimals(animalsData);
        setFilteredAnimals(animalsData);
      } catch (err) {
        setError("Error fetching animals data");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchAnimals();
  }, []);

  useEffect(() => {
    const filtered = animals.filter((animal) => {
      const searchStr = searchQuery.toLowerCase();
      return (
        animal.nickname.toLowerCase().includes(searchStr) ||
        animal.breed.toLowerCase().includes(searchStr) ||
        animal.species.toLowerCase().includes(searchStr) ||
        animal.uniqueID.toLowerCase().includes(searchStr)
      );
    });
    setFilteredAnimals(filtered);
  }, [searchQuery, animals]);

  const handleEditAnimal = async (updatedAnimal: Animal) => {
    try {
      const animalRef = doc(db, "animals", updatedAnimal.id);

      const { id, ...updateData } = updatedAnimal;

      await updateDoc(animalRef, updateData);

      setAnimals(
        animals.map((animal) =>
          animal.id === updatedAnimal.id ? updatedAnimal : animal
        )
      );
      setEditDialogOpen(false);
      setSelectedAnimal(null);
    } catch (err) {
      setError("Error updating animal details");
      console.error(err);
    }
  };

  const handleDeleteAnimal = async (id: string) => {
    try {
      await deleteDoc(doc(db, "animals", id));
      setAnimals(animals.filter((animal) => animal.id !== id));
      setDeleteDialogOpen(false);
      setSelectedAnimal(null);
    } catch (err) {
      setError("Error deleting animal");
      console.error(err);
    }
  };

  const handleAddVaccination = async (animalId: string) => {
    try {
      const animalRef = doc(db, "animals", animalId);
      await updateDoc(animalRef, {
        vaccinationRecords: arrayUnion(newVaccine),
      });

      const updatedAnimal = animals.find((a) => a.id === animalId);
      if (updatedAnimal) {
        const newRecords = [
          ...updatedAnimal.vaccinationRecords,
          newVaccine as VaccinationRecord,
        ];
        setAnimals(
          animals.map((animal) =>
            animal.id === animalId
              ? { ...animal, vaccinationRecords: newRecords }
              : animal
          )
        );
      }

      setVaccineDialogOpen(false);
      setNewVaccine({
        date: "",
        idNumber: "",
        nextDueDate: "",
        place: "",
        vaccineName: "",
        verified: false,
      });
    } catch (err) {
      setError("Error adding vaccination record");
      console.error(err);
    }
  };

  const handleVerifyVaccination = async (
    animalId: string,
    vaccineIndex: number
  ) => {
    try {
      const animal = animals.find((a) => a.id === animalId);
      if (!animal) return;

      const updatedRecords = [...animal.vaccinationRecords];
      updatedRecords[vaccineIndex].verified =
        !updatedRecords[vaccineIndex].verified;

      const animalRef = doc(db, "animals", animalId);
      await updateDoc(animalRef, {
        vaccinationRecords: updatedRecords,
      });

      setAnimals(
        animals.map((a) =>
          a.id === animalId ? { ...a, vaccinationRecords: updatedRecords } : a
        )
      );
    } catch (err) {
      setError("Error verifying vaccination");
      console.error(err);
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="400px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%", p: 2 }}>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <TextField
          fullWidth
          label="Search Animals"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: <Search />,
          }}
        />
      </Stack>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nickname</TableCell>
              <TableCell>Species</TableCell>
              <TableCell>Breed</TableCell>
              <TableCell>Age</TableCell>
              <TableCell>Health Notes</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAnimals.map((animal) => (
              <TableRow key={animal.id}>
                <TableCell>{animal.nickname}</TableCell>
                <TableCell>{animal.species}</TableCell>
                <TableCell>{animal.breed}</TableCell>
                <TableCell>{animal.age}</TableCell>
                <TableCell>{animal.healthNotes}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      setSelectedAnimal(animal);
                      setEditDialogOpen(true);
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setSelectedAnimal(animal);
                      setVaccineDialogOpen(true);
                    }}
                  >
                    <Vaccines />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setSelectedAnimal(animal);
                      setDeleteDialogOpen(true);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Edit Animal Dialog */}
      <Dialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Edit Animal Details</DialogTitle>
        <DialogContent>
          {selectedAnimal && (
            <Stack spacing={2} sx={{ mt: 2 }}>
              <TextField
                label="Nickname"
                fullWidth
                value={selectedAnimal.nickname}
                onChange={(e) =>
                  setSelectedAnimal({
                    ...selectedAnimal,
                    nickname: e.target.value,
                  })
                }
              />
              <TextField
                label="Breed"
                fullWidth
                value={selectedAnimal.breed}
                onChange={(e) =>
                  setSelectedAnimal({
                    ...selectedAnimal,
                    breed: e.target.value,
                  })
                }
              />
              <TextField
                label="Species"
                fullWidth
                value={selectedAnimal.species}
                onChange={(e) =>
                  setSelectedAnimal({
                    ...selectedAnimal,
                    species: e.target.value,
                  })
                }
              />
              <TextField
                label="Health Notes"
                fullWidth
                multiline
                rows={4}
                value={selectedAnimal.healthNotes}
                onChange={(e) =>
                  setSelectedAnimal({
                    ...selectedAnimal,
                    healthNotes: e.target.value,
                  })
                }
              />
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={() => selectedAnimal && handleEditAnimal(selectedAnimal)}
            variant="contained"
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Vaccination Management Dialog */}
      <Dialog
        open={vaccineDialogOpen}
        onClose={() => setVaccineDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Manage Vaccinations</DialogTitle>
        <DialogContent>
          {selectedAnimal && (
            <>
              <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
                Current Vaccination Records
              </Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Vaccine Name</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Next Due Date</TableCell>
                      <TableCell>Place</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedAnimal.vaccinationRecords.map((record, index) => (
                      <TableRow key={index}>
                        <TableCell>{record.vaccineName}</TableCell>
                        <TableCell>{record.date}</TableCell>
                        <TableCell>{record.nextDueDate}</TableCell>
                        <TableCell>{record.place}</TableCell>
                        <TableCell>
                          <Button
                            variant={record.verified ? "contained" : "outlined"}
                            onClick={() =>
                              handleVerifyVaccination(selectedAnimal.id, index)
                            }
                          >
                            {record.verified ? "Verified" : "Verify"}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                Add New Vaccination Record
              </Typography>
              <Stack spacing={2}>
                <TextField
                  label="Vaccine Name"
                  fullWidth
                  value={newVaccine.vaccineName}
                  onChange={(e) =>
                    setNewVaccine({
                      ...newVaccine,
                      vaccineName: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Date"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={newVaccine.date}
                  onChange={(e) =>
                    setNewVaccine({
                      ...newVaccine,
                      date: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Next Due Date"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={newVaccine.nextDueDate}
                  onChange={(e) =>
                    setNewVaccine({
                      ...newVaccine,
                      nextDueDate: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Place"
                  fullWidth
                  value={newVaccine.place}
                  onChange={(e) =>
                    setNewVaccine({
                      ...newVaccine,
                      place: e.target.value,
                    })
                  }
                />
                <TextField
                  label="ID Number"
                  fullWidth
                  value={newVaccine.idNumber}
                  onChange={(e) =>
                    setNewVaccine({
                      ...newVaccine,
                      idNumber: e.target.value,
                    })
                  }
                />
              </Stack>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setVaccineDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={() =>
              selectedAnimal && handleAddVaccination(selectedAnimal.id)
            }
            variant="contained"
          >
            Add Vaccination Record
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to remove {selectedAnimal?.nickname} from the
            registry? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={() =>
              selectedAnimal && handleDeleteAnimal(selectedAnimal.id)
            }
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AnimalManagement;
