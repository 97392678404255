import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  IconButton,
  Card,
  CardContent,
  CardActions,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Add, Edit, Delete, Search } from "@mui/icons-material";
import {
  doc,
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../services/firebase";

interface AdminAd {
  firebaseDocId?: string;
  id: string;
  category: string;
  description: string;
  imageUrl: string;
  location: string;
  originalPrice: string;
  price: string;
  title: string;
}

interface UserAd {
  firebaseDocId?: string;
  id: string;
  userId: string;
  category: string;
  contact: string;
  description: string;
  title: string;
}

const AdManagement: React.FC = () => {
  const [adminAds, setAdminAds] = useState<AdminAd[]>([]);
  const [userAds, setUserAds] = useState<UserAd[]>([]);
  const [filteredAds, setFilteredAds] = useState<AdminAd[] | UserAd[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedAd, setSelectedAd] = useState<Partial<AdminAd & UserAd>>({});
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [marketType, setMarketType] = useState("our-market");

  const isUserMarket = marketType === "user-market";

  useEffect(() => {
    const fetchAds = async () => {
      const adminCollection = collection(db, "our-market-ads");
      const userCollection = collection(db, "ads");

      const adminUnsubscribe = onSnapshot(adminCollection, (snapshot) => {
        const adminData = snapshot.docs.map((doc) => ({
          firebaseDocId: doc.id,
          ...doc.data(),
        })) as AdminAd[];
        setAdminAds(adminData);
        if (!isUserMarket) setFilteredAds(adminData);
      });

      const userUnsubscribe = onSnapshot(userCollection, (snapshot) => {
        const userData = snapshot.docs.map((doc) => ({
          firebaseDocId: doc.id,
          ...doc.data(),
        })) as UserAd[];
        setUserAds(userData);
        if (isUserMarket) setFilteredAds(userData);
      });

      return () => {
        adminUnsubscribe();
        userUnsubscribe();
      };
    };

    fetchAds();
  }, [marketType, isUserMarket]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    if (isUserMarket) {
      const filtered = userAds.filter((ad) =>
        ad.title.toLowerCase().includes(query)
      );
      setFilteredAds(filtered);
    } else {
      const filtered = adminAds.filter((ad) =>
        ad.title.toLowerCase().includes(query)
      );
      setFilteredAds(filtered);
    }
  };

  const handleToggleChange = (
    event: React.MouseEvent<HTMLElement>,
    newMarketType: string
  ) => {
    if (newMarketType !== null) {
      setMarketType(newMarketType);
      setSearchQuery("");
      setFilteredAds(newMarketType === "user-market" ? userAds : adminAds);
    }
  };

  const handleAddAdminAd = async () => {
    const newAd: AdminAd = {
      category: selectedAd.category || "",
      description: selectedAd.description || "",
      imageUrl: selectedAd.imageUrl || "",
      location: selectedAd.location || "",
      originalPrice: selectedAd.originalPrice || "",
      price: selectedAd.price || "",
      title: selectedAd.title || "",
      id: "",
    };
    await addDoc(collection(db, "our-market-ads"), newAd);
    setIsAdding(false);
  };

  const handleEditAd = async () => {
    if (!selectedAd.firebaseDocId) {
      console.error("No document ID specified for update.");
      return;
    }
    const adRef = doc(
      db,
      isUserMarket ? "ads" : "our-market-ads",
      selectedAd.firebaseDocId
    );
    const updatedAd = { ...selectedAd };
    await updateDoc(adRef, updatedAd);
    setIsEditing(false);
  };

  const handleDeleteAd = async (firebaseDocId: string) => {
    if (!firebaseDocId) {
      console.error("No document ID specified for deletion.");
      return;
    }
    const adRef = doc(
      db,
      isUserMarket ? "ads" : "our-market-ads",
      firebaseDocId
    );
    await deleteDoc(adRef);
  };

  const openAddDialog = () => {
    setSelectedAd({});
    setIsAdding(true);
  };

  const openEditDialog = (ad: AdminAd | UserAd) => {
    setSelectedAd({ ...ad, firebaseDocId: ad.firebaseDocId });
    setIsEditing(true);
  };

  const closeDialog = () => {
    setIsEditing(false);
    setIsAdding(false);
    setSelectedAd({});
  };

  const renderAds = () => (
    <Grid container spacing={2}>
      {filteredAds.map((ad) => (
        <Grid item xs={12} sm={6} md={4} key={ad.firebaseDocId}>
          <Card>
            <CardContent>
              <Typography variant="h6">{ad.title}</Typography>
              <Typography variant="subtitle1">
                Category: {ad.category}
              </Typography>
              <Typography variant="body2">{ad.description}</Typography>
              {isUserMarket ? (
                <Typography variant="body2">
                  Contact: {(ad as UserAd).contact}
                </Typography>
              ) : (
                <>
                  <Typography variant="body2">
                    Location: {(ad as AdminAd).location}
                  </Typography>
                  <Typography variant="body2">
                    Original Price: ${(ad as AdminAd).originalPrice}
                  </Typography>
                  <Typography variant="body2">
                    Price: ${(ad as AdminAd).price}
                  </Typography>
                </>
              )}
            </CardContent>
            <CardActions>
              <IconButton onClick={() => openEditDialog(ad)}>
                <Edit />
              </IconButton>
              <IconButton onClick={() => handleDeleteAd(ad.firebaseDocId!)}>
                <Delete />
              </IconButton>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Box p={3}>
      <Typography variant="h4">Ad Management</Typography>

      <Box
        mt={2}
        mb={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <ToggleButtonGroup
          value={marketType}
          exclusive
          onChange={handleToggleChange}
          aria-label="market type"
        >
          <ToggleButton value="our-market" aria-label="Our Market Ads">
            Our Market Ads
          </ToggleButton>
          <ToggleButton value="user-market" aria-label="User Market Ads">
            User Market Ads
          </ToggleButton>
        </ToggleButtonGroup>

        {marketType === "our-market" && (
          <Button
            variant="contained"
            color="primary"
            onClick={openAddDialog}
            startIcon={<Add />}
          >
            Add New Admin Ad
          </Button>
        )}
      </Box>

      <Box mb={3}>
        <TextField
          label={`Search ${isUserMarket ? "User Market" : "Our Market"} Ads`}
          fullWidth
          margin="dense"
          InputProps={{
            startAdornment: <Search />,
          }}
          value={searchQuery}
          onChange={handleSearch}
        />
      </Box>

      {renderAds()}

      <Dialog open={isAdding || isEditing} onClose={closeDialog}>
        <DialogTitle>{isAdding ? "Add New Admin Ad" : "Edit Ad"}</DialogTitle>
        <DialogContent>
          <TextField
            label="Title"
            fullWidth
            margin="dense"
            value={selectedAd.title || ""}
            onChange={(e) =>
              setSelectedAd({ ...selectedAd, title: e.target.value })
            }
          />
          <TextField
            label="Image URL"
            fullWidth
            margin="dense"
            value={selectedAd.imageUrl || ""}
            onChange={(e) =>
              setSelectedAd({ ...selectedAd, imageUrl: e.target.value })
            }
          />

          <TextField
            label="Category"
            fullWidth
            margin="dense"
            value={selectedAd.category || ""}
            onChange={(e) =>
              setSelectedAd({ ...selectedAd, category: e.target.value })
            }
          />
          <TextField
            label="Description"
            fullWidth
            margin="dense"
            multiline
            value={selectedAd.description || ""}
            onChange={(e) =>
              setSelectedAd({ ...selectedAd, description: e.target.value })
            }
          />
          {isUserMarket ? (
            <TextField
              label="Contact"
              fullWidth
              margin="dense"
              value={(selectedAd as UserAd).contact || ""}
              onChange={(e) =>
                setSelectedAd({ ...selectedAd, contact: e.target.value })
              }
            />
          ) : (
            <>
              <TextField
                label="Location"
                fullWidth
                margin="dense"
                value={(selectedAd as AdminAd).location || ""}
                onChange={(e) =>
                  setSelectedAd({ ...selectedAd, location: e.target.value })
                }
              />
              <TextField
                label="Original Price"
                fullWidth
                margin="dense"
                value={(selectedAd as AdminAd).originalPrice || ""}
                onChange={(e) =>
                  setSelectedAd({
                    ...selectedAd,
                    originalPrice: e.target.value,
                  })
                }
              />
              <TextField
                label="Price"
                fullWidth
                margin="dense"
                value={(selectedAd as AdminAd).price || ""}
                onChange={(e) =>
                  setSelectedAd({ ...selectedAd, price: e.target.value })
                }
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={isAdding ? handleAddAdminAd : handleEditAd}
            color="primary"
          >
            {isAdding ? "Add" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AdManagement;
