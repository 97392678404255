import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Drawer,
  List,
  Divider,
  ListItemText,
  ListItemIcon,
  Avatar,
  Tooltip,
  useTheme,
  alpha,
  Badge,
  Container,
} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import StoreIcon from "@mui/icons-material/Store";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import ForumIcon from "@mui/icons-material/Forum";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import InfoIcon from "@mui/icons-material/Info";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import MessageIcon from "@mui/icons-material/Message";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SearchIcon from "@mui/icons-material/Search";
import { db } from "../services/firebase";
import { doc, getDoc } from "firebase/firestore";
import { motion } from "framer-motion";
import logo from "../assets/images/logo.png";
import ListItem from "@mui/material/ListItem";

const PlatformMenu: React.FC = () => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [userRole, setUserRole] = useState<string>("user");

  useEffect(() => {
    if (currentUser) {
      const fetchUserRole = async () => {
        try {
          const userDocRef = doc(db, "users", currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            setUserRole(userDocSnap.data().role || "user");
          }
        } catch (error) {
          console.error("Error fetching user role: ", error);
        }
      };
      fetchUserRole();
    }
  }, [currentUser]);

  const menuItems = [
    {
      label: "Dashboard",
      route: "/dashboard",
      icon: <DashboardIcon />,
      color: "#4CAF50",
    },
    {
      label: "Market",
      route: "/market",
      icon: <StoreIcon />,
      color: "#2196F3",
    },
    {
      label: "Vets",
      route: "/vets",
      icon: <MedicalServicesIcon />,
      color: "#E91E63",
    },
    {
      label: "Forum",
      route: "/forum",
      icon: <ForumIcon />,
      color: "#FF9800",
    },
    {
      label: "Knowledge",
      route: "/knowledge-base",
      icon: <MenuBookIcon />,
      color: "#9C27B0",
    },
    {
      label: "About",
      route: "/about",
      icon: <InfoIcon />,
      color: "#607D8B",
    },
  ];

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleMessengerClick = () => {
    navigate("/messenger");
  };

  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          backgroundColor: "background.paper",
          borderBottom: `1px solid ${alpha(theme.palette.divider, 0.08)}`,
          backdropFilter: "blur(8px)",
          background: alpha(theme.palette.background.paper, 0.9),
        }}
      >
        <Container maxWidth="xl">
          <Toolbar
            sx={{
              minHeight: { xs: 64, sm: 70 },
              justifyContent: "space-between",
              px: { xs: 1, sm: 2 },
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                edge="start"
                sx={{
                  mr: 1,
                  display: { xs: "block", md: "none" },
                  color: "text.primary",
                }}
                onClick={() => setDrawerOpen(true)}
              >
                <MenuIcon />
              </IconButton>

              <Box
                component={motion.div}
                whileHover={{ scale: 1.02 }}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/")}
              >
                <img
                  src={logo}
                  alt="Gadbetay Logo"
                  style={{ height: "32px", marginRight: "12px" }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    color: "primary.main",
                    fontWeight: 700,
                    letterSpacing: 0.5,
                    display: { xs: "none", sm: "block" },
                  }}
                >
                  Gadbetay
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                gap: 1,
                mx: 4,
                flex: 1,
                justifyContent: "center",
              }}
            >
              {menuItems.map((item, index) => (
                <Button
                  key={index}
                  component={motion.button}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => navigate(item.route)}
                  sx={{
                    px: 2,
                    py: 1,
                    color: "text.primary",
                    borderRadius: 2,
                    transition: "all 0.2s",
                    textTransform: "none",
                    fontWeight: 500,
                    "&:hover": {
                      backgroundColor: alpha(item.color, 0.08),
                      color: item.color,
                    },
                  }}
                  startIcon={React.cloneElement(item.icon, {
                    sx: { color: item.color },
                  })}
                >
                  {item.label}
                </Button>
              ))}
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Tooltip title="Messages">
                <IconButton
                  onClick={handleMessengerClick}
                  sx={{
                    backgroundColor: alpha(theme.palette.info.main, 0.04),
                    "&:hover": {
                      backgroundColor: alpha(theme.palette.info.main, 0.08),
                    },
                  }}
                >
                  <MessageIcon color="info" />
                </IconButton>
              </Tooltip>

              {/* <Tooltip title="Notifications">
                <IconButton
                  sx={{
                    backgroundColor: alpha(theme.palette.warning.main, 0.04),
                    "&:hover": {
                      backgroundColor: alpha(theme.palette.warning.main, 0.08),
                    },
                  }}
                >
                    <NotificationsIcon color="warning" />
                </IconButton>
              </Tooltip> */}

              <Tooltip title={`Logged in as ${userRole}`}>
                <Avatar
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  sx={{
                    cursor: "pointer",
                    bgcolor: theme.palette.primary.main,
                    transition: "all 0.2s",
                    "&:hover": {
                      transform: "scale(1.05)",
                      boxShadow: theme.shadows[4],
                    },
                  }}
                >
                  {currentUser?.displayName?.charAt(0) || "U"}
                </Avatar>
              </Tooltip>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          elevation: 4,
          sx: {
            mt: 1.5,
            minWidth: 200,
            borderRadius: 2,
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.08))",
            "& .MuiMenuItem-root": {
              py: 1.5,
              px: 2,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            navigate("/profile");
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <AccountCircleIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Profile" />
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon color="error" />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
      </Menu>

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{
          sx: {
            width: 280,
            borderRadius: "0 24px 24px 0",
            borderRight: "none",
            boxShadow: theme.shadows[8],
          },
        }}
      >
        <Box
          sx={{
            p: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <img src={logo} alt="Logo" style={{ height: "28px" }} />
            <Typography variant="h6" color="primary" fontWeight={700}>
              Gadbetay
            </Typography>
          </Box>
          <IconButton
            onClick={() => setDrawerOpen(false)}
            sx={{
              "&:hover": {
                backgroundColor: alpha(theme.palette.error.main, 0.08),
                color: theme.palette.error.main,
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider sx={{ mx: 2 }} />
        <List sx={{ px: 2, py: 1 }}>
          {menuItems.map((item, index) => (
            <Box
              key={index}
              component={motion.div}
              whileHover={{ x: 4 }}
              sx={{
                display: "flex",
                alignItems: "center",
                borderRadius: 2,
                mb: 0.5,
                padding: "8px 16px",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: alpha(item.color, 0.08),
                },
              }}
              onClick={() => {
                navigate(item.route);
                setDrawerOpen(false);
              }}
            >
              <ListItemIcon sx={{ color: item.color }}>
                {item.icon}
              </ListItemIcon>
              <Typography sx={{ fontWeight: 500 }} variant="body1">
                {item.label}
              </Typography>
            </Box>
          ))}
        </List>
      </Drawer>

      <Toolbar sx={{ mb: 2 }} />
    </>
  );
};

export default PlatformMenu;
