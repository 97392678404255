import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  TextField,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  ButtonBase,
  Dialog,
  DialogContent,
  DialogTitle,
  useTheme,
  useMediaQuery,
  Chip,
  Avatar,
  Grid,
  Skeleton,
} from "@mui/material";
import {
  Search,
  BookmarkBorder,
  Share,
  Bookmark,
  Timeline,
  LocalLibrary,
  TrendingUp,
  AccessTime,
  Category,
  ArrowForward,
  Close,
  FilterList,
} from "@mui/icons-material";
import { collection, query, getDocs } from "firebase/firestore";
import { db } from "../services/firebase";
import PlatformMenu from "../components/PlatformMenu";

interface Article {
  id: string;
  title: string;
  category: string;
  description: string;
  content: string;
  reads: number;
  imageUrl?: string;
}

const KnowledgeBase: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [searchQuery, setSearchQuery] = useState("");
  const [articles, setArticles] = useState<Article[]>([]);
  const [filteredArticles, setFilteredArticles] = useState<Article[]>([]);
  const [categories] = useState<string[]>([
    "Animal Health",
    "Vaccination",
    "Nutrition",
    "Animal Care",
    "Veterinary",
    "Product Guides",
    "Platform",
    "Market",
    "Legal",
    "Community",
  ]);
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedArticle, setSelectedArticle] = useState<Article | null>(null);
  const [saved, setSaved] = useState<Set<string>>(new Set());

  useEffect(() => {
    const fetchArticles = async () => {
      const q = query(collection(db, "articles"));
      const querySnapshot = await getDocs(q);
      const articlesData: Article[] = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        articlesData.push({
          id: doc.id,
          title: data.title,
          category: data.category,
          description: data.description,
          content: data.content,
          reads: data.reads || 0,
          imageUrl: data.imageUrl || "",
        });
      });
      setArticles(articlesData);
      setFilteredArticles(articlesData);
    };
    fetchArticles();
  }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleCategoryFilter = (category: string) => {
    setSelectedCategory(category);
    if (category) {
      setFilteredArticles(
        articles.filter((article) => article.category === category)
      );
    } else {
      setFilteredArticles(articles);
    }
  };

  const filteredAndSearchedArticles = filteredArticles.filter((article) =>
    article.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleReadMore = (article: Article) => {
    setSelectedArticle(article);
  };

  const handleShare = (article: Article) => {
    const shareData = {
      title: article.title,
      text: article.description,
      url: window.location.href,
    };
    if (navigator.share) {
      navigator
        .share(shareData)
        .catch((error) => console.error("Error sharing", error));
    } else {
      navigator.clipboard
        .writeText(
          `${article.title}\n${article.description}\n${window.location.href}`
        )
        .then(() => alert("Article link copied to clipboard!"))
        .catch((error) => console.error("Error copying to clipboard", error));
    }
  };

  const toggleSave = (articleId: string) => {
    const newSaved = new Set(saved);
    if (saved.has(articleId)) {
      newSaved.delete(articleId);
    } else {
      newSaved.add(articleId);
    }
    setSaved(newSaved);
  };

  return (
    <Box sx={{ bgcolor: "background.default", minHeight: "100vh" }}>
      <PlatformMenu />

      {/* Hero Section */}
      <Box
        sx={{
          background: (theme) =>
            `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
          pt: { xs: 8, md: 12 },
          pb: { xs: 6, md: 8 },
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Container maxWidth="lg">
          <Typography
            variant="h2"
            fontWeight="800"
            sx={{
              color: "white",
              mb: 2,
              fontSize: { xs: "2rem", md: "3.5rem" },
              textAlign: "center",
            }}
          >
            Knowledge Base
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: "rgba(255, 255, 255, 0.9)",
              mb: 4,
              textAlign: "center",
              maxWidth: "800px",
              mx: "auto",
              fontSize: { xs: "1rem", md: "1.25rem" },
            }}
          >
            Explore expert insights on animal care, sales, and husbandry
          </Typography>

          {/* Search Bar */}
          <Box
            sx={{
              maxWidth: "600px",
              mx: "auto",
              position: "relative",
              transform: "translateY(50%)",
            }}
          >
            <Card
              elevation={24}
              sx={{
                borderRadius: "16px",
                p: 1,
              }}
            >
              <TextField
                fullWidth
                placeholder="Search articles..."
                value={searchQuery}
                onChange={handleSearchChange}
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <Search sx={{ mr: 1, color: "text.secondary" }} />
                  ),
                  disableUnderline: true,
                  sx: { px: 2, py: 1 },
                }}
              />
            </Card>
          </Box>
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ mt: 8, mb: 8 }}>
        {/* Category Filters */}
        <Box
          sx={{
            display: "flex",
            gap: 1,
            mb: 6,
            flexWrap: "wrap",
            justifyContent: "center",
            pt: 4,
          }}
        >
          {categories.map((category) => (
            <Chip
              key={category}
              label={category}
              onClick={() => handleCategoryFilter(category)}
              sx={{
                borderRadius: "8px",
                px: 2,
                backgroundColor:
                  selectedCategory === category
                    ? "primary.main"
                    : "background.paper",
                color: selectedCategory === category ? "white" : "text.primary",
                "&:hover": {
                  backgroundColor:
                    selectedCategory === category
                      ? "primary.dark"
                      : "action.hover",
                },
              }}
              icon={<Category sx={{ fontSize: 18 }} />}
            />
          ))}
          <Chip
            label="All"
            onClick={() => handleCategoryFilter("")}
            sx={{
              borderRadius: "8px",
              px: 2,
              backgroundColor:
                selectedCategory === "" ? "primary.main" : "background.paper",
              color: selectedCategory === "" ? "white" : "text.primary",
            }}
            icon={<FilterList sx={{ fontSize: 18 }} />}
          />
        </Box>

        {/* Main Content */}
        <Grid container spacing={4}>
          {/* Articles Section */}
          <Grid item xs={12} md={8}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
              {filteredAndSearchedArticles.map((article) => (
                <Card
                  key={article.id}
                  sx={{
                    borderRadius: "16px",
                    overflow: "hidden",
                    transition: "transform 0.2s, box-shadow 0.2s",
                    "&:hover": {
                      transform: "translateY(-4px)",
                      boxShadow: (theme) => theme.shadows[8],
                    },
                  }}
                >
                  <CardMedia
                    component="img"
                    height="240"
                    image={
                      article.imageUrl || "https://via.placeholder.com/400x240"
                    }
                    alt={article.title}
                    sx={{ objectFit: "cover" }}
                  />
                  <CardContent sx={{ p: 3 }}>
                    <Box sx={{ display: "flex", gap: 1, mb: 2 }}>
                      <Chip
                        label={article.category}
                        size="small"
                        sx={{ borderRadius: "8px" }}
                      />
                      <Chip
                        icon={<Timeline sx={{ fontSize: 16 }} />}
                        label={`${article.reads} reads`}
                        size="small"
                        variant="outlined"
                        sx={{ borderRadius: "8px" }}
                      />
                    </Box>
                    <Typography variant="h5" gutterBottom fontWeight="bold">
                      {article.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      sx={{ mb: 3, lineHeight: 1.6 }}
                    >
                      {article.description.substring(0, 150)}...
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => handleReadMore(article)}
                        endIcon={<ArrowForward />}
                        sx={{
                          borderRadius: "8px",
                          textTransform: "none",
                          px: 3,
                        }}
                      >
                        Read More
                      </Button>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <IconButton
                          onClick={() => toggleSave(article.id)}
                          color={saved.has(article.id) ? "primary" : "default"}
                        >
                          {saved.has(article.id) ? (
                            <Bookmark />
                          ) : (
                            <BookmarkBorder />
                          )}
                        </IconButton>
                        <IconButton onClick={() => handleShare(article)}>
                          <Share />
                        </IconButton>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              ))}
            </Box>
          </Grid>

          {/* Sidebar */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                position: "sticky",
                top: 24,
                display: "flex",
                flexDirection: "column",
                gap: 4,
              }}
            >
              {/* Top Articles */}
              <Card sx={{ borderRadius: "16px" }}>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      mb: 2,
                    }}
                  >
                    <TrendingUp color="primary" />
                    <Typography variant="h6" fontWeight="bold">
                      Top Articles
                    </Typography>
                  </Box>
                  <List sx={{ px: 0 }}>
                    {articles.slice(0, 3).map((article) => (
                      <ButtonBase
                        key={article.id}
                        onClick={() => handleReadMore(article)}
                        sx={{ width: "100%", textAlign: "left" }}
                      >
                        <ListItem
                          sx={{
                            borderRadius: "8px",
                            "&:hover": { bgcolor: "action.hover" },
                            px: 2,
                          }}
                        >
                          <ListItemText
                            primary={article.title}
                            secondary={`${article.reads} reads`}
                          />
                        </ListItem>
                      </ButtonBase>
                    ))}
                  </List>
                </CardContent>
              </Card>

              {/* Recommended Articles */}
              <Card sx={{ borderRadius: "16px" }}>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      mb: 2,
                    }}
                  >
                    <LocalLibrary color="primary" />
                    <Typography variant="h6" fontWeight="bold">
                      Recommended
                    </Typography>
                  </Box>
                  <List sx={{ px: 0 }}>
                    {articles.slice(3, 6).map((article) => (
                      <ButtonBase
                        key={article.id}
                        onClick={() => handleReadMore(article)}
                        sx={{ width: "100%", textAlign: "left" }}
                      >
                        <ListItem
                          sx={{
                            borderRadius: "8px",
                            "&:hover": { bgcolor: "action.hover" },
                            px: 2,
                          }}
                        >
                          <ListItemText
                            primary={article.title}
                            secondary={
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                }}
                              >
                                <AccessTime sx={{ fontSize: 16 }} />
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {article.category}
                                </Typography>
                              </Box>
                            }
                          />
                        </ListItem>
                      </ButtonBase>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>

        {/* Article Dialog */}
        <Dialog
          open={!!selectedArticle}
          onClose={() => setSelectedArticle(null)}
          fullWidth
          maxWidth="md"
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "16px",
              m: 2,
            },
          }}
        >
          <DialogTitle
            sx={{
              p: 3,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" fontWeight="bold">
              {selectedArticle?.title}
            </Typography>
            <IconButton onClick={() => setSelectedArticle(null)} size="small">
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers sx={{ p: 3 }}>
            {selectedArticle?.imageUrl && (
              <CardMedia
                component="img"
                image={selectedArticle.imageUrl}
                alt={selectedArticle.title}
                sx={{
                  borderRadius: "12px",
                  mb: 3,
                  maxHeight: "400px",
                  objectFit: "cover",
                }}
              />
            )}
            <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
              <Chip
                label={selectedArticle?.category}
                color="primary"
                sx={{ borderRadius: "8px" }}
              />
              <Chip
                icon={<Timeline />}
                variant="outlined"
                sx={{ borderRadius: "8px" }}
              />
            </Box>
            <Typography
              variant="body1"
              sx={{
                lineHeight: 1.8,
                color: "text.primary",
                "& p": { mb: 2 },
                fontSize: "1.1rem",
              }}
            >
              {selectedArticle?.content}
            </Typography>

            {/* Article Actions */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 4,
                pt: 3,
                borderTop: "1px solid",
                borderColor: "divider",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Button
                  variant="contained"
                  startIcon={<BookmarkBorder />}
                  onClick={() =>
                    selectedArticle && toggleSave(selectedArticle.id)
                  }
                  sx={{
                    borderRadius: "8px",
                    textTransform: "none",
                    px: 3,
                  }}
                >
                  {saved.has(selectedArticle?.id || "")
                    ? "Saved"
                    : "Save Article"}
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<Share />}
                  onClick={() =>
                    selectedArticle && handleShare(selectedArticle)
                  }
                  sx={{
                    borderRadius: "8px",
                    textTransform: "none",
                    px: 3,
                  }}
                >
                  Share
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>

        {/* Loading Skeleton for Articles */}
        {!filteredAndSearchedArticles.length && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
            {[1, 2, 3].map((item) => (
              <Card
                key={item}
                sx={{ borderRadius: "16px", overflow: "hidden" }}
              >
                <Skeleton variant="rectangular" height={240} />
                <CardContent>
                  <Box sx={{ display: "flex", gap: 1, mb: 2 }}>
                    <Skeleton
                      variant="rectangular"
                      width={100}
                      height={24}
                      sx={{ borderRadius: "8px" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      width={80}
                      height={24}
                      sx={{ borderRadius: "8px" }}
                    />
                  </Box>
                  <Skeleton variant="text" height={40} sx={{ mb: 1 }} />
                  <Skeleton variant="text" height={20} sx={{ mb: 1 }} />
                  <Skeleton variant="text" height={20} sx={{ mb: 2 }} />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Skeleton
                      variant="rectangular"
                      width={120}
                      height={36}
                      sx={{ borderRadius: "8px" }}
                    />
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Skeleton variant="circular" width={40} height={40} />
                      <Skeleton variant="circular" width={40} height={40} />
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Box>
        )}

        {/* No Results Message */}
        {filteredAndSearchedArticles.length === 0 && searchQuery && (
          <Box
            sx={{
              textAlign: "center",
              py: 8,
              px: 2,
            }}
          >
            <Search sx={{ fontSize: 64, color: "text.secondary", mb: 2 }} />
            <Typography variant="h5" gutterBottom>
              No articles found
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Try adjusting your search or filter to find what you're looking
              for
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                setSearchQuery("");
                setSelectedCategory("");
              }}
              sx={{ mt: 3, borderRadius: "8px", textTransform: "none" }}
            >
              Clear Search
            </Button>
          </Box>
        )}
      </Container>

      {/* Mobile Bottom Navigation (optional) */}
      {isMobile && (
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            bgcolor: "background.paper",
            borderTop: "1px solid",
            borderColor: "divider",
            px: 2,
            py: 1,
            display: "flex",
            justifyContent: "space-around",
            zIndex: 1000,
          }}
        >
          <IconButton
            color="primary"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <Search />
          </IconButton>
          <IconButton color="primary" onClick={() => handleCategoryFilter("")}>
            <Category />
          </IconButton>
          <IconButton color="primary">
            <BookmarkBorder />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default KnowledgeBase;
