import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  Button,
  Card,
  CardContent,
  Fade,
  IconButton,
  Collapse,
  useTheme,
  useMediaQuery,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../services/firebase";
import PlatformMenu from "../components/PlatformMenu";


import {
  Pets as PetsIcon,
  Vaccines as VaccinesIcon,
  LocationOn as LocationOnIcon,
  LocalHospital as LocalHospitalIcon,
  Store as StoreIcon,
  Forum as ForumIcon,
  Article as ArticleIcon,
  People as PeopleIcon,
  Support as SupportIcon,
  Security as SecurityIcon,
  Assignment as AssignmentIcon,
  ExpandMore as ExpandMoreIcon,
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  Instagram as InstagramIcon,
  Favorite as FavoriteIcon,
  ArrowForward as ArrowForwardIcon,
  HealthAndSafety as HealthAndSafetyIcon,
  Map as MapIcon,
  CalendarToday as CalendarIcon,
  WorkOutline as WorkIcon,
  AutoGraph as AutoGraphIcon,
} from "@mui/icons-material";

import logo from "../assets/images/logo.png";
import HeroImage from "../assets/images/HeroImage.png";

const LandingPage: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState<boolean[]>(new Array(4).fill(false));
  const [isSignedIn, setIsSignedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsSignedIn(!!user);
    });
    return () => unsubscribe();
  }, []);

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const toggleFAQ = (index: number) => {
    setOpen((prev) => prev.map((item, i) => (i === index ? !item : item)));
  };

  const features = [
    {
      icon: <PetsIcon />,
      title: "Animal Registration",
      description: "Digital profiles & virtual passports for your livestock",
    },
    {
      icon: <VaccinesIcon />,
      title: "Vaccination Tracking",
      description: "Manage and verify vaccination records",
    },
    {
      icon: <MapIcon />,
      title: "Real-time Tracking",
      description: "Monitor your animals' location and status",
    },
    {
      icon: <LocalHospitalIcon />,
      title: "Vet Network",
      description: "Find and connect with veterinarians",
    },
    {
      icon: <CalendarIcon />,
      title: "Appointment Booking",
      description: "Schedule vet visits online",
    },
    {
      icon: <StoreIcon />,
      title: "Marketplace",
      description: "Shop for medical supplies and equipment",
    },
    {
      icon: <ForumIcon />,
      title: "Community Forum",
      description: "Discuss and share experiences",
    },
    {
      icon: <WorkIcon />,
      title: "Job Portal",
      description: "Connect with veterinary professionals",
    },
  ];

  const stats = [
    { icon: <AutoGraphIcon />, value: "10K+", label: "Registered Farms" },
    { icon: <PetsIcon />, value: "50K+", label: "Animals Tracked" },
    { icon: <LocalHospitalIcon />, value: "500+", label: "Veterinarians" },
    { icon: <PeopleIcon />, value: "20K+", label: "Active Users" },
  ];

  const faqs = [
    {
      question: "How does animal registration work?",
      answer:
        "Simply create a digital profile with your animal's details and receive a virtual passport. Track vaccinations and medical history all in one place.",
    },
    {
      question: "How can I find nearby veterinarians?",
      answer:
        "Use our interactive map to locate certified vets in your area. Filter by specialization and book appointments directly through the platform.",
    },
    {
      question: "What products are available in the marketplace?",
      answer:
        "We offer a wide range of veterinary supplies, medicines, tracking devices, and essential farm equipment from verified suppliers.",
    },
    {
      question: "How secure is my farm's data?",
      answer:
        "We implement bank-level encryption and strict data protection protocols to ensure your farm's information remains completely secure.",
    },
  ];

  return (
    <Box sx={{ bgcolor: "#FAFAFA" }}>
      {/* Navbar */}
      <Box
        sx={{
          position: "fixed",
          top: 0,
          width: "100%",
          zIndex: 1000,
          bgcolor: "rgba(255, 255, 255, 0.95)",
          backdropFilter: "blur(20px)",
          borderBottom: "1px solid",
          borderColor: "divider",
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "70px",
              px: { xs: 2, sm: 0 },
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <img
                src={logo}
                alt="Gadbetay Logo"
                style={{ height: "40px", width: "auto" }}
              />
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 800,
                  background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  display: { xs: "none", sm: "block" },
                }}
              >
                Gadbetay
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 2 }}>
              {isSignedIn ? (
                <PlatformMenu />
              ) : (
                <>
                  <Button
                    variant="outlined"
                    onClick={() => handleNavigate("/login")}
                    sx={{
                      borderRadius: 3,
                      textTransform: "none",
                      px: 3,
                      borderWidth: 2,
                      "&:hover": {
                        borderWidth: 2,
                      },
                    }}
                  >
                    Sign In
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => handleNavigate("/login")}
                    sx={{
                      borderRadius: 3,
                      textTransform: "none",
                      px: 3,
                      background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
                      boxShadow: "none",
                      "&:hover": {
                        boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
                      },
                    }}
                  >
                    Get Started
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Hero Section */}
      <Box
        sx={{
          pt: { xs: 12, md: 20 },
          pb: { xs: 8, md: 15 },
          background: `linear-gradient(165deg, #f8f9fa 60%, ${theme.palette.primary.light}40 100%)`,
          backgroundImage: `url(${HeroImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <Fade in timeout={1000}>
                <Box sx={{ textAlign: { xs: "center", md: "left" } }}>
                  <Typography
                    variant="h2"
                    sx={{
                      fontWeight: 800,
                      mb: 3,
                      fontSize: { xs: "2.5rem", md: "3.5rem" },
                      background: "white",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      lineHeight: 1.2,
                    }}
                  >
                    Transform animal Care With Technology
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      mb: 4,
                      color: "white",
                      fontWeight: 400,
                      fontSize: { xs: "1rem", md: "1.25rem" },
                    }}
                  >
                    Track, manage, and care for your farm animals with our
                    comprehensive digital platform
                  </Typography>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() => handleNavigate("/login")}
                    sx={{
                      borderRadius: 3,
                      textTransform: "none",
                      px: 4,
                      py: 2,
                      background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
                      boxShadow: "0 4px 14px rgba(0,0,0,0.1)",
                      "&:hover": {
                        boxShadow: "0 6px 20px rgba(0,0,0,0.15)",
                      },
                    }}
                    endIcon={<ArrowForwardIcon />}
                  >
                    Start Your Digital Farm Journey
                  </Button>
                </Box>
              </Fade>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                {/* <HealthAndSafetyIcon
                  sx={{
                    fontSize: { xs: 200, md: 300 },
                    color: theme.palette.primary.main,
                    opacity: 0.1,
                    transform: "rotate(-15deg)",
                  }}
                /> */}
                {/* Additional decorative icons */}
                {/* <PetsIcon
                  sx={{
                    position: "absolute",
                    fontSize: 40,
                    color: theme.palette.primary.main,
                    top: "20%",
                    left: "20%",
                    opacity: 0.6,
                  }}
                /> */}
                {/* <MapIcon
                  sx={{
                    position: "absolute",
                    fontSize: 40,
                    color: theme.palette.secondary.main,
                    bottom: "30%",
                    right: "25%",
                    opacity: 0.6,
                  }}
                /> */}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Stats Section */}
      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Grid container spacing={4}>
          {stats.map((stat, index) => (
            <Grid item xs={6} md={3} key={index}>
              <Card
                sx={{
                  height: "100%",
                  borderRadius: 4,
                  border: "1px solid",
                  borderColor: "divider",
                  boxShadow: "none",
                  textAlign: "center",
                  p: 2,
                }}
              >
                <Box sx={{ color: "primary.main", mb: 1 }}>
                  {React.cloneElement(stat.icon, { sx: { fontSize: 40 } })}
                </Box>
                <Typography variant="h4" sx={{ fontWeight: 700, mb: 1 }}>
                  {stat.value}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {stat.label}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Features Section */}
      <Container maxWidth="lg" sx={{ py: 10 }}>
        <Typography
          variant="h3"
          align="center"
          sx={{
            mb: 2,
            fontWeight: 800,
            fontSize: { xs: "2rem", md: "2.5rem" },
            background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Everything You Need
        </Typography>
        <Typography
          align="center"
          color="text.secondary"
          sx={{ mb: 8, maxWidth: "600px", mx: "auto" }}
        >
          Comprehensive tools and features to manage your farm efficiently
        </Typography>
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card
                sx={{
                  height: "100%",
                  borderRadius: 4,
                  transition: "all 0.3s ease",
                  border: "1px solid",
                  borderColor: "divider",
                  boxShadow: "none",
                  "&:hover": {
                    transform: "translateY(-5px)",
                    boxShadow: "0 4px 14px rgba(0,0,0,0.1)",
                    borderColor: "primary.main",
                  },
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mb: 2,
                      height: 80,
                    }}
                  >
                    {React.cloneElement(feature.icon, {
                      sx: { fontSize: 48, color: "primary.main" },
                    })}
                  </Box>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: 600, mb: 1, textAlign: "center" }}
                  >
                    {feature.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "text.secondary", textAlign: "center" }}
                  >
                    {feature.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* FAQ Section */}
      <Box sx={{ bgcolor: "white", py: 10 }}>
        <Container maxWidth="lg">
          <Typography
            variant="h3"
            align="center"
            sx={{
              mb: 2,
              fontWeight: 800,
              fontSize: { xs: "2rem", md: "2.5rem" },
              background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Common Questions
          </Typography>
          <Typography
            align="center"
            color="text.secondary"
            sx={{ mb: 8, maxWidth: "600px", mx: "auto" }}
          >
            Find answers to frequently asked questions about our platform
          </Typography>
          <Grid container spacing={3} justifyContent="center">
            {faqs.map((faq, index) => (
              <Grid item xs={12} md={10} key={index}>
                <Card
                  sx={{
                    borderRadius: 4,
                    transition: "all 0.3s ease",
                    border: "1px solid",
                    borderColor: open[index] ? "primary.main" : "divider",
                    boxShadow: "none",
                    "&:hover": {
                      borderColor: "primary.main",
                      boxShadow: "0 4px 14px rgba(0,0,0,0.1)",
                    },
                  }}
                >
                  <CardContent sx={{ p: 0 }}>
                    <Box
                      onClick={() => toggleFAQ(index)}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        p: 3,
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 600,
                          color: open[index] ? "primary.main" : "text.primary",
                        }}
                      >
                        {faq.question}
                      </Typography>
                      <IconButton
                        sx={{
                          transform: open[index] ? "rotate(180deg)" : "none",
                          transition: "transform 0.3s",
                        }}
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </Box>
                    <Collapse in={open[index]} timeout="auto">
                      <Divider />
                      <Box sx={{ p: 3, bgcolor: "rgba(0,0,0,0.02)" }}>
                        <Typography color="text.secondary">
                          {faq.answer}
                        </Typography>
                      </Box>
                    </Collapse>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* CTA Section */}
      <Container maxWidth="lg" sx={{ py: 10 }}>
        <Card
          sx={{
            borderRadius: 6,
            background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
            boxShadow: "0 4px 14px rgba(0,0,0,0.1)",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              right: -100,
              top: -100,
              width: 300,
              height: 300,
              borderRadius: "50%",
              backgroundColor: "rgba(255,255,255,0.1)",
            }}
          />
          <CardContent sx={{ p: { xs: 4, md: 6 } }}>
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={8}>
                <Typography
                  variant="h3"
                  sx={{
                    color: "white",
                    fontWeight: 800,
                    mb: 2,
                    fontSize: { xs: "2rem", md: "2.5rem" },
                  }}
                >
                  Ready to Transform Your Farm Management?
                </Typography>
                <Typography
                  sx={{
                    color: "rgba(255,255,255,0.9)",
                    mb: { xs: 3, md: 0 },
                    fontSize: { xs: "1rem", md: "1.1rem" },
                  }}
                >
                  Join thousands of farmers who are already managing their
                  livestock smarter with Gadbetay.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", md: "flex-end" },
                }}
              >
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => handleNavigate("/login")}
                  sx={{
                    borderRadius: 3,

                    textTransform: "none",
                    px: 4,
                    py: 2,
                    bgcolor: "white",
                    color: "primary.main",
                    "&:hover": {
                      bgcolor: "rgba(255,255,255,0.9)",
                    },
                  }}
                  endIcon={<ArrowForwardIcon />}
                >
                  Get Started Free
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>

      {/* Footer */}
      <Box
        sx={{
          bgcolor: "black",
          color: "white",
          py: { xs: 6, md: 8 },
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            {/* Logo and Description */}
            <Grid item xs={12} md={4}>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <img
                  src={logo}
                  alt="Gadbetay Logo"
                  style={{ height: "40px", marginRight: "12px" }}
                />
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 800,
                    background: `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  Gadbetay
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "rgba(255,255,255,0.7)",
                  mb: 3,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Your farm's trusted companion
                <FavoriteIcon
                  sx={{ color: theme.palette.error.main, ml: 1, fontSize: 16 }}
                />
              </Typography>
            </Grid>

            {/* Quick Links */}
            <Grid item xs={12} md={4}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                Quick Links
              </Typography>
              <Grid container spacing={2}>
                {["About Us", "Contact"].map((link) => (
                  <Grid item xs={6} key={link}>
                    <Button
                      sx={{
                        color: "rgba(255,255,255,0.7)",
                        textTransform: "none",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    >
                      {link}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            {/* Social Media */}
            <Grid item xs={12} md={4}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                Connect With Us
              </Typography>
              <Box sx={{ display: "flex", gap: 2 }}>
                {[
                  { icon: <FacebookIcon />, color: "#1877F2" },
                  { icon: <TwitterIcon />, color: "#1DA1F2" },
                  { icon: <InstagramIcon />, color: "#E4405F" },
                ].map((social, index) => (
                  <IconButton
                    key={index}
                    sx={{
                      bgcolor: "rgba(255,255,255,0.1)",
                      "&:hover": {
                        bgcolor: social.color,
                        transform: "translateY(-3px)",
                      },
                      transition: "all 0.3s ease",
                    }}
                  >
                    {React.cloneElement(social.icon, {
                      sx: { color: "white" },
                    })}
                  </IconButton>
                ))}
              </Box>
            </Grid>
          </Grid>

          <Divider sx={{ my: 4, bgcolor: "rgba(255,255,255,0.1)" }} />

          {/* Copyright */}
          <Typography
            align="center"
            sx={{ color: "rgba(255,255,255,0.5)", fontSize: 14 }}
          >
            © {new Date().getFullYear()} Gadbetay. All rights reserved.
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default LandingPage;
