import React, { useEffect, useState } from "react";
import { getDoc } from "firebase/firestore";
import theme from "../styles/theme";

import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { auth, db } from "../services/firebase";
import {
  setDoc,
  doc,
  getDocs,
  collection,
  query,
  where,
} from "firebase/firestore";
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  Paper,
  Divider,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
  Zoom,
} from "@mui/material";
import {
  Google as GoogleIcon,
  Visibility,
  VisibilityOff,
  AccountCircle,
  Email,
  Lock,
  LocationOn,
  ArrowForward,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../components/AlertContext";
import { SelectChangeEvent } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";

const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [location, setLocation] = useState("");
  const [isRegistering, setIsRegistering] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  const { setAlert } = useAlert();
  const provider = new GoogleAuthProvider();
  const navigate = useNavigate();

  const departments = [
    "Artibonite",
    "Centre",
    "Grand'Anse",
    "Nippes",
    "Nord",
    "Nord-Est",
    "Nord-Ouest",
    "Ouest",
    "Sud",
    "Sud-Est",
    "Not in Haiti",
  ];

  const validatePassword = (pass: string) => {
    const requirements = {
      length: pass.length >= 8,
      uppercase: /[A-Z]/.test(pass),
      lowercase: /[a-z]/.test(pass),
      number: /\d/.test(pass),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(pass),
    };

    if (!requirements.length)
      return "Password must be at least 8 characters long";
    if (!requirements.uppercase)
      return "Password must contain an uppercase letter";
    if (!requirements.lowercase)
      return "Password must contain a lowercase letter";
    if (!requirements.number) return "Password must contain a number";
    if (!requirements.special)
      return "Password must contain a special character";
    return "";
  };

  const generateUsername = async (baseUsername: string) => {
    let finalUsername = baseUsername;
    let counter = 1;

    while (true) {
      const usernameQuery = query(
        collection(db, "users"),
        where("username", "==", finalUsername)
      );
      const querySnapshot = await getDocs(usernameQuery);

      if (querySnapshot.empty) {
        return finalUsername;
      }

      finalUsername = `${baseUsername}${counter}`;
      counter++;
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) navigate("/dashboard");
    });
    return () => unsubscribe();
  }, [navigate]);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setPasswordError(validatePassword(newPassword));
  };

  const handlePhoneNumberChange = (value: string) => {
    setPhoneNumber(value);
  };

  const handleRegister = async () => {
    if (!firstName || !lastName || !phoneNumber || !location) {
      setAlert("Please fill in all fields.", "error");
      return;
    }

    if (passwordError) {
      setAlert("Please fix password issues before continuing.", "error");
      return;
    }

    try {
      const baseUsername = `${firstName.toLowerCase()}${lastName.toLowerCase()}@Gadbetay`;
      const finalUsername = await generateUsername(baseUsername);

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      await setDoc(doc(db, "users", user.uid), {
        firstName,
        lastName,
        phoneNumber,
        location,
        email,
        username: finalUsername,
      });

      setAlert("Registration successful!", "success");
      navigate("/dashboard");
    } catch (error: any) {
      setAlert(error.message, "error");
    }
  };

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setAlert("Login successful!", "success");
      navigate("/dashboard");
    } catch (error: any) {
      setAlert(error.message, "error");
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);

        
        if (!userDoc.exists()) {
          const { displayName, email, phoneNumber, photoURL } = user;

          
          const [firstName, lastName] = displayName
            ? displayName.split(" ")
            : ["", ""];

          await setDoc(userDocRef, {
            firstName: firstName || "Unknown",
            lastName: lastName || "Unknown",
            email: email || "No Email",
            phoneNumber: phoneNumber || "No Phone Number",
            photoURL: photoURL || "",
            location: "Unknown",
            username: await generateUsername(email?.split("@")[0] || "user"),
          });

          setAlert("Registration successful!", "success");
        } else {
          setAlert("Welcome back!", "success");
        }

        
        navigate("/dashboard");
      }
    } catch (error: any) {
      setAlert(error.message, "error");
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        background: "linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)",
        padding: 3,
      }}
    >
      <Container maxWidth="sm" sx={{ display: "flex", alignItems: "center" }}>
        <Paper
          elevation={24}
          sx={{
            width: "100%",
            borderRadius: 4,
            overflow: "hidden",
            background: "rgba(255, 255, 255, 0.95)",
            backdropFilter: "blur(10px)",
          }}
        >
          <Box sx={{ p: 4 }}>
            <Typography
              variant="h4"
              align="center"
              sx={{
                mb: 4,
                fontWeight: 700,
                background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              {isRegistering ? "Create Account" : "Welcome Back"}
            </Typography>

            <Box component="form" sx={{ mt: 2 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email color="primary" />
                    </InputAdornment>
                  ),
                }}
              />

              <Tooltip
                open={!!passwordError}
                title={passwordError}
                placement="right"
                TransitionComponent={Zoom}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={handlePasswordChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock color="primary" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Tooltip>

              {isRegistering && (
                <>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      label="First Name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircle color="primary" />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      label="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircle color="primary" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>

                  <MuiTelInput
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    fullWidth
                    defaultCountry="HT"
                    onlyCountries={["HT", "DO", "CU", "JM", "BS"]}
                    langOfCountryName="en"
                    preferredCountries={["HT"]}
                    focusOnSelectCountry
                    sx={{
                      "& .MuiInputBase-root": {
                        paddingLeft: "8px",
                      },
                      "& .MuiInputAdornment-root": {
                        marginRight: "8px",
                      },
                    }}
                  />

                  <FormControl fullWidth margin="normal" required>
                    <InputLabel>Location</InputLabel>
                    <Select
                      value={location}
                      onChange={(e: SelectChangeEvent) =>
                        setLocation(e.target.value)
                      }
                      startAdornment={
                        <InputAdornment position="start">
                          <LocationOn color="primary" />
                        </InputAdornment>
                      }
                    >
                      {departments.map((dept) => (
                        <MenuItem key={dept} value={dept}>
                          {dept}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}

              <Button
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  height: 56,
                  background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
                  boxShadow: "0 0px px 0px green",
                  transition: "transform 0.2s",
                  "&:hover": {
                    transform: "scale(1.01)",
                  },
                }}
                onClick={isRegistering ? handleRegister : handleLogin}
                endIcon={<ArrowForward />}
              >
                {isRegistering ? "Create Account" : "Sign In"}
              </Button>

              <Button
                fullWidth
                variant="outlined"
                startIcon={<GoogleIcon />}
                sx={{
                  mb: 2,
                  height: 56,
                  borderWidth: 2,
                  "&:hover": {
                    borderWidth: 2,
                  },
                }}
                onClick={handleGoogleSignIn}
              >
                Continue with Google
              </Button>

              <Divider sx={{ my: 2 }}>
                <Typography color="text.secondary" variant="body2">
                  OR
                </Typography>
              </Divider>

              <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                {isRegistering
                  ? "Already have an account?"
                  : "Don't have an account?"}{" "}
                <Button
                  variant="text"
                  onClick={() => setIsRegistering(!isRegistering)}
                  sx={{ textTransform: "none" }}
                >
                  {isRegistering ? "Sign In" : "Create Account"}
                </Button>
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default Login;
