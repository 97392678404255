import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#228B22",
      light: "#66bb6a",
      dark: "#134d13",
      contrastText: "#fff",
    },
    secondary: {
      main: "#f44336",
      light: "#ff7961",
      dark: "#ba000d",
      contrastText: "#fff",
    },
    error: {
      main: "#d32f2f",
    },
    warning: {
      main: "#ffa000",
    },
    info: {
      main: "#0288d1",
    },
    success: {
      main: "#388e3c",
    },
    background: {
      default: "#f5f5f5",
      paper: "#fff",
    },
    text: {
      primary: "#212121",
      secondary: "#757575",
      disabled: "#bdbdbd",
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          borderRadius: 4,
          padding: "12px 24px",
        },
        standardSuccess: {
          backgroundColor: "#388e3c",
          color: "#fff",
        },
        standardError: {
          backgroundColor: "#d32f2f",
          color: "#fff",
        },
        standardWarning: {
          backgroundColor: "#ffa000",
          color: "#fff",
        },

        standardInfo: {
          backgroundColor: "#0288d1",
          color: "#fff",
        },
      },
    },
  },
});

export default theme;
