import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  TextField,
  Box,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Fade,
  Chip,
  Grid,
  IconButton,
  Paper,
  alpha,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Divider,
} from "@mui/material";
import {
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
  Close as CloseIcon,
  Refresh as RefreshIcon,
  QuestionAnswer as QuestionAnswerIcon,
  ChatBubbleOutline as ChatIcon,
  Schedule as ScheduleIcon,
  ErrorOutline as ErrorIcon,
  CheckCircleOutline as CheckIcon,
  AutorenewOutlined as InProgressIcon,
  Send as SendIcon,
  LiveHelp as LiveHelpIcon,
  ContactSupport as ContactSupportIcon,
} from "@mui/icons-material";
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore";
import { db } from "../services/firebase";
import PlatformMenu from "../components/PlatformMenu";
import theme from "../styles/theme";
import HelpIcon from "@mui/icons-material/Help";

interface TicketMessage {
  id: string;
  ticketId: string;
  userId: string;
  message: string;
  createdAt: Date;
  isStaff: boolean;
}

interface Ticket {
  id: string;
  userId: string;
  subject: string;
  description: string;
  status: "open" | "in-progress" | "closed";
  createdAt: Date;
  updatedAt: Date;
  messages: TicketMessage[];
}

interface FAQ {
  id: string;
  question: string;
  answer: string;
  category: string;
}

const HelpCenter = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [faqs, setFaqs] = useState<FAQ[]>([]);
  const [loading, setLoading] = useState(false);
  const [newTicket, setNewTicket] = useState({
    subject: "",
    description: "",
  });
  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);
  const [newMessage, setNewMessage] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchFAQs = async () => {
      setLoading(true);
      try {
        const faqsRef = collection(db, "faqs");
        const snapshot = await getDocs(faqsRef);
        const fetchedFaqs = snapshot.docs.map(
          (doc) =>
            ({
              id: doc.id,
              ...doc.data(),
            } as FAQ)
        );
        setFaqs(fetchedFaqs);
      } catch (err) {
        setError("Failed to fetch FAQs");
        console.error(err);
      }
      setLoading(false);
    };

    fetchFAQs();
  }, []);

  useEffect(() => {
    const fetchTickets = async () => {
      setLoading(true);
      try {
        const ticketsRef = collection(db, "tickets");
        const userTickets = query(
          ticketsRef,
          where("userId", "==", "current-user-id")
        );
        const snapshot = await getDocs(userTickets);
        const fetchedTickets = snapshot.docs.map(
          (doc) =>
            ({
              id: doc.id,
              ...doc.data(),
            } as Ticket)
        );
        setTickets(fetchedTickets);
      } catch (err) {
        setError("Failed to fetch tickets");
        console.error(err);
      }
      setLoading(false);
    };

    fetchTickets();
  }, []);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleSubmitTicket = async () => {
    try {
      const ticketData: Omit<Ticket, "id"> = {
        ...newTicket,
        status: "open" as const,
        userId: "current-user-id",
        createdAt: new Date(),
        updatedAt: new Date(),
        messages: [],
      };

      const docRef = await addDoc(collection(db, "tickets"), ticketData);
      setContactDialogOpen(false);
      setNewTicket({ subject: "", description: "" });
      setTickets((prev) => [...prev, { ...ticketData, id: docRef.id }]);
    } catch (err) {
      setError("Failed to create ticket");
      console.error(err);
    }
  };

  const handleTicketStatusUpdate = async (
    ticketId: string,
    newStatus: "open" | "in-progress" | "closed"
  ) => {
    try {
      const ticketRef = doc(db, "tickets", ticketId);
      await updateDoc(ticketRef, {
        status: newStatus,
        updatedAt: new Date(),
      });

      setTickets((prev) =>
        prev.map((ticket) =>
          ticket.id === ticketId
            ? { ...ticket, status: newStatus, updatedAt: new Date() }
            : ticket
        )
      );
    } catch (err) {
      setError("Failed to update ticket status");
      console.error(err);
    }
  };

  const handleAddMessage = async (ticketId: string) => {
    if (!newMessage.trim()) return;

    try {
      const messageId = Math.random().toString(36).substr(2, 9);
      const messageData: TicketMessage = {
        id: messageId,
        ticketId,
        message: newMessage,
        createdAt: new Date(),
        userId: "current-user-id",
        isStaff: false,
      };

      const ticketRef = doc(db, "tickets", ticketId);
      await updateDoc(ticketRef, {
        messages: [...(selectedTicket?.messages || []), messageData],
        updatedAt: new Date(),
      });

      setTickets((prev) =>
        prev.map((ticket) =>
          ticket.id === ticketId
            ? {
                ...ticket,
                messages: [...(ticket.messages || []), messageData],
                updatedAt: new Date(),
              }
            : ticket
        )
      );

      setNewMessage("");
    } catch (err) {
      setError("Failed to add message");
      console.error(err);
    }
  };

  const filteredFAQs = faqs.filter(
    (faq) =>
      faq.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
      faq.answer.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box sx={{ bgcolor: "#f8f9fa", minHeight: "100vh" }}>
      <PlatformMenu />

      <Container maxWidth="lg" sx={{ py: { xs: 3, md: 6 } }}>
        {/* Modern Header Section */}
        <Fade in timeout={800}>
          <Box
            textAlign="center"
            mb={6}
            sx={{
              background: `linear-gradient(135deg, ${
                theme.palette.primary.main
              }, ${alpha(theme.palette.primary.light, 2)})`,
              borderRadius: "24px",
              padding: { xs: 4, md: 8 },
              color: "white",
              boxShadow: "0 8px 32px rgba(33, 150, 243, 0.15)",
            }}
          >
            <LiveHelpIcon sx={{ fontSize: 68, mb: 2 }} />
            <Typography
              variant="h2"
              component="h1"
              gutterBottom
              sx={{
                fontSize: { xs: "2rem", md: "3.5rem" },
                fontWeight: 700,
                mb: 4,
              }}
            >
              How can we help you?
            </Typography>

            <Paper
              elevation={0}
              sx={{
                p: "8px 16px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                maxWidth: 700,
                margin: "0 auto",
                borderRadius: "16px",
                border: "1px solid rgba(0, 0, 0, 0.08)",
                transition: "all 0.3s ease",
                "&:hover": {
                  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                  border: "1px solid rgba(33, 150, 243, 0.3)",
                },
              }}
            >
              <SearchIcon sx={{ color: "text.secondary", mr: 1 }} />
              <TextField
                fullWidth
                placeholder="Search for help..."
                value={searchQuery}
                onChange={handleSearch}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  sx: { fontSize: "1.1rem" },
                }}
              />
            </Paper>
          </Box>
        </Fade>

        {/* FAQ Section with Modern Design */}
        <Box mb={8}>
          <Box display="flex" alignItems="center" mb={4}>
            <QuestionAnswerIcon
              sx={{ mr: 2, color: "primary.main", fontSize: 32 }}
            />
            <Typography
              variant="h4"
              component="h2"
              sx={{
                fontWeight: 600,
                fontSize: { xs: "1.75rem", md: "2.25rem" },
              }}
            >
              Frequently Asked Questions
            </Typography>
          </Box>

          {loading ? (
            <Box display="flex" justifyContent="center" p={4}>
              <CircularProgress size={40} />
            </Box>
          ) : (
            <Grid container spacing={2}>
              {filteredFAQs.map((faq) => (
                <Grid item xs={12} key={faq.id}>
                  <Accordion
                    elevation={0}
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.08)",
                      borderRadius: "12px !important",
                      mb: 1,
                      "&:before": { display: "none" },
                      "&.Mui-expanded": {
                        border: "1px solid rgba(33, 150, 243, 0.3)",
                        bgcolor: "rgba(33, 150, 243, 0.02)",
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{
                        "& .MuiAccordionSummary-content": {
                          margin: "12px 0",
                        },
                      }}
                    >
                      <Typography sx={{ fontWeight: 500 }}>
                        {faq.question}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{ color: "text.secondary" }}>
                        {faq.answer}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>

        {/* Support Tickets Section with Modern Cards */}
        <Box mb={8}>
          <Box display="flex" alignItems="center" mb={4}>
            <ChatIcon sx={{ mr: 2, color: "primary.main", fontSize: 32 }} />
            <Typography
              variant="h4"
              component="h2"
              sx={{
                fontWeight: 600,
                fontSize: { xs: "1.75rem", md: "2.25rem" },
              }}
            >
              Your Support Tickets
            </Typography>
          </Box>

          <Grid container spacing={3}>
            {tickets.map((ticket) => (
              <Grid item xs={12} md={6} key={ticket.id}>
                <Card
                  elevation={0}
                  sx={{
                    border: "1px solid rgba(0, 0, 0, 0.08)",
                    borderRadius: "16px",
                    transition: "all 0.3s ease",
                    "&:hover": {
                      boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                      transform: "translateY(-4px)",
                    },
                  }}
                >
                  <CardContent sx={{ p: 3 }}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                    >
                      <Typography variant="h6" sx={{ fontWeight: 600 }}>
                        {ticket.subject}
                      </Typography>
                      <Chip
                        icon={
                          ticket.status === "open" ? (
                            <ErrorIcon fontSize="small" />
                          ) : ticket.status === "in-progress" ? (
                            <InProgressIcon fontSize="small" />
                          ) : (
                            <CheckIcon fontSize="small" />
                          )
                        }
                        label={ticket.status}
                        sx={{
                          borderRadius: "8px",
                          "& .MuiChip-icon": { ml: "8px" },
                        }}
                        color={
                          ticket.status === "open"
                            ? "error"
                            : ticket.status === "in-progress"
                            ? "warning"
                            : "success"
                        }
                      />
                    </Box>

                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mb: 2 }}
                    >
                      {ticket.description}
                    </Typography>

                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box display="flex" alignItems="center">
                        <ScheduleIcon
                          sx={{
                            fontSize: 20,
                            color: "text.secondary",
                            mr: 1,
                          }}
                        />
                        <Typography variant="caption" color="text.secondary">
                          {new Date(ticket.createdAt).toLocaleDateString()}
                        </Typography>
                      </Box>

                      <Box>
                        <Button
                          size="small"
                          variant="outlined"
                          sx={{
                            mr: 1,
                            borderRadius: "8px",
                          }}
                          onClick={() => setSelectedTicket(ticket)}
                        >
                          View Details
                        </Button>
                        {ticket.status === "closed" && (
                          <Button
                            size="small"
                            variant="contained"
                            startIcon={<RefreshIcon />}
                            sx={{ borderRadius: "8px" }}
                            onClick={() =>
                              handleTicketStatusUpdate(ticket.id, "open")
                            }
                          >
                            Reopen
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Contact Us Section with Modern Style */}
        <Box
          textAlign="center"
          sx={{
            bgcolor: "white",
            borderRadius: "24px",
            p: { xs: 4, md: 6 },
            boxShadow: "0 8px 32px rgba(0, 0, 0, 0.08)",
          }}
        >
          <ContactSupportIcon
            sx={{ fontSize: 48, color: "primary.main", mb: 2 }}
          />
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: 600,
              mb: 3,
            }}
          >
            Haven't found what you're looking for?
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => setContactDialogOpen(true)}
            startIcon={<ChatIcon />}
            sx={{
              borderRadius: "12px",
              textTransform: "none",
              py: 1.5,
              px: 4,
              fontSize: "1.1rem",
            }}
          >
            Contact Us
          </Button>
        </Box>

        {/* Modern Contact Dialog */}
        <Dialog
          open={contactDialogOpen}
          onClose={() => setContactDialogOpen(false)}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: "24px",
              p: 2,
            },
          }}
        >
          <DialogTitle sx={{ pb: 1 }}>
            <Box display="flex" alignItems="center">
              <ChatIcon sx={{ mr: 2, color: "primary.main" }} />
              <Typography
                variant="h5"
                component="span"
                sx={{ fontWeight: 600 }}
              >
                Create a Support Ticket
              </Typography>
            </Box>
            <IconButton
              aria-label="close"
              onClick={() => setContactDialogOpen(false)}
              sx={{
                position: "absolute",
                right: 16,
                top: 16,
                color: "text.secondary",
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label="Subject"
                value={newTicket.subject}
                onChange={(e) =>
                  setNewTicket({ ...newTicket, subject: e.target.value })
                }
                margin="normal"
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                  },
                }}
              />
              <TextField
                fullWidth
                label="Description"
                multiline
                rows={4}
                value={newTicket.description}
                onChange={(e) =>
                  setNewTicket({ ...newTicket, description: e.target.value })
                }
                margin="normal"
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                  },
                }}
              />
            </Box>
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <Button
              onClick={() => setContactDialogOpen(false)}
              sx={{
                borderRadius: "8px",
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmitTicket}
              variant="contained"
              startIcon={<SendIcon />}
              sx={{
                borderRadius: "8px",
                textTransform: "none",
                px: 3,
              }}
            >
              Submit Ticket
            </Button>
          </DialogActions>
        </Dialog>

        {/* Modern Ticket Detail Dialog */}
        <Dialog
          open={!!selectedTicket}
          onClose={() => setSelectedTicket(null)}
          maxWidth="md"
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: "24px",
              p: 2,
            },
          }}
        >
          {selectedTicket && (
            <>
              <DialogTitle>
                <Box display="flex" alignItems="center">
                  <QuestionAnswerIcon sx={{ mr: 2, color: "primary.main" }} />
                  <Typography
                    variant="h5"
                    component="span"
                    sx={{ fontWeight: 600 }}
                  >
                    Ticket Details
                  </Typography>
                </Box>
                <IconButton
                  aria-label="close"
                  onClick={() => setSelectedTicket(null)}
                  sx={{
                    position: "absolute",
                    right: 16,
                    top: 16,
                    color: "text.secondary",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Box sx={{ mb: 3 }}>
                  <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
                    {selectedTicket.subject}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {selectedTicket.description}
                  </Typography>
                </Box>
                <Divider sx={{ mb: 3 }} />
                <Box sx={{ mb: 3 }}>
                  {selectedTicket.messages?.map((message, index) => (
                    <Paper
                      key={index}
                      elevation={0}
                      sx={{
                        p: 3,
                        mb: 2,
                        borderRadius: "16px",
                        backgroundColor: message.isStaff
                          ? "#f8f9fa"
                          : "rgba(33, 150, 243, 0.08)",
                        border: "1px solid",
                        borderColor: message.isStaff
                          ? "rgba(0, 0, 0, 0.08)"
                          : "rgba(33, 150, 243, 0.16)",
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                      }}
                    >
                      <Box display="flex" alignItems="center" mb={1}>
                        <Box
                          sx={{
                            width: 32,
                            height: 32,
                            borderRadius: "50%",
                            backgroundColor: message.isStaff
                              ? "grey.200"
                              : "primary.light",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            mr: 2,
                          }}
                        >
                          {message.isStaff ? (
                            <ContactSupportIcon
                              sx={{ color: "text.secondary", fontSize: 20 }}
                            />
                          ) : (
                            <ChatIcon
                              sx={{ color: "primary.main", fontSize: 20 }}
                            />
                          )}
                        </Box>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontWeight: 500,
                            color: message.isStaff
                              ? "text.secondary"
                              : "primary.main",
                          }}
                        >
                          {message.isStaff ? "Support Team" : "You"}
                        </Typography>
                      </Box>
                      <Typography variant="body1" sx={{ ml: 6 }}>
                        {message.message}
                      </Typography>
                      <Box display="flex" alignItems="center" sx={{ ml: 6 }}>
                        <ScheduleIcon
                          sx={{
                            fontSize: 16,
                            color: "text.secondary",
                            mr: 0.5,
                          }}
                        />
                        <Typography variant="caption" color="text.secondary">
                          {new Date(message.createdAt).toLocaleString()}
                        </Typography>
                      </Box>
                    </Paper>
                  ))}
                </Box>
                <Box sx={{ mt: 4 }}>
                  <TextField
                    fullWidth
                    label="Reply to ticket"
                    multiline
                    rows={3}
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    variant="outlined"
                    placeholder="Type your message here..."
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "12px",
                        backgroundColor: "white",
                      },
                    }}
                  />
                </Box>
              </DialogContent>
              <DialogActions sx={{ p: 3 }}>
                <Button
                  onClick={() => setSelectedTicket(null)}
                  sx={{
                    borderRadius: "8px",
                    textTransform: "none",
                  }}
                >
                  Close
                </Button>
                <Button
                  onClick={() => handleAddMessage(selectedTicket.id)}
                  variant="contained"
                  color="primary"
                  startIcon={<SendIcon />}
                  disabled={!newMessage.trim()}
                  sx={{
                    borderRadius: "8px",
                    textTransform: "none",
                    px: 3,
                    "&.Mui-disabled": {
                      backgroundColor: "rgba(33, 150, 243, 0.12)",
                    },
                  }}
                >
                  Send Reply
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>

        {/* Modern Error Alert */}
        {error && (
          <Alert
            severity="error"
            onClose={() => setError("")}
            icon={<ErrorIcon />}
            sx={{
              position: "fixed",
              bottom: 16,
              right: 16,
              borderRadius: "12px",
              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
              alignItems: "center",
              width: { xs: "calc(100% - 32px)", sm: "auto" },
              maxWidth: { xs: "none", sm: "400px" },
              zIndex: 9999,
              "& .MuiAlert-icon": {
                fontSize: 24,
              },
              "& .MuiAlert-action": {
                pt: 0,
              },
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setError("")}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {error}
          </Alert>
        )}

        {/* Loading Overlay */}
        {loading && (
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 9999,
            }}
          >
            <CircularProgress size={48} />
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default HelpCenter;
