import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Typography,
  Container,
  Box,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Switch,
  Tooltip,
  Select,
  FormControlLabel,
  CircularProgress,
  Divider,
  Paper,
  IconButton,
  useTheme,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  LocalizationProvider,
  DatePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { auth, db } from "../services/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { updateProfile } from "firebase/auth";
import PlatformMenu from "../components/PlatformMenu";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const Profile: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState("");
  const [isVet, setIsVet] = useState(false);
  const [specialization, setSpecialization] = useState("");
  const [bio, setBio] = useState("");
  const [location, setLocation] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [passportNumber, setPassportNumber] = useState("");
  const [gender, setGender] = useState("");
  const [nickname, setNickname] = useState("");
  const [socialMedia, setSocialMedia] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "success"
  );
  const [birthdate, setBirthdate] = useState("");

  const [availableTimeSlots, setAvailableTimeSlots] = useState<
    { date: string; startTime: string; endTime: string }[]
  >([]);
  const [serviceFee, setServiceFee] = useState("");
  const [vetLocation, setVetLocation] = useState("");

  const user = auth.currentUser;

  const haitiDepartments = [
    "Artibonite",
    "Centre",
    "Grand'Anse",
    "Nippes",
    "Nord",
    "Nord-Est",
    "Nord-Ouest",
    "Ouest",
    "Sud",
    "Sud-Est",
    "Not in Haiti",
  ];

  const showAlert = (message: string, severity: "success" | "error") => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };

  useEffect(() => {
    if (user) {
      const fetchUserData = async () => {
        try {
          const userRef = doc(db, "users", user.uid);
          const userDoc = await getDoc(userRef);
          if (userDoc.exists()) {
            const data = userDoc.data();
            setUsername(data.username || "");
            setBio(data.bio || "");
            setLocation(data.location || "");
            setBirthdate(data.birthdate || "");
            setPhoneNumber(data.phoneNumber || "");
            setPassportNumber(data.passportNumber || "");
            setGender(data.gender || "");
            setNickname(data.nickname || "");
            setSocialMedia(data.socialMedia || "");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchUserData();
    }
  }, [user]);

  const handleAddTimeSlot = () => {
    setAvailableTimeSlots([
      ...availableTimeSlots,
      { date: "", startTime: "", endTime: "" },
    ]);
  };

  const handleTimeSlotChange = (
    index: number,
    key: "date" | "startTime" | "endTime",
    value: Dayjs | null
  ) => {
    const updatedSlots = [...availableTimeSlots];
    updatedSlots[index][key] = value
      ? value.format(key === "date" ? "YYYY-MM-DD" : "HH:mm")
      : "";
    setAvailableTimeSlots(updatedSlots);
  };

  const handleRemoveTimeSlot = (index: number) => {
    const updatedSlots = availableTimeSlots.filter((_, i) => i !== index);
    setAvailableTimeSlots(updatedSlots);
  };
  const handleSaveProfile = async () => {
    if (!username.trim()) {
      showAlert("Username is required.", "error");
      return;
    }
    if (!bio.trim()) {
      showAlert("Bio is required.", "error");
      return;
    }
    if (!birthdate) {
      showAlert("Birthdate is required.", "error");
      return;
    }
    if (!gender.trim()) {
      showAlert("Gender is required.", "error");
      return;
    }
    if (!phoneNumber.trim()) {
      showAlert("Phone Number is required.", "error");
      return;
    }
    if (!location.trim()) {
      showAlert("Location is required.", "error");
      return;
    }

    try {
      setLoading(true);

      const userRef = doc(db, "users", user?.uid || "");

      const updatedData = {
        username,
        bio,
        location,
        birthdate,
        phoneNumber,
        passportNumber,
        gender,
        nickname,
        socialMedia,
        isVet,
        role: isVet ? "vet" : "user",
        specialization,
        availableTimeSlots,
        serviceFee,
        vetLocation,
      };

      await setDoc(userRef, updatedData, { merge: true });

      if (user) {
        await updateProfile(user, { displayName: username });
      }

      showAlert("Profile updated successfully!", "success");
    } catch (error) {
      console.error("Error saving profile:", error);
      showAlert(
        "An error occurred while saving the profile. Please try again.",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const theme = useTheme();

  const paperStyles = {
    backgroundColor: "#ffffff",
    borderRadius: 2,
    boxShadow: "none",
    border: "1px solid #eaeaea",
  };

  const textFieldStyles = {
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      backgroundColor: "#ffffff",
      "&:hover": {
        "& > fieldset": { borderColor: theme.palette.primary.main },
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "14px",
    },
    marginBottom: 2,
  };

  const textFieldStylesRequired = {
    ...textFieldStyles,
    "& label.Mui-focused": {
      color: theme.palette.error.main,
    },
    "& .MuiOutlinedInput-root": {
      borderColor: theme.palette.error.main,
      "&:hover fieldset": {
        borderColor: theme.palette.error.main,
      },
    },
  };

  const buttonStyles = {
    textTransform: "none",
    borderRadius: "8px",
    padding: "10px 24px",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress size={40} thickness={4} />
      </Box>
    );
  }

  return (
    <Box sx={{ bgcolor: "#f5f5f7", minHeight: "100vh", pb: 8 }}>
      <PlatformMenu />
      <Container maxWidth="md" sx={{ pt: 6 }}>
        <Typography
          variant="h4"
          sx={{
            mb: 4,
            fontWeight: 600,
            color: "#1a1a1a",
            textAlign: "center",
          }}
        >
          Profile Settings
        </Typography>

        <Paper sx={{ ...paperStyles }}>
          <Box sx={{ p: 4 }}>
            {/* Basic Information Section */}
            <Typography variant="h6" sx={{ mb: 3, fontWeight: 500 }}>
              Basic Information
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                  sx={textFieldStylesRequired}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Nickname"
                  value={nickname}
                  onChange={(e) => setNickname(e.target.value)}
                  sx={textFieldStyles}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Bio"
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                  sx={textFieldStyles}
                />
              </Grid>
            </Grid>

            <Divider sx={{ my: 4 }} />

            {/* Personal Details Section */}
            <Typography variant="h6" sx={{ mb: 3, fontWeight: 500 }}>
              Personal Details
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="date"
                  label="Birthdate"
                  value={birthdate}
                  onChange={(e) => setBirthdate(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  required
                  sx={textFieldStylesRequired}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Gender</InputLabel>
                  <Select
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    fullWidth
                    sx={textFieldStyles}
                  >
                    <MenuItem value="">Prefer not to say</MenuItem>
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Divider sx={{ my: 4 }} />

            {/* Contact Information and Location Information Section */}
            <Grid container spacing={3}>
              {/* Contact Information */}
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" sx={{ mb: 3, fontWeight: 500 }}>
                  Contact Information
                </Typography>
                <TextField
                  fullWidth
                  label="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                  sx={textFieldStylesRequired}
                />
                <TextField
                  fullWidth
                  label="Social Media Links"
                  value={socialMedia}
                  onChange={(e) => setSocialMedia(e.target.value)}
                  sx={textFieldStyles}
                />
              </Grid>

              {/* Location Information */}
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" sx={{ mb: 3, fontWeight: 500 }}>
                  Location Information
                </Typography>
                <FormControl fullWidth required sx={textFieldStylesRequired}>
                  <InputLabel>Location</InputLabel>
                  <Select
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    fullWidth
                  >
                    <MenuItem value="">Select Location</MenuItem>
                    {haitiDepartments.map((department) => (
                      <MenuItem key={department} value={department}>
                        {department}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {location === "None" && (
                  <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                    Location must be selected.
                  </Typography>
                )}
              </Grid>
              {/* Email Display (Read-only) */}
              {/* Email Display (Read-only with Tooltip) */}
              <Grid item xs={12}>
                <Tooltip title="Email address is unchangeable" arrow>
                  <TextField
                    fullWidth
                    label="Email"
                    value={user?.email || "Not available"}
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      ...textFieldStyles,
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "#f9f9f9",
                        cursor: "not-allowed",
                      },
                    }}
                  />
                </Tooltip>
              </Grid>
            </Grid>

            {/* Divider */}
            <Divider sx={{ my: 4 }} />

            {/* Vet Account Section */}
            <Box sx={{ mt: 4 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isVet}
                    onChange={() => setIsVet(!isVet)}
                    sx={{
                      "& .MuiSwitch-switchBase.Mui-checked": {
                        color: theme.palette.primary.main,
                      },
                    }}
                  />
                }
                label={
                  <Typography sx={{ fontWeight: 500 }}>
                    Veterinarian Account
                  </Typography>
                }
              />
            </Box>

            {isVet && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="h6" sx={{ mb: 3, fontWeight: 500 }}>
                  Professional Information
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Specialization"
                      value={specialization}
                      onChange={(e) => setSpecialization(e.target.value)}
                      sx={textFieldStyles}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Service Fee"
                      value={serviceFee}
                      onChange={(e) => setServiceFee(e.target.value)}
                      sx={textFieldStyles}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Practice Location"
                      value={vetLocation}
                      onChange={(e) => setVetLocation(e.target.value)}
                      sx={textFieldStyles}
                    />
                  </Grid>
                </Grid>

                <Typography variant="h6" sx={{ mt: 4, mb: 3, fontWeight: 500 }}>
                  Availability
                </Typography>
                <Box sx={{ mb: 2 }}>
                  {availableTimeSlots.map((slot, index) => (
                    <Paper
                      key={index}
                      sx={{
                        p: 2,
                        mb: 2,
                        ...paperStyles,
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Date"
                          value={dayjs(slot.date)}
                          onChange={(newValue) =>
                            handleTimeSlotChange(index, "date", newValue)
                          }
                          sx={{ flex: 1 }}
                        />
                        <TimePicker
                          label="Start Time"
                          value={dayjs(slot.startTime, "HH:mm")}
                          onChange={(newValue) =>
                            handleTimeSlotChange(index, "startTime", newValue)
                          }
                          sx={{ flex: 1 }}
                        />
                        <TimePicker
                          label="End Time"
                          value={dayjs(slot.endTime, "HH:mm")}
                          onChange={(newValue) =>
                            handleTimeSlotChange(index, "endTime", newValue)
                          }
                          sx={{ flex: 1 }}
                        />
                      </LocalizationProvider>
                      <IconButton
                        onClick={() => handleRemoveTimeSlot(index)}
                        sx={{ color: theme.palette.error.main }}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Paper>
                  ))}
                  <Button
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={handleAddTimeSlot}
                    sx={{
                      ...buttonStyles,
                      mt: 2,
                    }}
                  >
                    Add Time Slot
                  </Button>
                </Box>
              </Box>
            )}

            <Box sx={{ mt: 4 }}>
              <Button
                variant="contained"
                fullWidth
                onClick={handleSaveProfile}
                sx={{
                  ...buttonStyles,
                  backgroundColor: theme.palette.primary.main,
                  color: "#ffffff",
                  fontSize: "1rem",
                  fontWeight: 500,
                }}
              >
                Save Changes
              </Button>
            </Box>
          </Box>
        </Paper>
      </Container>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => setAlertOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setAlertOpen(false)}
          severity={alertSeverity}
          sx={{
            width: "100%",
            backgroundColor:
              alertSeverity === "success" ? "#4caf50" : "#f44336",
            borderRadius: "8px",
            color: "#fff",
            fontWeight: "500",
          }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Profile;
