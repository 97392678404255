import { createContext, useContext, useState, ReactNode } from "react";

interface AlertContextType {
  alertMessage: string;
  alertSeverity: "success" | "error";
  setAlert: (message: string, severity: "success" | "error") => void;
  clearAlert: () => void;
}

const AlertContext = createContext<AlertContextType | undefined>(undefined);

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error("useAlert must be used within an AlertProvider");
  }
  return context;
};

export const AlertProvider = ({ children }: { children: ReactNode }) => {
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "success"
  );

  const setAlert = (message: string, severity: "success" | "error") => {
    setAlertMessage(message);
    setAlertSeverity(severity);
  };

  const clearAlert = () => {
    setAlertMessage("");
    setAlertSeverity("success");
  };

  return (
    <AlertContext.Provider
      value={{ alertMessage, alertSeverity, setAlert, clearAlert }}
    >
      {children}
    </AlertContext.Provider>
  );
};
