import { useState, useEffect, useRef } from "react";
import {
  collection,
  query as firestoreQuery,
  where,
  getDocs,
  addDoc,
  orderBy,
  onSnapshot,
  serverTimestamp,
  doc,
  setDoc,
} from "firebase/firestore";
import { db, auth } from "../services/firebase";
import {
  Box,
  Container,
  TextField,
  List,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Paper,
  InputBase,
  IconButton,
  Divider,
  CircularProgress,
  Tooltip,
  Grid,
} from "@mui/material";
import {
  Search as SearchIcon,
  Send as SendIcon,
  Person as PersonIcon,
  ContentCopy as ContentCopyIcon,
} from "@mui/icons-material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import PlatformMenu from "../components/PlatformMenu";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#00b894",
    },
    background: {
      default: "#1e272e",
      paper: "#2f3640",
    },
    text: {
      primary: "#ffffff",
      secondary: "#dcdde1",
    },
  },
  typography: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    button: {
      textTransform: "none",
    },
  },
});

interface User {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
}

interface Message {
  id: string;
  senderId: string;
  text: string;
  timestamp: any;
}

interface Chat {
  id: string;
  participants: string[];
  lastMessage?: string;
  timestamp: any;
  otherUser: User;
}

const Messenger = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<User[]>([]);
  const [selectedChatId, setSelectedChatId] = useState<string | null>(null);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState<Message[]>([]);
  const [chats, setChats] = useState<Chat[]>([]);
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const currentUser = auth.currentUser;

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const searchUsers = async (query: string) => {
    if (!query || query.length < 2) {
      setSearchResults([]);
      return;
    }

    setLoading(true);
    try {
      const usersRef = collection(db, "users");
      const q = firestoreQuery(
        usersRef,
        where("username", ">=", query.toLowerCase()),
        where("username", "<=", query.toLowerCase() + "\uf8ff"),
        orderBy("username")
      );
      const querySnapshot = await getDocs(q);

      const results: User[] = querySnapshot.docs
        .map((doc) => ({
          id: doc.id,
          username: doc.data().username || "",
          firstName: doc.data().firstName || "",
          lastName: doc.data().lastName || "",
        }))
        .filter((user) => user.id !== currentUser?.uid);

      setSearchResults(results);
    } catch (error) {
      console.error("Error searching users:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!currentUser) return;

    const chatsRef = collection(db, "chats");
    const q = firestoreQuery(
      chatsRef,
      where("participants", "array-contains", currentUser.uid),
      orderBy("timestamp", "desc")
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const chatsData: Chat[] = snapshot.docs.map((doc) => {
        const chatData = doc.data();
        return {
          id: doc.id,
          participants: chatData.participants,
          lastMessage: chatData.lastMessage,
          timestamp: chatData.timestamp,
          otherUser: chatData.otherUser,
        } as Chat;
      });

      setChats(chatsData);
    });

    return () => unsubscribe();
  }, [currentUser]);

  useEffect(() => {
    if (!selectedChatId) return;

    const messagesRef = collection(db, "chats", selectedChatId, "messages");
    const q = firestoreQuery(messagesRef, orderBy("timestamp", "asc"));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const messagesData: Message[] = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Message[];

      setMessages(messagesData);
    });

    return () => unsubscribe();
  }, [selectedChatId]);

  const selectChat = async (user: User) => {
    const chatId = [currentUser?.uid, user.id].sort().join("_");
    setSelectedChatId(chatId);
    setSelectedUser(user);
  };

  const sendMessage = async () => {
    if (!message.trim() || !currentUser || !selectedChatId || !selectedUser)
      return;

    try {
      const chatRef = doc(db, "chats", selectedChatId);
      const messagesRef = collection(chatRef, "messages");

      await addDoc(messagesRef, {
        senderId: currentUser.uid,
        text: message,
        timestamp: serverTimestamp(),
      });

      await setDoc(
        chatRef,
        {
          participants: [currentUser.uid, selectedUser.id].sort(),
          lastMessage: message,
          timestamp: serverTimestamp(),
          otherUser: {
            id: selectedUser.id,
            username: selectedUser.username,
            firstName: selectedUser.firstName,
            lastName: selectedUser.lastName,
          },
        },
        { merge: true }
      );

      setMessage("");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <div>
      <PlatformMenu />
      <ThemeProvider theme={darkTheme}>
        <Container maxWidth="lg" sx={{ height: "90vh", py: 2 }}>
          <Grid container spacing={2} sx={{ height: "90%" }}>
            {/* Sidebar */}
            <Grid item xs={12} md={4}>
              <Paper
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Box p={2} borderBottom={1} borderColor="divider">
                  {currentUser && (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography variant="subtitle1">
                        @{currentUser.displayName || "User"}
                      </Typography>
                      <Tooltip title="Copy Username">
                        <IconButton
                          onClick={() =>
                            navigator.clipboard.writeText(
                              currentUser.displayName || ""
                            )
                          }
                        >
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </Box>

                <Box p={2}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search users..."
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                      searchUsers(e.target.value);
                    }}
                    InputProps={{
                      startAdornment: <SearchIcon color="action" />,
                    }}
                  />
                </Box>

                <List sx={{ overflow: "auto", flexGrow: 1 }}>
                  {loading && (
                    <Box display="flex" justifyContent="center" p={2}>
                      <CircularProgress />
                    </Box>
                  )}

                  {searchQuery
                    ? searchResults.map((user) => (
                        <ListItemButton
                          key={user.id}
                          onClick={() => selectChat(user)}
                        >
                          <ListItemAvatar>
                            <Avatar>
                              <PersonIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={`${user.firstName} ${user.lastName}`}
                            secondary={user.username}
                          />
                        </ListItemButton>
                      ))
                    : chats.map((chat) => (
                        <ListItemButton
                          key={chat.id}
                          onClick={() => selectChat(chat.otherUser)}
                        >
                          <ListItemAvatar>
                            <Avatar>
                              <PersonIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={`${chat.otherUser.firstName} ${chat.otherUser.lastName}`}
                            secondary={chat.lastMessage}
                          />
                        </ListItemButton>
                      ))}
                </List>
              </Paper>
            </Grid>

            {/* Chat Area */}
            <Grid item xs={12} md={8}>
              <Paper
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                {selectedUser ? (
                  <>
                    <Box p={2} borderBottom={1} borderColor="divider">
                      <Typography variant="h6">
                        {selectedUser.firstName} {selectedUser.lastName}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        @{selectedUser.username}
                      </Typography>
                    </Box>

                    <Box p={2} sx={{ flexGrow: 1, overflow: "auto" }}>
                      {messages.map((msg) => (
                        <Box
                          key={msg.id}
                          sx={{
                            display: "flex",
                            justifyContent:
                              msg.senderId === currentUser?.uid
                                ? "flex-end"
                                : "flex-start",
                            mb: 1,
                          }}
                        >
                          <Paper
                            sx={{
                              p: 1,
                              maxWidth: "70%",
                              bgcolor:
                                msg.senderId === currentUser?.uid
                                  ? "primary.main"
                                  : "grey.100",
                              color:
                                msg.senderId === currentUser?.uid
                                  ? "white"
                                  : "inherit",
                            }}
                          >
                            <Typography>{msg.text}</Typography>
                          </Paper>
                        </Box>
                      ))}
                      <div ref={messagesEndRef} />
                    </Box>

                    <Divider />

                    <Box p={2} display="flex">
                      <InputBase
                        fullWidth
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Type a message"
                        sx={{ mr: 2 }}
                      />
                      <IconButton onClick={sendMessage} color="primary">
                        <SendIcon />
                      </IconButton>
                    </Box>
                  </>
                ) : (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ height: "100%" }}
                  >
                    <Typography variant="h5" color="textSecondary">
                      Select a chat to start messaging
                    </Typography>
                  </Box>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default Messenger;
