import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { auth, db } from "../services/firebase";
import {
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore";
import {
  Button,
  Typography,
  Box,
  Container,
  Grid,
  Card,
  CardContent,
  Snackbar,
  Alert,
  CircularProgress,
  IconButton,
  Paper,
} from "@mui/material";
import { signOut } from "firebase/auth";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import LogoutIcon from "@mui/icons-material/LogoutOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PetsOutlinedIcon from "@mui/icons-material/PetsOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import { useAlert } from "../components/AlertContext";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";

const Dashboard: React.FC = () => {
  const [userData, setUserData] = useState<any>(null);
  const [animals, setAnimals] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { alertMessage, alertSeverity, clearAlert } = useAlert();

  const fetchAnimals = async (userId: string) => {
    try {
      const animalsQuery = query(
        collection(db, "animals"),
        where("userId", "==", userId)
      );
      const querySnapshot = await getDocs(animalsQuery);
      const userAnimals = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAnimals(userAnimals);
    } catch (error) {
      console.error("Error fetching animals:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAnimal = async (animalId: string) => {
    try {
      await deleteDoc(doc(db, "animals", animalId));
      if (auth.currentUser) {
        fetchAnimals(auth.currentUser.uid);
      }
    } catch (error) {
      console.error("Error deleting animal:", error);
    }
  };

  const handleRegisterNewAnimal = () => {
    navigate("/register");
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (auth.currentUser) {
        const user = auth.currentUser;
        setUserData(user);
        fetchAnimals(user.uid);
      } else {
        navigate("/login");
      }
    };
    fetchUserData();
  }, [navigate]);

  useEffect(() => {
    if (location.state?.newAnimalRegistered) {
      setSnackbarMessage("New animal successfully registered!");
      setSnackbarOpen(true);
      if (auth.currentUser) {
        fetchAnimals(auth.currentUser.uid);
      }
    }
  }, [location.state]);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const navigationItems = [
    { text: "Profile", icon: <PersonOutlineIcon />, path: "profile" },
    { text: "Market", icon: <StorefrontOutlinedIcon />, path: "market" },
    { text: "Vets", icon: <LocalHospitalOutlinedIcon />, path: "vets" },
    { text: "Forum", icon: <ForumOutlinedIcon />, path: "forum" },
    {
      text: "Knowledge Base",
      icon: <MenuBookOutlinedIcon />,
      path: "knowledge-base",
    },
    { text: "About", icon: <InfoOutlinedIcon />, path: "about" },
    { text: "Feed", icon: <PetsOutlinedIcon />, path: "feed" },
    { text: "Help", icon: <HelpOutlineOutlinedIcon />, path: "help" },
    { text: "Vacancy", icon: <WorkOutlineOutlinedIcon />, path: "vacancy" },
  ];

  return (
    <div>
      {" "}
      <Snackbar
        open={alertMessage !== ""}
        autoHideDuration={6000}
        onClose={clearAlert}
      >
        <Alert
          onClose={clearAlert}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      <Container maxWidth="lg" sx={{ py: 6 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            gap: 6,
          }}
        >
          {/* Header Section */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: 300,
                color: "text.primary",
                letterSpacing: "-0.5px",
              }}
            >
              Welcome back
              <Typography
                component="span"
                variant="h4"
                sx={{
                  fontWeight: 600,
                  color: "primary.main",
                  ml: 1,
                }}
              >
                {userData?.username || userData?.email?.split("@")[0] || "User"}
              </Typography>
            </Typography>

            <IconButton
              onClick={handleSignOut}
              sx={{
                color: "text.secondary",
                "&:hover": { color: "primary.main" },
              }}
            >
              <LogoutIcon />
            </IconButton>
          </Box>

          {/* Navigation Cards */}
          <Paper
            elevation={0}
            sx={{
              backgroundColor: "grey.50",
              borderRadius: 4,
              p: 3,
            }}
          >
            <Grid container spacing={2}>
              {navigationItems.map((item) => (
                <Grid item xs={12} sm={6} md={4} key={item.text}>
                  <Button
                    variant="text"
                    onClick={() => navigate(`/${item.path}`)}
                    sx={{
                      width: "100%",
                      height: "80px",
                      backgroundColor: "background.paper",
                      borderRadius: 2,
                      textTransform: "none",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 1,
                      color: "text.primary",
                      transition: "all 0.2s",
                      "&:hover": {
                        backgroundColor: "primary.main",
                        color: "white",
                        transform: "translateY(-2px)",
                        "& .MuiSvgIcon-root": {
                          color: "white",
                        },
                      },
                    }}
                  >
                    {React.cloneElement(item.icon, {
                      sx: {
                        color: "primary.main",
                        transition: "color 0.2s",
                      },
                    })}
                    <Typography variant="body1" fontWeight={500}>
                      {item.text}
                    </Typography>
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Paper>

          {/* Animals Section */}
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" }, 
                justifyContent: "space-between",
                alignItems: "center",
                mb: 3,
                gap: { xs: 2, sm: 0 }, 
              }}
            >
              {/* Text Section */}
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 400,
                  color: "text.primary",
                  flex: 1, 
                }}
              >
                Your Animals{" "}
              </Typography>

              {/* Buttons Section */}
              <Box
                sx={{
                  display: "flex",
                  gap: 2, 
                  flexWrap: "wrap",
                  justifyContent: "flex-end", 
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleRegisterNewAnimal}
                  startIcon={<AddCircleOutlineIcon />}
                  sx={{
                    textTransform: "none",
                    borderRadius: 2,
                    px: 3,
                    py: 1,
                    backgroundColor: "primary.main",
                    "&:hover": {
                      backgroundColor: "primary.dark",
                    },
                  }}
                >
                  Add New Animal
                </Button>

                <Button
                  variant="contained"
                  startIcon={<GpsFixedIcon />}
                  onClick={() => navigate("/tracking")}
                  sx={{
                    textTransform: "none",
                    borderRadius: 2,
                    px: 3,
                    py: 1,
                    backgroundColor: "warning.main",
                    "&:hover": {
                      backgroundColor: "warning.secondary",
                    },
                  }}
                >
                  Track Animal Location
                </Button>
              </Box>
            </Box>

            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center", py: 4 }}>
                <CircularProgress size={40} thickness={4} />
              </Box>
            ) : animals && animals.length > 0 ? (
              <Grid container spacing={3}>
                {animals.map((animal) => (
                  <Grid item xs={12} sm={6} md={4} key={animal.id}>
                    <Card
                      elevation={0}
                      sx={{
                        borderRadius: 2,
                        border: "1px solid",
                        borderColor: "grey.100",
                        transition: "all 0.2s",
                        "&:hover": {
                          borderColor: "primary.main",
                          transform: "translateY(-2px)",
                        },
                      }}
                    >
                      <CardContent sx={{ p: 3 }}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: 500,
                            mb: 0.5,
                          }}
                        >
                          {animal.name}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "text.secondary",
                            mb: 2,
                          }}
                        >
                          {animal.species}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                          }}
                        >
                          <Button
                            variant="contained"
                            startIcon={<VisibilityOutlinedIcon />}
                            onClick={() => navigate(`/passport/${animal.id}`)}
                            sx={{
                              flex: 1,
                              textTransform: "none",
                              borderRadius: 1.5,
                            }}
                          >
                            View Passport
                          </Button>
                          <IconButton
                            onClick={() => handleDeleteAnimal(animal.id)}
                            sx={{
                              color: "error.main",
                              border: "1px solid",
                              borderColor: "error.main",
                              borderRadius: 1.5,
                              "&:hover": {
                                backgroundColor: "error.main",
                                color: "white",
                              },
                            }}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Paper
                elevation={0}
                sx={{
                  textAlign: "center",
                  py: 6,
                  backgroundColor: "grey.50",
                  borderRadius: 4,
                }}
              >
                <Typography color="text.secondary">
                  No animals registered yet. Add your first animal to get
                  started.
                </Typography>
              </Paper>
            )}
          </Box>
        </Box>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{
              width: "100%",
              borderRadius: 2,
            }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
};

export default Dashboard;
