import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Switch,
  FormControlLabel,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { db } from "../../services/firebase";

interface Vacancy {
  id: string;
  company_logo: string;
  company_name: string;
  job_title: string;
  location: string;
  job_type: string;
  salary_range: string;
  remote_work: boolean;
  job_description: string;
  job_requirements: string;
  job_responsibilities: string;
  contact_name: string;
  contact_email: string;
  contact_phone: string;
  benefits?: string;
  company_culture?: string;
}

type FormData = Omit<Vacancy, "id">;

const VacancyManagement: React.FC = () => {
  const [vacancies, setVacancies] = useState<Vacancy[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    company_logo: "",
    company_name: "",
    job_title: "",
    location: "",
    job_type: "",
    salary_range: "",
    remote_work: false,
    job_description: "",
    job_requirements: "",
    job_responsibilities: "",
    contact_name: "",
    contact_email: "",
    contact_phone: "",
    benefits: "",
    company_culture: "",
  });
  const [editingId, setEditingId] = useState<string | null>(null);

  useEffect(() => {
    const fetchVacancies = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "vacancies"));
        const fetchedVacancies = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Vacancy[];
        setVacancies(fetchedVacancies);
      } catch (error) {
        console.error("Error fetching vacancies:", error);
      }
    };
    fetchVacancies();
  }, []);

  const openAddEditDialog = (vacancy?: Vacancy) => {
    if (vacancy) {
      setEditingId(vacancy.id);
      setFormData({
        ...vacancy,
      });
    } else {
      setEditingId(null);
      setFormData({
        company_logo: "",
        company_name: "",
        job_title: "",
        location: "",
        job_type: "",
        salary_range: "",
        remote_work: false,
        job_description: "",
        job_requirements: "",
        job_responsibilities: "",
        contact_name: "",
        contact_email: "",
        contact_phone: "",
        benefits: "",
        company_culture: "",
      });
    }
    setOpenDialog(true);
  };

  const handleSave = async () => {
    try {
      if (editingId) {
        const vacancyRef = doc(db, "vacancies", editingId);
        await updateDoc(vacancyRef, formData);
      } else {
        await addDoc(collection(db, "vacancies"), formData);
      }
      setOpenDialog(false);
      setEditingId(null);
      refreshVacancies();
    } catch (error) {
      console.error("Error saving vacancy:", error);
    }
  };

  const refreshVacancies = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "vacancies"));
      const updatedVacancies = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Vacancy[];
      setVacancies(updatedVacancies);
    } catch (error) {
      console.error("Error refreshing vacancies:", error);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteDoc(doc(db, "vacancies", id));
      refreshVacancies();
    } catch (error) {
      console.error("Error deleting vacancy:", error);
    }
  };

  const handleInputChange = (
    field: keyof FormData,
    value: string | boolean
  ) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <Box p={4}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => openAddEditDialog()}
        sx={{ mb: 2 }}
      >
        Add New Vacancy
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Job Title</TableCell>
              <TableCell>Company Name</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Job Type</TableCell>
              <TableCell>Salary Range</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vacancies.map((vacancy) => (
              <TableRow key={vacancy.id}>
                <TableCell>{vacancy.job_title}</TableCell>
                <TableCell>{vacancy.company_name}</TableCell>
                <TableCell>{vacancy.location}</TableCell>
                <TableCell>{vacancy.job_type}</TableCell>
                <TableCell>{vacancy.salary_range}</TableCell>
                <TableCell>
                  <IconButton onClick={() => openAddEditDialog(vacancy)}>
                    <EditIcon color="primary" />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(vacancy.id)}>
                    <DeleteIcon color="error" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{editingId ? "Edit Vacancy" : "Add Vacancy"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {Object.entries(formData).map(([field, value]) => (
              <Grid item xs={12} key={field}>
                {field === "remote_work" ? (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!!value}
                        onChange={(e) =>
                          handleInputChange(
                            field as keyof FormData,
                            e.target.checked
                          )
                        }
                      />
                    }
                    label="Remote Work"
                  />
                ) : (
                  <TextField
                    label={field.replace("_", " ").toUpperCase()}
                    fullWidth
                    value={value as string}
                    onChange={(e) =>
                      handleInputChange(field as keyof FormData, e.target.value)
                    }
                    multiline={[
                      "job_description",
                      "job_requirements",
                      "job_responsibilities",
                    ].includes(field)}
                    rows={
                      [
                        "job_description",
                        "job_requirements",
                        "job_responsibilities",
                      ].includes(field)
                        ? 4
                        : 1
                    }
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default VacancyManagement;
