import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
  IconButton,
  Avatar,
  CardActions,
  Collapse,
  Paper,
  Chip,
  Divider,
  useTheme,
  alpha,
} from "@mui/material";
import {
  Favorite as FavoriteIcon,
  FavoriteBorder as FavoriteBorderIcon,
  ThumbDown as ThumbDownIcon,
  ChatBubbleOutline as CommentIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  EmojiEmotions as EmojiIcon,
  Send as SendIcon,
  Close as CloseIcon,
  Timeline as TimelineIcon,
  Tag as TagIcon,
  Image as ImageIcon,
} from "@mui/icons-material";
import { auth, db } from "../services/firebase";
import {
  collection,
  addDoc,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
  query,
  orderBy,
  serverTimestamp,
  arrayUnion,
  Timestamp,
} from "firebase/firestore";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import Picker from "emoji-picker-react";
import PlatformMenu from "../components/PlatformMenu";

dayjs.extend(relativeTime);








interface Tweet {
  id: string;
  userId: string;
  username: string;
  text: string;
  hashtags?: string[];
  imageUrl?: string;
  timestamp: any;
  likes: string[];
  dislikes: string[];
  comments: Comment[];
}

interface Comment {
  id: string;
  userId: string;
  username: string;
  text: string;
  timestamp: any;
}

const Feed: React.FC = () => {
  const [tweets, setTweets] = useState<Tweet[]>([]);
  const [text, setText] = useState<string>("");
  const [hashtags, setHashtags] = useState<string>("");
  const [imageUrl, setImageUrl] = useState<string>("");
  const [expandedTweet, setExpandedTweet] = useState<string | null>(null);
  const [emojiPickerVisible, setEmojiPickerVisible] = useState<boolean>(false);
  const [emojiTarget] = useState<"tweet" | "comment">("tweet");
  const [rulesVisible, setRulesVisible] = useState<boolean>(true);
  const [commentTextMap, setCommentTextMap] = useState<{
    [tweetId: string]: string;
  }>({});
  const [editingTweetId, setEditingTweetId] = useState<string | null>(null);
  const [editText, setEditText] = useState<string>("");

  const theme = useTheme();
  const currentUser = auth.currentUser;

  useEffect(() => {
    const tweetsRef = query(
      collection(db, "tweets"),
      orderBy("timestamp", "desc")
    );
    const unsubscribe = onSnapshot(tweetsRef, (snapshot) => {
      const fetchedTweets = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Tweet[];
      setTweets(fetchedTweets);
    });

    return () => unsubscribe();
  }, []);

  const handleAddComment = async (tweetId: string, commentText: string) => {
    if (!commentText.trim()) return;

    const newComment = {
      id: Math.random().toString(36).substr(2, 9),
      text: commentText,
      userId: currentUser?.uid,
      username: currentUser?.displayName || "Anonymous",
      timestamp: Timestamp.now(),
    };

    const tweetRef = doc(db, "tweets", tweetId);
    await updateDoc(tweetRef, {
      comments: arrayUnion(newComment),
    });

    setCommentTextMap({ ...commentTextMap, [tweetId]: "" });
  };

  const handleEmojiSelect = (emoji: any) => {
    if (emojiTarget === "tweet") {
      setText(text + emoji.emoji);
    }
    setEmojiPickerVisible(false);
  };

  const handleDeleteTweet = async (tweetId: string) => {
    await deleteDoc(doc(db, "tweets", tweetId));
  };

  const handleDeleteComment = async (tweetId: string, commentId: string) => {
    const tweetRef = doc(db, "tweets", tweetId);
    const tweet = tweets.find((tweet) => tweet.id === tweetId);
    if (!tweet) return;

    const updatedComments = tweet.comments.filter(
      (comment) => comment.id !== commentId
    );
    await updateDoc(tweetRef, { comments: updatedComments });
  };

  
  
  
  
  
  

  const handleLikeDislike = async (
    tweetId: string,
    type: "like" | "dislike"
  ) => {
    const tweetRef = doc(db, "tweets", tweetId);
    const tweet = tweets.find((t) => t.id === tweetId);
    if (!tweet) return;

    const updatedLikes = new Set(tweet.likes);
    const updatedDislikes = new Set(tweet.dislikes);

    if (type === "like") {
      updatedLikes.add(currentUser?.uid!);
      updatedDislikes.delete(currentUser?.uid!);
    } else {
      updatedDislikes.add(currentUser?.uid!);
      updatedLikes.delete(currentUser?.uid!);
    }

    await updateDoc(tweetRef, {
      likes: Array.from(updatedLikes),
      dislikes: Array.from(updatedDislikes),
    });
  };

  const handleUpdateTweet = async (tweetId: string, updatedText: string) => {
    const tweetRef = doc(db, "tweets", tweetId);

    if (updatedText.length < 150) {
      alert("Tweets must be at least 150 characters long!");
      return;
    }

    await updateDoc(tweetRef, { text: updatedText });
  };

  const handleTweetSubmit = async () => {
    if (text.length < 150) {
      alert("Tweets must be at least 150 characters long!");
      return;
    }

    const today = dayjs().format("YYYY-MM-DD");
    const todayTweets = tweets.filter(
      (tweet) =>
        tweet.userId === currentUser?.uid &&
        dayjs(tweet.timestamp?.seconds * 1000).isSame(today, "day")
    );

    if (todayTweets.length > 0) {
      alert("You can only tweet once per day.");
      return;
    }

    const newTweet = {
      userId: currentUser?.uid,
      username: currentUser?.displayName || "Anonymous",
      text,
      hashtags: hashtags.split(",").map((tag) => tag.trim()),
      imageUrl: imageUrl || null,
      timestamp: serverTimestamp(),
      likes: [],
      dislikes: [],
      comments: [],
    };

    await addDoc(collection(db, "tweets"), newTweet);
    setText("");
    setHashtags("");
    setImageUrl("");
  };

  return (
    <Box
      sx={{
        bgcolor: theme.palette.grey[100],
        minHeight: "100vh",
        p: 0,
      }}
    >
      <PlatformMenu />

      {/* Modern Rules Card */}
      {rulesVisible && (
        <Paper
          elevation={0}
          sx={{
            m: 2,
            p: 3,
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.main}, ${alpha(
                theme.palette.primary.light,
                0.8
              )})`,
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", color: "white" }}
            >
              Community Guidelines
            </Typography>
            <IconButton onClick={() => setRulesVisible(false)} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
            {[
              "#Be respectful",
              "#One tweet per day",
              "#150+ characters",
              "#No offensive content",
              "#Follow guidelines",
            ].map((rule) => (
              <Chip
                key={rule}
                label={rule}
                sx={{ color: "white" }}
                variant="outlined"
                size="small"
              />
            ))}
          </Box>
        </Paper>
      )}

      {/* Modern Tweet Input Card */}
      <Paper
        elevation={0}
        sx={{
          m: 2,
          p: 3,
          borderRadius: 2,
        }}
      >
        <Box sx={{ display: "flex", gap: 2, alignItems: "flex-start" }}>
          <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
            {currentUser?.displayName?.charAt(0) || "A"}
          </Avatar>
          <Box sx={{ flexGrow: 1 }}>
            <TextField
              fullWidth
              multiline
              rows={3}
              placeholder="What's on your mind?"
              variant="outlined"
              value={text}
              onChange={(e) => setText(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                },
              }}
            />
            <Box sx={{ mt: 2, display: "flex", gap: 1, flexWrap: "wrap" }}>
              <TextField
                size="small"
                placeholder="Add hashtags..."
                value={hashtags}
                onChange={(e) => setHashtags(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <TagIcon sx={{ mr: 1, color: "text.secondary" }} />
                  ),
                }}
                sx={{ flexGrow: 1 }}
              />
              <TextField
                size="small"
                placeholder="Image URL..."
                value={imageUrl}
                onChange={(e) => setImageUrl(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <ImageIcon sx={{ mr: 1, color: "text.secondary" }} />
                  ),
                }}
                sx={{ flexGrow: 1 }}
              />
              <IconButton
                onClick={() => setEmojiPickerVisible(!emojiPickerVisible)}
              >
                <EmojiIcon />
              </IconButton>
              <Button
                variant="contained"
                endIcon={<SendIcon />}
                onClick={handleTweetSubmit}
                sx={{ borderRadius: 2 }}
              >
                Tweet
              </Button>
            </Box>
            {emojiPickerVisible && (
              <Box sx={{ mt: 2 }}>
                <Picker onEmojiClick={handleEmojiSelect} />
              </Box>
            )}
          </Box>
        </Box>
      </Paper>

      {/* Tweet List */}
      <Box sx={{ px: 2 }}>
        {tweets.map((tweet) => (
          <Card
            key={tweet.id}
            elevation={0}
            sx={{
              mb: 2,
              borderRadius: 2,
              "&:hover": {
                boxShadow: theme.shadows[1],
              },
            }}
          >
            <CardContent>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
              >
                <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                  <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                    {tweet.username.charAt(0)}
                  </Avatar>
                  <Box>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                      {tweet.username}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {dayjs(tweet.timestamp?.seconds * 1000).fromNow()}
                    </Typography>
                  </Box>
                </Box>
                {currentUser?.uid === tweet.userId && (
                  <Box>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setEditingTweetId(tweet.id);
                        setEditText(tweet.text);
                      }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleDeleteTweet(tweet.id)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                )}
              </Box>

              {editingTweetId === tweet.id ? (
                <Box sx={{ mt: 2 }}>
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    value={editText}
                    onChange={(e) => setEditText(e.target.value)}
                    sx={{ mb: 2 }}
                  />
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        handleUpdateTweet(tweet.id, editText);
                        setEditingTweetId(null);
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => setEditingTweetId(null)}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              ) : (
                <>
                  <Typography sx={{ mb: 2 }}>{tweet.text}</Typography>
                  {tweet.hashtags && tweet.hashtags.length > 0 && (
                    <Box
                      sx={{ display: "flex", gap: 1, flexWrap: "wrap", mb: 2 }}
                    >
                      {tweet.hashtags.map((tag) => (
                        <Chip
                          key={tag}
                          label={tag}
                          size="small"
                          color="primary"
                          variant="outlined"
                        />
                      ))}
                    </Box>
                  )}
                </>
              )}

              {tweet.imageUrl && (
                <Box
                  component="img"
                  src={tweet.imageUrl}
                  alt="Tweet"
                  sx={{
                    width: "50%",
                    borderRadius: 1,
                    mb: 2,
                  }}
                />
              )}
            </CardContent>

            <Divider />

            <CardActions sx={{ px: 2, py: 1 }}>
              <IconButton
                size="small"
                onClick={() => handleLikeDislike(tweet.id, "like")}
                color={
                  tweet.likes.includes(currentUser?.uid || "")
                    ? "primary"
                    : "default"
                }
              >
                {tweet.likes.includes(currentUser?.uid || "") ? (
                  <FavoriteIcon fontSize="small" />
                ) : (
                  <FavoriteBorderIcon fontSize="small" />
                )}
              </IconButton>
              <Typography variant="caption" sx={{ mr: 2 }}>
                {tweet.likes.length}
              </Typography>

              <IconButton
                size="small"
                onClick={() => handleLikeDislike(tweet.id, "dislike")}
                color={
                  tweet.dislikes.includes(currentUser?.uid || "")
                    ? "error"
                    : "default"
                }
              >
                <ThumbDownIcon fontSize="small" />
              </IconButton>
              <Typography variant="caption" sx={{ mr: 2 }}>
                {tweet.dislikes.length}
              </Typography>

              <IconButton
                size="small"
                onClick={() =>
                  setExpandedTweet(expandedTweet === tweet.id ? null : tweet.id)
                }
              >
                <CommentIcon fontSize="small" />
              </IconButton>
              <Typography variant="caption">{tweet.comments.length}</Typography>
            </CardActions>

            <Collapse in={expandedTweet === tweet.id}>
              <Box sx={{ p: 2 }}>
                {tweet.comments.map((comment) => (
                  <Box
                    key={comment.id}
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: 2,
                      mb: 2,
                      p: 2,
                      bgcolor: theme.palette.grey[50],
                      borderRadius: 1,
                    }}
                  >
                    <Avatar sx={{ width: 32, height: 32 }}>
                      {comment.username.charAt(0)}
                    </Avatar>
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle2">
                        {comment.username}
                      </Typography>
                      <Typography variant="body2">{comment.text}</Typography>
                    </Box>
                    {currentUser?.uid === comment.userId && (
                      <IconButton
                        size="small"
                        onClick={() =>
                          handleDeleteComment(tweet.id, comment.id)
                        }
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    )}
                  </Box>
                ))}

                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                  <Avatar
                    sx={{
                      width: 32,
                      height: 32,
                      bgcolor: theme.palette.primary.main,
                    }}
                  >
                    {currentUser?.displayName?.charAt(0) || "A"}
                  </Avatar>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Write a comment..."
                    value={commentTextMap[tweet.id] || ""}
                    onChange={(e) =>
                      setCommentTextMap({
                        ...commentTextMap,
                        [tweet.id]: e.target.value,
                      })
                    }
                    InputProps={{
                      endAdornment: (
                        <Box sx={{ display: "flex", gap: 0.5 }}>
                          <IconButton
                            size="small"
                            onClick={() =>
                              setEmojiPickerVisible(!emojiPickerVisible)
                            }
                          >
                            <EmojiIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            size="small"
                            color="primary"
                            onClick={() => {
                              handleAddComment(
                                tweet.id,
                                commentTextMap[tweet.id] || ""
                              );
                            }}
                            disabled={!commentTextMap[tweet.id]?.trim()}
                          >
                            <SendIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      ),
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: 2,
                        bgcolor: theme.palette.grey[50],
                      },
                    }}
                  />
                </Box>
                {emojiPickerVisible && (
                  <Box
                    sx={{
                      position: "absolute",
                      zIndex: 1,
                      mt: 1,
                      boxShadow: theme.shadows[3],
                      borderRadius: 2,
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        p: 1,
                        bgcolor: "white",
                      }}
                    >
                      <IconButton
                        size="small"
                        onClick={() => setEmojiPickerVisible(false)}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Box>
                    <Picker
                      onEmojiClick={(emoji) =>
                        setCommentTextMap({
                          ...commentTextMap,
                          [tweet.id]:
                            (commentTextMap[tweet.id] || "") + emoji.emoji,
                        })
                      }
                    />
                  </Box>
                )}
              </Box>
            </Collapse>
          </Card>
        ))}
      </Box>

      {/* Empty State */}
      {tweets.length === 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            p: 4,
            textAlign: "center",
            color: "text.secondary",
          }}
        >
          <TimelineIcon
            sx={{
              fontSize: 48,
              mb: 2,
              color: theme.palette.grey[300],
            }}
          />
          <Typography variant="h6">No tweets yet</Typography>
          <Typography variant="body2">
            Be the first to share your thoughts!
          </Typography>
        </Box>
      )}

      {/* Loading State - Optional */}
      {/* {isLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
          <CircularProgress />
        </Box>
      )} */}
    </Box>
  );
};

export default Feed;
