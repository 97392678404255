import React, { useState, useEffect } from "react";
import { db } from "../services/firebase";
import { doc, getDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { QRCodeCanvas } from "qrcode.react";
import {
  Container,
  Typography,
  Box,
  Paper,
  Button,
  Chip,
  Divider,
  useTheme,
  useMediaQuery,
  Skeleton,
  Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PlatformMenu from "../components/PlatformMenu";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  Pets,
  HealthAndSafety,
  Medication,
  Restaurant,
  QrCode2,
  Download,
  Verified,
  GppBad,
  CalendarMonth,
  LocationOn,
  Info,
  DateRange,
  Badge,
} from "@mui/icons-material";

// Interfaces remain the same
interface VaccinationRecord {
  vaccineName: string;
  date: string;
  place: string;
  nextDueDate: string;
  verified: boolean;
}

interface AnimalData {
  species: string;
  purposeTags: string[];
  healthConditions: string;
  nickname: string;
  breed: string;
  profilePhotoUrl: string;
  medications?: string;
  healthNotes?: string;
  age: string;
  dietaryInfo?: string;
  gender: string;
  uniqueID: string;
  vaccinationRecords: VaccinationRecord[];
}

const PassportContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));

const PassportCard = styled(Paper)(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: 16,
  overflow: "hidden",
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
  position: "relative",
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "translateY(-4px)",
  },
}));

const Header = styled(Box)(({ theme }) => ({
  background: "linear-gradient(135deg, #148114 0%, #169216 100%)",
  padding: theme.spacing(4),
  color: "#fff",
  textAlign: "center",
  position: "relative",
  overflow: "hidden",
  "&::after": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background:
      "radial-gradient(circle at center, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)",
  },
}));

const PhotoContainer = styled(Box)(({ theme }) => ({
  width: 200,
  height: 200,
  margin: "0 auto",
  position: "relative",
  marginTop: -100,
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    width: 150,
    height: 150,
    marginTop: -75,
  },
}));

const ProfilePhoto = styled("img")(({ theme }) => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: "50%",
  border: "4px solid #fff",
  boxShadow: "0 4px 16px rgba(0, 0, 0, 0.1)",
}));

const InfoSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));

const InfoCard = styled(Box)(({ theme }) => ({
  background: theme.palette.grey[50],
  borderRadius: 12,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  display: "flex",
  alignItems: "flex-start",
  gap: theme.spacing(2),
  transition: "transform 0.2s ease",
  "&:hover": {
    transform: "translateX(4px)",
  },
}));

const VaccinationCard = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: 12,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.05)",
}));

const DownloadButton = styled(Button)(({ theme }) => ({
  background: "linear-gradient(135deg, #FFD700 0%, #FFA500 100%)",
  color: "#00256E",
  padding: theme.spacing(1.5, 4),
  borderRadius: 30,
  fontWeight: 600,
  textTransform: "none",
  fontSize: "1rem",
  boxShadow: "0 4px 12px rgba(255, 215, 0, 0.3)",
  "&:hover": {
    background: "linear-gradient(135deg, #FFD700 0%, #FF8C00 100%)",
    transform: "translateY(-2px)",
    boxShadow: "0 6px 16px rgba(255, 215, 0, 0.4)",
  },
}));

const QRCodeContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(2),
  right: theme.spacing(2),
  background: "#fff",
  padding: theme.spacing(1),
  borderRadius: 8,
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
  [theme.breakpoints.down("sm")]: {
    position: "relative",
    top: "auto",
    right: "auto",
    margin: "0 auto",
    marginTop: theme.spacing(2),
    width: "fit-content",
  },
}));

const VirtualPassport: React.FC = () => {
  const { animalId } = useParams<{ animalId: string }>();
  const [animalData, setAnimalData] = useState<AnimalData | null>(null);
  const [loading, setLoading] = useState(true);
  const currentUrl = window.location.href;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchAnimalData = async () => {
      if (!animalId) return;
      try {
        const docRef = doc(db, "animals", animalId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setAnimalData(docSnap.data() as AnimalData);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching animal data:", error);
        setLoading(false);
      }
    };
    fetchAnimalData();
  }, [animalId]);

  const handleDownloadPDF = async () => {
    const passportElement = document.getElementById("passport");
    if (!passportElement) return;

    const canvas = await html2canvas(passportElement);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF();
    pdf.addImage(imgData, "PNG", 10, 10, 190, 0);
    pdf.save(`${animalData?.nickname}_passport.pdf`);
  };

  if (loading) {
    return (
      <PassportContainer maxWidth="md">
        <Skeleton variant="rectangular" height={400} sx={{ borderRadius: 2 }} />
      </PassportContainer>
    );
  }

  if (!animalData) {
    return (
      <PassportContainer maxWidth="md">
        <Alert severity="error">No animal data found</Alert>
      </PassportContainer>
    );
  }

  return (
    <div>
      <PlatformMenu />
      <PassportContainer maxWidth="md">
        <PassportCard elevation={0} id="passport">
          <Header>
            <Typography variant="h4" sx={{ fontWeight: 700, mb: 1 }}>
              Virtual Pet Passport
            </Typography>
            <Typography variant="subtitle1" sx={{ opacity: 0.9 }}>
              AnimalsX Digital Certificate
            </Typography>
          </Header>

          {/* <PhotoContainer>
            <ProfilePhoto
              src={animalData.profilePhotoUrl || "https://via.placeholder.com/200"}
              alt={animalData.nickname}
            />
          </PhotoContainer> */}

          <QRCodeContainer>
            <QRCodeCanvas
              value={currentUrl}
              size={isMobile ? 100 : 120}
              level="H"
              includeMargin={true}
            />
            <Typography
              variant="caption"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mt: 1,
              }}
            >
              <QrCode2 sx={{ fontSize: 16, mr: 0.5 }} /> Verify
            </Typography>
          </QRCodeContainer>

          <InfoSection>
            <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
              Pet Information
            </Typography>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
                gap: 2,
              }}
            >
              <InfoCard>
                <Pets color="primary" />
                <Box>
                  <Typography variant="caption" color="textSecondary">
                    Name & Species
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {animalData.nickname} ({animalData.species})
                  </Typography>
                </Box>
              </InfoCard>

              <InfoCard>
                <Badge color="primary" />
                <Box>
                  <Typography variant="caption" color="textSecondary">
                    Breed
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {animalData.breed}
                  </Typography>
                </Box>
              </InfoCard>

              <InfoCard>
                <Info color="primary" />
                <Box>
                  <Typography variant="caption" color="textSecondary">
                    ID Number
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {animalData.uniqueID}
                  </Typography>
                </Box>
              </InfoCard>

              <InfoCard>
                <DateRange color="primary" />
                <Box>
                  <Typography variant="caption" color="textSecondary">
                    Age
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {animalData.age}
                  </Typography>
                </Box>
              </InfoCard>
            </Box>

            <Divider sx={{ my: 4 }} />

            <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
              Health Information
            </Typography>

            <InfoCard>
              <HealthAndSafety color="error" />
              <Box sx={{ flex: 1 }}>
                <Typography variant="caption" color="textSecondary">
                  Health Conditions
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  {animalData.healthConditions || "None reported"}
                </Typography>
              </Box>
            </InfoCard>

            <InfoCard>
              <Medication color="info" />
              <Box sx={{ flex: 1 }}>
                <Typography variant="caption" color="textSecondary">
                  Medications
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  {animalData.medications || "None prescribed"}
                </Typography>
              </Box>
            </InfoCard>

            <InfoCard>
              <Restaurant color="success" />
              <Box sx={{ flex: 1 }}>
                <Typography variant="caption" color="textSecondary">
                  Dietary Information
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  {animalData.dietaryInfo || "Standard diet"}
                </Typography>
              </Box>
            </InfoCard>

            <Divider sx={{ my: 4 }} />

            <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
              Vaccination Records
            </Typography>

            {animalData.vaccinationRecords.map((record, index) => (
              <VaccinationCard key={index}>
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  {record.verified ? (
                    <Chip
                      icon={<Verified />}
                      label="Verified"
                      color="success"
                      size="small"
                      sx={{ mr: 1 }}
                    />
                  ) : (
                    <Chip
                      icon={<GppBad />}
                      label="Unverified"
                      color="warning"
                      size="small"
                      sx={{ mr: 1 }}
                    />
                  )}
                  <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                    {record.vaccineName}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr 1fr",
                    gap: 2,
                    mt: 2,
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CalendarMonth
                      sx={{ fontSize: 20, mr: 1, color: "primary.main" }}
                    />
                    <Box>
                      <Typography variant="caption" color="textSecondary">
                        Date
                      </Typography>
                      <Typography variant="body2">{record.date}</Typography>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <LocationOn
                      sx={{ fontSize: 20, mr: 1, color: "primary.main" }}
                    />
                    <Box>
                      <Typography variant="caption" color="textSecondary">
                        Location
                      </Typography>
                      <Typography variant="body2">{record.place}</Typography>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <DateRange
                      sx={{ fontSize: 20, mr: 1, color: "primary.main" }}
                    />
                    <Box>
                      <Typography variant="caption" color="textSecondary">
                        Next Due
                      </Typography>
                      <Typography variant="body2">
                        {record.nextDueDate}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </VaccinationCard>
            ))}

            <Box sx={{ textAlign: "center", mt: 4 }}>
              <DownloadButton
                startIcon={<Download />}
                onClick={handleDownloadPDF}
                variant="contained"
                size="large"
              >
                Download Pet Passport
              </DownloadButton>
            </Box>

            <Box sx={{ mt: 4, p: 3, bgcolor: "grey.50", borderRadius: 2 }}>
              <Typography
                variant="caption"
                sx={{
                  fontFamily: "monospace",
                  display: "block",
                  textAlign: "center",
                  fontSize: "0.75rem",
                  letterSpacing: "0.1em",
                }}
              >
                {`P<ANX${animalData.species
                  .toUpperCase()
                  .padEnd(3, "<")}${animalData.nickname
                  .toUpperCase()
                  .padEnd(39, "<")}`}
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  fontFamily: "monospace",
                  display: "block",
                  textAlign: "center",
                  fontSize: "0.75rem",
                  letterSpacing: "0.1em",
                  mt: 1,
                }}
              >
                {`${animalData.uniqueID}<<<${animalData.breed
                  .toUpperCase()
                  .padEnd(35, "<")}`}
              </Typography>
            </Box>

            <Box
              sx={{
                mt: 4,
                textAlign: "center",
                color: "text.secondary",
                borderTop: "1px solid",
                borderColor: "divider",
                pt: 2,
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 1,
                }}
              >
                <Badge fontSize="small" />
                Official Digital Pet Passport
              </Typography>
              <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                AnimalsX © {new Date().getFullYear()}
              </Typography>
            </Box>
          </InfoSection>
        </PassportCard>

        {/* Floating Action Button for mobile download */}
        {isMobile && (
          <Box
            sx={{
              position: "fixed",
              bottom: 20,
              right: 20,
              zIndex: 1000,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleDownloadPDF}
              sx={{
                borderRadius: "50%",
                width: 56,
                height: 56,
                minWidth: "auto",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
                "&:hover": {
                  transform: "scale(1.1)",
                },
              }}
            >
              <Download />
            </Button>
          </Box>
        )}
      </PassportContainer>
    </div>
  );
};

// Theme customization
const theme = {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: 8,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 16,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    h4: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 600,
    },
    subtitle1: {
      fontWeight: 500,
    },
  },
  palette: {
    primary: {
      main: "#148114",
      light: "#179717",
      dark: "#148114",
    },
    secondary: {
      main: "#FFD700",
      light: "#FFE347",
      dark: "#CCB100",
    },
  },
};

export default VirtualPassport;
