import React, { useState, useEffect } from "react";
import { db } from "../../services/firebase";
import {
  collection,
  doc,
  getDocs,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import {
  Container,
  Typography,
  TextField,
  IconButton,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Button,
  Divider,
  Box,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";

interface Post {
  id: string;
  content: string;
  author: string;
  authorId: string;
  timestamp: any;
}

export interface Thread {
  id: string;
  title: string;
  description: string;
  creatorId: string;
  posts: Post[];
}

const ForumManagement: React.FC = () => {
  const [threads, setThreads] = useState<Thread[]>([]);
  const [selectedThread, setSelectedThread] = useState<Thread | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchResults, setSearchResults] = useState<Thread[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [editingPostId, setEditingPostId] = useState<string | null>(null);
  const [editedPostContent, setEditedPostContent] = useState<string>("");

  useEffect(() => {
    const fetchThreads = async () => {
      setLoading(true);
      const threadsRef = collection(db, "threads");
      const querySnapshot = await getDocs(threadsRef);
      const threadsData: Thread[] = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Thread[];
      setThreads(threadsData);
      setLoading(false);
    };

    fetchThreads();
  }, []);

  useEffect(() => {
    if (searchQuery.trim()) {
      const results = threads.filter(
        (thread) =>
          thread.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          thread.description.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setSearchResults(results);
    } else {
      setSearchResults(threads);
    }
  }, [searchQuery, threads]);

  const handleEditPost = (postId: string, currentContent: string) => {
    setEditingPostId(postId);
    setEditedPostContent(currentContent);
  };

  const handleSaveEditedPost = async (threadId: string, postId: string) => {
    const threadRef = doc(db, "threads", threadId);
    const threadSnapshot = await getDoc(threadRef);
    if (threadSnapshot.exists()) {
      const threadData = threadSnapshot.data();
      const updatedPosts = threadData.posts.map((post: Post) =>
        post.id === postId ? { ...post, content: editedPostContent } : post
      );
      await updateDoc(threadRef, {
        posts: updatedPosts,
      });
      setThreads((prevThreads) =>
        prevThreads.map((thread) =>
          thread.id === threadId ? { ...thread, posts: updatedPosts } : thread
        )
      );
      setEditingPostId(null);
      setEditedPostContent("");
    }
  };

  const handleCancelEdit = () => {
    setEditingPostId(null);
    setEditedPostContent("");
  };

  const handleDeletePost = async (threadId: string, postId: string) => {
    const threadRef = doc(db, "threads", threadId);
    const threadSnapshot = await getDoc(threadRef);
    if (threadSnapshot.exists()) {
      const threadData = threadSnapshot.data();
      const updatedPosts = threadData.posts.filter(
        (post: Post) => post.id !== postId
      );
      await updateDoc(threadRef, {
        posts: updatedPosts,
      });
      setThreads((prevThreads) =>
        prevThreads.map((thread) =>
          thread.id === threadId ? { ...thread, posts: updatedPosts } : thread
        )
      );
    }
  };

  const handleBackToThreads = () => {
    setSelectedThread(null);
  };

  const handleSelectThread = (thread: Thread) => {
    setSelectedThread(thread);
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom align="center" color="primary">
        Forum Management
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <TextField
          label="Search Threads"
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
          sx={{
            maxWidth: "600px",
            marginRight: "20px",
          }}
        />
      </Box>

      {loading ? (
        <Box
          sx={{ display: "flex", justifyContent: "center", padding: "50px" }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {selectedThread ? (
            <Box>
              <Button
                onClick={handleBackToThreads}
                variant="outlined"
                color="primary"
                style={{ marginBottom: "20px" }}
              >
                Back to Threads
              </Button>

              <Typography variant="h5" color="primary" gutterBottom>
                {selectedThread.title}
              </Typography>
              <Typography variant="body1" color="textSecondary" gutterBottom>
                {selectedThread.description}
              </Typography>

              <Divider style={{ marginBottom: "20px" }} />

              <List>
                {selectedThread.posts.map((post) => (
                  <ListItem
                    key={post.id}
                    alignItems="flex-start"
                    sx={{ marginBottom: "10px" }}
                  >
                    <ListItemAvatar>
                      <Avatar>{post.author[0]}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={<strong>{post.author}</strong>}
                      secondary={
                        <>
                          {editingPostId === post.id ? (
                            <Box>
                              <TextField
                                fullWidth
                                variant="outlined"
                                value={editedPostContent}
                                onChange={(e) =>
                                  setEditedPostContent(e.target.value)
                                }
                                multiline
                                rows={4}
                                style={{ marginBottom: "10px" }}
                              />
                              <Box>
                                <IconButton
                                  onClick={() =>
                                    handleSaveEditedPost(
                                      selectedThread.id,
                                      post.id
                                    )
                                  }
                                  color="primary"
                                  size="large"
                                >
                                  <SaveIcon />
                                </IconButton>
                                <IconButton
                                  onClick={handleCancelEdit}
                                  color="secondary"
                                  size="large"
                                  style={{ marginLeft: "10px" }}
                                >
                                  <CancelIcon />
                                </IconButton>
                              </Box>
                            </Box>
                          ) : (
                            <>
                              <Typography
                                component="span"
                                variant="body2"
                                color="textPrimary"
                              >
                                {post.content}
                              </Typography>
                              <br />
                              <Typography variant="body2" color="textSecondary">
                                {post.timestamp?.toDate().toLocaleString()}
                              </Typography>
                              <Box
                                display="flex"
                                justifyContent="flex-end"
                                style={{ marginTop: "10px" }}
                              >
                                <IconButton
                                  onClick={() =>
                                    handleEditPost(post.id, post.content)
                                  }
                                  color="primary"
                                  size="large"
                                >
                                  <EditIcon />
                                </IconButton>
                                <IconButton
                                  onClick={() =>
                                    handleDeletePost(selectedThread.id, post.id)
                                  }
                                  color="secondary"
                                  size="large"
                                  style={{ marginLeft: "10px" }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            </>
                          )}
                        </>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          ) : (
            <Box>
              <List>
                {searchResults.map((thread) => (
                  <Card
                    key={thread.id}
                    sx={{ marginBottom: "20px", boxShadow: 3 }}
                  >
                    <CardContent>
                      <Typography
                        variant="h6"
                        color="primary"
                        onClick={() => handleSelectThread(thread)}
                        sx={{ cursor: "pointer", fontWeight: 600 }}
                      >
                        {thread.title}
                      </Typography>
                      <Typography color="textSecondary">
                        {thread.description}
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
              </List>
            </Box>
          )}
        </>
      )}
    </Container>
  );
};

export default ForumManagement;
