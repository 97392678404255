import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Grid,
  MenuItem,
  InputLabel,
  Select,
  Typography,
  Snackbar,
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
  Box,
  Stepper,
  Step,
  StepLabel,
  Paper,
  Chip,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  UploadFile,
  Add,
  Close,
  Pets,
  Badge,
  Medication,
  Restaurant,
  Cake,
  Male,
  Female,
  Vaccines,
  PhotoCamera,
  NavigateNext,
  NavigateBefore,
} from "@mui/icons-material";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../services/firebase";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";

interface VaccinationRecord {
  vaccineName: string;
  date: string;
  nextDueDate: string;
  place: string;
  idNumber: string;
  verified: boolean;
}

interface AnimalData {
  species: string;
  purposeTags: string[];
  healthConditions: string;
  nickname: string;
  breed: string;
  profilePhoto: File | null;
  medications: string;
  healthNotes: string;
  age: string;
  dietaryInfo: string;
  gender: string;
  uniqueID: string;
  vaccinationRecords: VaccinationRecord[];
  userId: string;
}

const RegistrationPage: React.FC = () => {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(true);
  const [animalData, setAnimalData] = useState<AnimalData>({
    species: "",
    purposeTags: [],
    healthConditions: "",
    nickname: "",
    breed: "",
    profilePhoto: null,
    medications: "",
    healthNotes: "",
    age: "",
    dietaryInfo: "",
    gender: "",
    uniqueID: "",
    vaccinationRecords: [],
    userId: "",
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [currentUserId, setCurrentUserId] = useState<string | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      setCurrentUserId(user.uid);
    } else {
      console.log("No user logged in");
    }
  }, []);

  useEffect(() => {
    if (currentUserId) {
      setAnimalData((prevData) => ({ ...prevData, userId: currentUserId }));
    }
  }, [currentUserId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAnimalData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (
    e: React.ChangeEvent<{ value: unknown }>,
    field: keyof AnimalData
  ) => {
    setAnimalData((prevData) => ({
      ...prevData,
      [field]: e.target.value as string[],
    }));
  };

  const steps = ["Basic Info", "Health Details", "Vaccination Records"];

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handlePhotoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setAnimalData((prevData) => ({
        ...prevData,
        profilePhoto: e.target.files ? e.target.files[0] : null,
      }));
    }
  };

  const handleVaccinationChange = <K extends keyof VaccinationRecord>(
    index: number,
    field: K,
    value: VaccinationRecord[K]
  ) => {
    const updatedVaccinations = [...animalData.vaccinationRecords];
    updatedVaccinations[index] = {
      ...updatedVaccinations[index],
      [field]: value,
    };
    setAnimalData((prevData) => ({
      ...prevData,
      vaccinationRecords: updatedVaccinations,
    }));
  };

  const addVaccinationRecord = () => {
    setAnimalData((prevData) => ({
      ...prevData,
      vaccinationRecords: [
        ...prevData.vaccinationRecords,
        {
          vaccineName: "",
          date: "",
          nextDueDate: "",
          place: "",
          idNumber: "",
          verified: false,
        },
      ],
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (!currentUserId) {
        throw new Error("User not authenticated");
      }

      const docRef = await addDoc(collection(db, "animals"), {
        ...animalData,
        userId: currentUserId,
      });
      setSnackbarMessage(
        `Successfully registered animal: ${animalData.nickname}`
      );
      setSnackbarOpen(true);

      navigate("/dashboard");
    } catch (error) {
      console.error("Error adding document: ", error);
      setSnackbarMessage("Registration failed. Please try again.");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => setSnackbarOpen(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    navigate("/dashboard");
  };

  const renderBasicInfo = () => (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper
          elevation={0}
          sx={{ p: 3, backgroundColor: "rgba(0,0,0,0.02)", borderRadius: 2 }}
        >
          <Box display="flex" alignItems="center" mb={2}>
            <Pets sx={{ mr: 1, color: theme.palette.primary.main }} />
            <Typography variant="h6">Basic Information</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Species"
                name="species"
                value={animalData.species}
                onChange={handleChange}
                required
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <Pets sx={{ mr: 1, color: "text.secondary" }} />
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Nickname"
                name="nickname"
                value={animalData.nickname}
                onChange={handleChange}
                required
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <Badge sx={{ mr: 1, color: "text.secondary" }} />
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Purpose Tags</InputLabel>
              <Select
                multiple
                fullWidth
                value={animalData.purposeTags}
                onChange={(e) =>
                  handleSelectChange(
                    e as React.ChangeEvent<{ value: unknown }>,
                    "purposeTags"
                  )
                }
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {(selected as string[]).map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        color="primary"
                        variant="outlined"
                      />
                    ))}
                  </Box>
                )}
              >
                <MenuItem value="For Sale">For Sale</MenuItem>
                <MenuItem value="Breeding">Breeding</MenuItem>
                <MenuItem value="Pet">Pet</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper
          elevation={0}
          sx={{ p: 3, backgroundColor: "rgba(0,0,0,0.02)", borderRadius: 2 }}
        >
          <Box display="flex" alignItems="center" mb={2}>
            <PhotoCamera sx={{ mr: 1, color: theme.palette.primary.main }} />
            <Typography variant="h6">Profile Photo</Typography>
          </Box>
          <Button
            variant="outlined"
            component="label"
            startIcon={<UploadFile />}
            sx={{ width: "100%", height: "120px", borderStyle: "dashed" }}
          >
            <Box textAlign="center">
              <Typography variant="body1">Upload Photo</Typography>
              <Typography variant="caption" color="text.secondary">
                Drag and drop or click to upload
              </Typography>
            </Box>
            <input type="file" hidden onChange={handlePhotoUpload} />
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );

  const renderHealthDetails = () => (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper
          elevation={0}
          sx={{ p: 3, backgroundColor: "rgba(0,0,0,0.02)", borderRadius: 2 }}
        >
          <Box display="flex" alignItems="center" mb={2}>
            <Medication sx={{ mr: 1, color: theme.palette.primary.main }} />
            <Typography variant="h6">Health Information</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Medications"
                name="medications"
                value={animalData.medications}
                onChange={handleChange}
                multiline
                rows={3}
                InputProps={{
                  startAdornment: (
                    <Medication sx={{ mr: 1, color: "text.secondary" }} />
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Health Notes"
                name="healthNotes"
                value={animalData.healthNotes}
                onChange={handleChange}
                multiline
                rows={3}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper
          elevation={0}
          sx={{ p: 3, backgroundColor: "rgba(0,0,0,0.02)", borderRadius: 2 }}
        >
          <Box display="flex" alignItems="center" mb={2}>
            <Restaurant sx={{ mr: 1, color: theme.palette.primary.main }} />
            <Typography variant="h6">Dietary Information</Typography>
          </Box>
          <TextField
            fullWidth
            label="Dietary Info"
            name="dietaryInfo"
            value={animalData.dietaryInfo}
            onChange={handleChange}
            multiline
            rows={3}
            InputProps={{
              startAdornment: (
                <Restaurant sx={{ mr: 1, color: "text.secondary" }} />
              ),
            }}
          />
        </Paper>
      </Grid>
    </Grid>
  );

  const renderVaccinationRecords = () => (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper
          elevation={0}
          sx={{ p: 3, backgroundColor: "rgba(0,0,0,0.02)", borderRadius: 2 }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Box display="flex" alignItems="center">
              <Vaccines sx={{ mr: 1, color: theme.palette.primary.main }} />
              <Typography variant="h6">Vaccination Records</Typography>
            </Box>
            <Button
              variant="outlined"
              startIcon={<Add />}
              onClick={addVaccinationRecord}
              size="small"
            >
              Add Record
            </Button>
          </Box>
          {animalData.vaccinationRecords.map((record, index) => (
            <Paper
              key={index}
              elevation={0}
              sx={{ p: 2, mb: 2, backgroundColor: "white", borderRadius: 1 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Vaccine ID"
                    value={record.vaccineName}
                    onChange={(e) =>
                      handleVaccinationChange(
                        index,
                        "vaccineName",
                        e.target.value
                      )
                    }
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Date"
                    type="date"
                    value={record.date}
                    onChange={(e) =>
                      handleVaccinationChange(index, "date", e.target.value)
                    }
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Next Due Date"
                    type="date"
                    value={record.nextDueDate}
                    onChange={(e) =>
                      handleVaccinationChange(
                        index,
                        "nextDueDate",
                        e.target.value
                      )
                    }
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
            </Paper>
          ))}
        </Paper>
      </Grid>
    </Grid>
  );

  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          minHeight: "80vh",
        },
      }}
    >
      <DialogTitle sx={{ borderBottom: 1, borderColor: "divider", pb: 1 }}>
        <Box display="flex" alignItems="center">
          <Pets sx={{ mr: 1, color: theme.palette.primary.main }} />
          <Typography variant="h6">Animal Registration</Typography>
        </Box>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers sx={{ p: 3 }}>
        <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {activeStep === 0 && renderBasicInfo()}
        {activeStep === 1 && renderHealthDetails()}
        {activeStep === 2 && renderVaccinationRecords()}
      </DialogContent>

      <DialogActions sx={{ p: 2, borderTop: 1, borderColor: "divider" }}>
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          startIcon={<NavigateBefore />}
        >
          Back
        </Button>
        {activeStep === steps.length - 1 ? (
          <Button
            variant="contained"
            onClick={handleSubmit}
            endIcon={<Add />}
            sx={{ ml: 1 }}
          >
            Register Animal
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={handleNext}
            endIcon={<NavigateNext />}
            sx={{ ml: 1 }}
          >
            Next
          </Button>
        )}
      </DialogActions>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default RegistrationPage;
